import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import TableSkeleton from "../../../common/TableSkeleton";
import SavonFabricationAdminTableData from "./SavonFabricationAdminTableData";
import { BsFileCheckFill } from "react-icons/bs";
import { useGetFabricationListQuery } from "../../../../utils/api/fabrication/fabrication.api";
import { Recette } from "../../../../utils/api/recette/recette.type";
import { onFormatDate } from "../../../../utils/utils";
import { CustomPagination } from "../../../TableauDeBord/StockMP/StockMPTable/StockMPTable";
import { FiEye } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { Fabrication } from "../../../../utils/api/fabrication/fabrication.type";

function SavonFabricationAdminTable({ type }: { type: string }) {
 const [page, setPage] = useState(1);
 const { data = { data: { data: [], total: 0 } }, isLoading } =
   useGetFabricationListQuery({
     page,
     type: type || "",
   });

 useEffect(() => {
   console.log("data", data);
 }, [data]);

  const cureFormatter = (cell, row) => {
    if (cell === "vert") {
      return (
        <>
          <span style={{ color: "#219653", fontSize: 20 }}>
            <BsFileCheckFill />
          </span>
        </>
      );
    }
    if (cell === "Orange") {
      return (
        <>
          <span style={{ color: "#F2994A", fontSize: 20 }}>
            <BsFileCheckFill />
          </span>
        </>
      );
    }

    return (
      <>
        <span style={{ color: "#EB5757", fontSize: 20 }}>
          <BsFileCheckFill />
        </span>
      </>
    );
  };

  const actionFormatter = (cell: string, row: Fabrication) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-2 align-items-center">
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/fabrication/${cell}`}
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Visualiser"
              state={row}
            >
              <FiEye />
            </NavLink>
          </div>
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "numero_fabrication",
      text: "N°  de fabrication° ",
    },
    {
      dataField: "recette",
      text: "Nom de la recette",
      formatter: (cell: Recette) => cell?.nom_recette,
    },
    {
      dataField: "numero_lot",
      text: "N° de lot",
    },
    {
      dataField: "date_fabrication",
      text: "Date de fabrication",
      formatter: (cell: string) => onFormatDate(cell),
    },
    {
      dataField: "mise_encure_status",
      text: "Sortie de cure",
      formatter: (cell: any, row: any) => cureFormatter(cell, row),
    },
    {
      dataField: "fabrication_id",
      text: "Actions",
      formatter: (cell: string, row: Fabrication) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="fabrication_id"
            data={data?.data?.data}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => "Aucune donnée trouvée"}
          />
          <CustomPagination
            nbPages={data?.data?.total}
            page={page}
            onChange={(page) => {
              setPage(page);
            }}
            perPage={10}
          />
        </>
      )}
    </>
  );
}

export default SavonFabricationAdminTable;
