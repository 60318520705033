import { useEffect } from "react";
import { useStep } from "react-hooks-helper";
import { useRecetteFromLocation } from "../../../../utils/api/recette/recette.api";
import { useStepType } from "../../../../utils/type";
import CoutFabricationTabs from "./NewRecetteTabs/CoutFabricationTabs";
import InfoFabricationTabs from "./NewRecetteTabs/InfoFabricationTabs";
import QuantiteTabs from "./NewRecetteTabs/QuantiteTabs";
import UseCrudRecetteForm from "./requestForm/UseCrudRecetteForm";

const steps = [
  { id: "quantite", Component: QuantiteTabs },
  { id: "info-fabrication", Component: InfoFabricationTabs },
  { id: "cout-fabrication", Component: CoutFabricationTabs },
];
const tabs = ["Quantité MP", "Info fabrication", "Coût fabrication"];

const AddNewRecette = () => {
  const [item] = useRecetteFromLocation();
  const { step, navigation, index }: useStepType = useStep({
    initialStep: 0,
    steps,
  });
  const form = UseCrudRecetteForm(item);
  const { Component } = step;
  const { setValue, onSubmit, level, dataForm } = form;
  const props = {
    navigation,
    index,
    ...form,
  };
  useEffect(() => {
    window.scrollTo(10, 10);
    // console.log("level index", itemState);
    setValue("step", index);
  }, [index]);

  useEffect(() => {
    if (level === 1) {
      navigation.go("info-fabrication");
    } else if (level === 2) {
      navigation.go("cout-fabrication");
    }
    console.log("level index", level);
  }, [level, dataForm]);
  return (
    <div className="fournisseur-list-component">
      <div className="container-title-page-admin mb-5">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">Nouvelle recette</h3>
        </div>
      </div>
      <div className="container-fournisseur-list">
        <div className="container-ul-recette">
          <ul className="tabs-container">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
        </div>
        <form className="tabs-component-container pt-5" onSubmit={onSubmit}>
          <Component {...props} />
        </form>
      </div>
    </div>
  );
};

export default AddNewRecette;
