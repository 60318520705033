import React from 'react'
import { FiMoreHorizontal } from 'react-icons/fi'

const ConfidentialiteTabs = () => {
    return (
        <div className='cgu-tabs-component'>
            <div className='container-cgu-tabs'>
                <div className='content-chapter-item mb-4'>
                <div className='content-title-cgu'>
                    <h2 className='title-page-cgu'>1. Introduction</h2>
                </div>
                <div className='content-text-cgu-admin'>
                    <div className='content-btn-menu flex-r'>
                        <button className='btn no-link'>
                            <FiMoreHorizontal />
                        </button>
                    </div>
                    <p className='text-cgu-admin py-3'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis neque id volutpat vulputate ante. Accumsan pharetra, elementum amet tempor ante. Tristique scelerisque iaculis donec pellentesque convallis sed. Mattis cursus sed gravida turpis lacus, amet, ut est. Elementum pellentesque bibendum 
Egestas nibh ac sit pretium. Sed diam sit dignissim et facilisis. Bibendum sit dictumst pharetra felis. Nulla euismod porttitor quisque sed ipsum dictum nunc diam. Tortor nulla odio amet nunc in. Lorem faucibus ridiculus integer aliquet massa ac consectetur amet. Aliquam adipiscing congue pellentesque eu euismod ut mollis dolor auctor. Integer et interdum eget et. Sem bibendum quis faucibus enim a. Habitasse at egestas dolor mauris fusce. Maecenas scelerisque donec commodo ullamcorper non. Lobortis odio .
                    </p>
                </div>
                </div>
                <div className='content-chapter-item mb-4'>
                <div className='content-title-cgu'>
                    <h2 className='title-page-cgu'>1. Introduction</h2>
                </div>
                <div className='content-text-cgu-admin'>
                    <div className='content-btn-menu flex-r'>
                        <button className='btn no-link'>
                            <FiMoreHorizontal />
                        </button>
                    </div>
                    <p className='text-cgu-admin py-3'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis neque id volutpat vulputate ante. Accumsan pharetra, elementum amet tempor ante. Tristique scelerisque iaculis donec pellentesque convallis sed. Mattis cursus sed gravida turpis lacus, amet, ut est. Elementum pellentesque bibendum 
Egestas nibh ac sit pretium. Sed diam sit dignissim et facilisis. Bibendum sit dictumst pharetra felis. Nulla euismod porttitor quisque sed ipsum dictum nunc diam. Tortor nulla odio amet nunc in. Lorem faucibus ridiculus integer aliquet massa ac consectetur amet. Aliquam adipiscing congue pellentesque eu euismod ut mollis dolor auctor. Integer et interdum eget et. Sem bibendum quis faucibus enim a. Habitasse at egestas dolor mauris fusce. Maecenas scelerisque donec commodo ullamcorper non. Lobortis odio .
                    </p>
                </div>
                </div>
                <div className='content-chapter-item mb-4'>
                <div className='content-title-cgu'>
                    <h2 className='title-page-cgu'>1. Introduction</h2>
                </div>
                <div className='content-text-cgu-admin'>
                    <div className='content-btn-menu flex-r'>
                        <button className='btn no-link'>
                            <FiMoreHorizontal />
                        </button>
                    </div>
                    <p className='text-cgu-admin py-3'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis neque id volutpat vulputate ante. Accumsan pharetra, elementum amet tempor ante. Tristique scelerisque iaculis donec pellentesque convallis sed. Mattis cursus sed gravida turpis lacus, amet, ut est. Elementum pellentesque bibendum 
Egestas nibh ac sit pretium. Sed diam sit dignissim et facilisis. Bibendum sit dictumst pharetra felis. Nulla euismod porttitor quisque sed ipsum dictum nunc diam. Tortor nulla odio amet nunc in. Lorem faucibus ridiculus integer aliquet massa ac consectetur amet. Aliquam adipiscing congue pellentesque eu euismod ut mollis dolor auctor. Integer et interdum eget et. Sem bibendum quis faucibus enim a. Habitasse at egestas dolor mauris fusce. Maecenas scelerisque donec commodo ullamcorper non. Lobortis odio .
                    </p>
                </div>
                </div>
                <div className='content-chapter-item mb-4'>
                <div className='content-title-cgu'>
                    <h2 className='title-page-cgu'>1. Introduction</h2>
                </div>
                <div className='content-text-cgu-admin'>
                    <div className='content-btn-menu flex-r'>
                        <button className='btn no-link'>
                            <FiMoreHorizontal />
                        </button>
                    </div>
                    <p className='text-cgu-admin py-3'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis neque id volutpat vulputate ante. Accumsan pharetra, elementum amet tempor ante. Tristique scelerisque iaculis donec pellentesque convallis sed. Mattis cursus sed gravida turpis lacus, amet, ut est. Elementum pellentesque bibendum 
Egestas nibh ac sit pretium. Sed diam sit dignissim et facilisis. Bibendum sit dictumst pharetra felis. Nulla euismod porttitor quisque sed ipsum dictum nunc diam. Tortor nulla odio amet nunc in. Lorem faucibus ridiculus integer aliquet massa ac consectetur amet. Aliquam adipiscing congue pellentesque eu euismod ut mollis dolor auctor. Integer et interdum eget et. Sem bibendum quis faucibus enim a. Habitasse at egestas dolor mauris fusce. Maecenas scelerisque donec commodo ullamcorper non. Lobortis odio .
                    </p>
                </div>
                </div>
            </div>
        </div>
    )
}

export default ConfidentialiteTabs