import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import SavonFabricationTable from "../SavonFabricationTable/SavonFabricationTable";

const CosmeticFabricationTabsItem = ({type}:{type:string}) => {
  const [term, setTerm] = useState("");
  return (
    <div className="savon-tabs-component">
      <div className="container-filter-table pb-4">
        <div className="row pb-4">
          <div className="col-md-3 mb-3">
            <div className="form-filter-table position-relative">
              <input
                type="text"
                className="form-control form-control-filter-table"
                placeholder="Recherche"
                onChange={(e) => setTerm(e?.target?.value)}
              />
              <span className="content-icon-filter-table">
                <CiSearch
                  className="filter-icon-table"
                  style={{ color: "#7C97B6" }}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="container-table-savon">
          <SavonFabricationTable type={type} term={term} />
        </div>
      </div>
    </div>
  );
};

export default CosmeticFabricationTabsItem;
