import React from "react";

const TabsMessageSend = () => {
  return (
    <div className="auth-right-side-container">
      <div className="container-step2">
        <div className="container-message-forget-password">
          <p className="message-forget-password mb-0">
            Mail de vérification , contenant le lien de modification du mot de
            passe a été envoyé à{" "}
            <span style={{ color: "#50B2D4" }}>exemple@gmail.com</span>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TabsMessageSend;
