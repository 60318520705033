import React, { useEffect, useState } from "react";
import "./StockPf.css";
import { useNavigate } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";
import StockPfTable from "./StockPfTable/StockPfTable";
import { Fabrication } from "../../../utils/api/fabrication/fabrication.type";
import { TiExport } from "react-icons/ti";
import { useLazyExportPFByUserQuery } from "../../../utils/api/fabrication/fabrication.api";
import { useAppSelector } from "../../../redux/hook";
import Swal from "sweetalert2";

const StockPf = () => {
  const [item, setItem] = useState<Fabrication | undefined>();
  const navigate = useNavigate();
  const { userInfos } = useAppSelector((s) => s?.userInfos);
  const [exportData, { isLoading, isSuccess, isError, error, data }] =
    useLazyExportPFByUserQuery();
  useEffect(() => {
    if (isSuccess) {
      if (data?.success) {
        window.open(data?.pdf_url, "_blank");
      } else {
        Swal.fire({
          icon: "error",
          title:
            data?.message ||
            "Nous n'avons pas pu exporter vos données. Veuillez rééssayer plutard!",
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }

    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isSuccess, isError]);
  return (
    <div className="fournisseur-list-component">
      <div className="container-title-page-admin mb-5">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">Stock produits</h3>
        </div>
        <div className="d-flex gap-2">
          <div className="content-btn-action-title-page-admin">
            <button
              className="btn btn-page-title-admin"
              onClick={() => exportData(userInfos?.user_id)}
              disabled={isLoading}
            >
              {!isLoading && (
                <>
                  <TiExport />
                  <span className="ps-2">Exporter en PDF</span>
                </>
              )}
              {!!isLoading && (
                <>
                  <span
                    className="spinner-border spinner-border-sm me-1 d-inline-block"
                    role="status"
                  ></span>
                  <span>Exportation...</span>
                </>
              )}
            </button>
          </div>
          <div className="content-btn-action-title-page-admin">
            <button
              // to="/fabriquant/recette/:slug"
              className="btn btn-page-title-admin "
              disabled={!item}
              onClick={() => {
                if (item) {
                  navigate(`/fabriquant/recette/${item?.recette?.recette_id}`, {
                    state: item?.recette,
                  });
                }
              }}
            >
              <AiFillEye />
              <span className="ps-2">Voir la recette</span>
            </button>
          </div>
          <div className="content-btn-action-title-page-admin">
            <button
              // to="/fabriquant/fabrication/:slug"
              className="btn btn-page-title-admin"
              disabled={!item}
              onClick={() => {
                if (item) {
                  navigate(`/fabriquant/fabrication/${item?.fabrication_id}`, {
                    state: item,
                  });
                }
              }}
            >
              <AiFillEye />
              <span className="ps-2">Voir la fiche de fabrication</span>
            </button>
          </div>
        </div>
      </div>
      <div className="container-fournisseur-list">
        <StockPfTable setItem={setItem} />
      </div>
    </div>
  );
};

export default StockPf;
