import React from "react";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { NavLink } from "react-router-dom";
import Logo from "../../../assets/appImages/logo.png";
import { ForgetPasswordFormData } from "../../../utils/api/auth/auth.type";
import ErrorMessage from "../../common/ErrorMessage";

export type ForgetPasswordStepPropsType = {
  navigation: any;
};
const InfoEmail = ({
  navigation,
  register,
  onSubmit,
  errors,
  isLoading
}: {
  navigation: any;
  register: UseFormRegister<ForgetPasswordFormData>;
  onSubmit: any;
  isLoading: boolean;
  errors: Partial<FieldErrorsImpl<{email: string}>>;
}) => {
 
  return (
    <div className="auth-right-side-container">
      <div className="auth-form-container text-center pb-4">
        <div className="content-logo-auth pb-2">
          <img src={Logo} alt="logo" className="logo-auth-page" />
        </div>
        <h2 className="title-auth-form-text pt-4">Mot de passe oublié?</h2>
        <div className="content-text-message-login text-center">
          <span className="auth-form-message">
            Entrez votre mail pour réinitialiser votre mot de passe
          </span>
        </div>
        <div className="mt-5">
          <form id="auth-form" onSubmit={onSubmit}>
            <div className="row auth-form-row">
              <div className="col-md-12 auth-input-col mb-3">
                <div className="auth-form-group">
                  <label className="form-label form-label-auth-login">
                    Adresse mail
                  </label>
                  <input
                    type={`email`}
                    className="form-control auth-form-control"
                    id="email"
                    {...register("email")}
                    placeholder="Adresse mail"
                    aria-label="email"
                    data-testid="emailId"
                  />
                  <ErrorMessage message={errors?.email?.message} />
                </div>
              </div>
              <div className="col-md-12 auth-submit-btn-container mt-2">
                <button
                  // onClick={(e) => handleSubmit(e)}
                  type="submit"
                  disabled={isLoading}
                  className="btn auth-submit-btn trans-0-2"
                  data-testid="submitBtnId"
                >
                  {/* Envoyer */}
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>Envoi...</span>
                    </>
                  ) : (
                    "Envoyer"
                  )}
                </button>
              </div>
              <div className="text-center mt-3">
                <NavLink to={"/"} className="forget-password-link">
                  Retour à la page de connexion
                </NavLink>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InfoEmail;
