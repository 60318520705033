/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleannerError } from "../../../../utils/utils";
import {
  CommandeLine,
  CommandeLineFormData,
} from "../../../../utils/api/commande/commande.type";
import { useAppSelector } from "../../../../redux/hook";
import {
  useDeleteCommandeLineMutation,
  useUpdateCommandeLineMutation,
} from "../../../../utils/api/commande/commande.api";
import { Color } from "../../../../utils/theme";
import moment from "moment";

function UseEditCommandeLineForm(commande: CommandeLine) {
  const validationSchema = yup.object().shape({
    category: yup.string().required().label("La catégorie").nullable(),
    nom: yup.string().required().label("Le nom").nullable(),
    numero_lot: yup.string().required().label("Le N° Lot ").nullable(),
    dluo_ddm: yup.string().required().label("DLUO / DDM").nullable(),
    date_ouverture: yup.string().label("La date d'ouverture").nullable(),
    quantite: yup.string().required().label("La quantité").nullable(),
    seuil_alerte: yup.string().required().label("Le seuil d'alerte").nullable(),
    poids: yup
      .string()
      .when("volume", {
        is: (val) => !val,
        then: yup.string().required().label("Le poids").nullable(),
      })
      .nullable(),
    unite_poids: yup
      .string()
      .when("poids", {
        is: (val) => val,
        then: yup.string().required().label("L'unité du poids").nullable(),
      })
      .nullable(),
    volume: yup.string().label("Le volume").nullable(),

    unite_volume: yup
      .string()
      .when("volume", {
        is: (val) => val,
        then: yup.string().required().label("L'unité du volume").nullable(),
      })
      .nullable(),
    volumeExist: yup
      .string()
      .when(["poids", "volume"], {
        is: (val, vol) => !val && !vol,
        then: yup.string().required().label("Le volume").nullable(),
      })
      .nullable(),
    // poids: yup.string().required().label("Le poids").nullable(),
    // unite_poids: yup.string().required().label("L'unité du poids").nullable(),
    // volume: yup.string().required().label("Le volume").nullable(),
    // unite_volume: yup.string().required().label("L'unité du volume").nullable(),
    prix_unite: yup.string().required().label("Le prix par unité").nullable(),
    prix_total_ligne: yup
      .string()
      .required()
      .label("Le prix total de la ligne")
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    clearErrors,
  } = useForm<CommandeLineFormData>({
    resolver: yupResolver(validationSchema),
  });
  const { userInfos } = useAppSelector((s) => s?.userInfos);

  const [options, setOptions] = useState<any>({
    quantite: 0,
    prix_unite: 0,
    prix_total_ligne: 0,
  });
  const navigate = useNavigate();
  const [sendData, { isLoading, isSuccess, error, isError, data }] =
    useUpdateCommandeLineMutation();

  useEffect(() => {
    if (options?.quantite > 0 && options?.prix_unite > 0) {
      let total: any =
        parseInt(options?.quantite) * parseFloat(options?.prix_unite);
      options.prix_total_ligne = total;
      setValue(`prix_total_ligne`, total);
    } else {
      let total: any = 0;
      options.prix_total_ligne = total;
      setValue(`prix_total_ligne`, total);
    }
  }, [options]);

  const handleChange = (e) => {
    // console.log("e", e.target.name);
    setValue(`prix_unite`, e.target.value);
    setOptions((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeQty = (e) => {
    setValue("quantite", e.target.value);
    setOptions((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (commande?.ligne_commande_id) {
      console.log(commande);
      const fields: (keyof CommandeLineFormData)[] = [
        "nom",
        "quantite",
        "category",
        "numero_lot",
        "date_ouverture",
        "details_commande_id",
        "dluo_ddm",
        "poids",
        "prix_total_ligne",
        "prix_unite",
        "seuil_alerte",
        "unite_poids",
        "unite_volume",
        "volume",
        "ligne_commande_id",
      ];
      for (let field of fields) {
        register(field);
        if (field === "date_ouverture" || field === "dluo_ddm") {
          if (commande[field]) {
            setValue(
              field,
              moment(commande[field], "DD-MM-YYYY").format("YYYY-MM-DD")
            );
          }
          
        } else {
          setValue(field, commande[field]);
        }
      }
      setOptions({
        quantite: commande?.quantite,
        prix_unite: commande?.prix_unite,
        prix_total_line: commande?.prix_total_ligne,
      });
    } else {
      setTimeout(() => {
        navigate(-1);
      }, 10);
    }
  }, [commande]);

  useEffect(() => {
    if (isSuccess) {
      if (data?.success) {
        Swal.fire({
          icon: "success",
          iconColor: Color.success,
          confirmButtonColor: Color.success,
          title: data?.message,
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          if (userInfos?.status === "fabricant") {
            navigate(`/fabriquant/stock-mp`);
          } else {
            navigate("/admin/stock-mp");
          }
        });
      } else {
        // setError("password", { message: data?.message });
        Swal.fire({
          icon: "error",
          title: data?.message,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    console.log("error", errors);
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSubmit = async (data: CommandeLineFormData) => {
    if (!commande) {
      data["fabriquant_id"] = userInfos?.user_id;
    }
    console.log("data", data);
    await sendData(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    Controller,
    control,
    handleChange,
    handleChangeQty,
    options,
  };
}

export default UseEditCommandeLineForm;

export function UseDeleteCommandeItem(id: string) {
  const [deleteData] = useDeleteCommandeLineMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce stock ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(id);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        console.log(result);
        Swal.fire({
          icon: "success",
          title: `Stock supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
