/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import "./ProfilPage.css";
import { NavLink } from "react-router-dom";
import { AiFillEye, AiOutlineMail } from "react-icons/ai";
import { IoCallOutline, IoLocationSharp } from "react-icons/io5";
import ChangePasswordModal from "./modals/ChangePasswordModal";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { getAvatar } from "../../../utils/utils";
import { BsCameraFill } from "react-icons/bs";
import Swal from "sweetalert2";
import { Color } from "../../../utils/theme";
import { onSetUser } from "../../../redux/slice/User.slice";
import { useEditUserAvatarMutation } from "../../../utils/api/user/user.api";

const ProfilPage = () => {
  const { userInfos } = useAppSelector((s) => s?.userInfos);
  const [modalChangePassword, setModalChangePassword] = React.useState(false);
  const [avatar, setAvatar] = React.useState<any>(null);
  const [sendData, { isSuccess: done, isError, error, data, isLoading: load }] =
    useEditUserAvatarMutation();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (done) {
      // console.log("data", data);
      dispatch(onSetUser(data));
      setAvatar(null);
      Swal.fire({
        icon: "success",
        title: "Photo de profil modifiée avec succès !",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [done, isError]);

  const changeImage = (e: any) => {
    const file = e.target.files[0];
    setAvatar(URL.createObjectURL(file));
    let fd = new FormData();
    fd.append("avatar", file);
    sendData({ user_id: userInfos?.user_id, data: fd });
  };

  function openModalChangePassword() {
    setModalChangePassword(true);
  }
  return (
    <div className="fournisseur-list-component">
      <div className="container-title-page-admin mb-5">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">Mon profil</h3>
        </div>
        <div className="content-btn-action-title-page-admin">
          <NavLink
            to={
              userInfos?.status === "fabricant"
                ? "/fabriquant/modifier-profil"
                : "/admin/modifier-profil"
            }
            className="btn btn-page-title-admin"
          >
            <AiFillEye />
            <span className="ps-2">Editer mon profil</span>
          </NavLink>
        </div>
      </div>
      <div className="container-profil-page">
        <div className="content-profil-info b-b-1 pb-5">
          {/* <div className="container-photo-profil">
            <img
              src={getAvatar(userInfos?.avatar)}
              alt="Profil"
              className="photo-profil-img"
            />
          </div> */}
          <div className="content-pp-profil-fabriquant text-center pb-2">
            <div className="d-flex justify-content-center align-items-center leader-auto-user-avatar-container">
              <label htmlFor="input-test" className="input-testt">
                <img
                  src={avatar || getAvatar(userInfos?.avatar)}
                  alt={userInfos?.first_name + " " + userInfos?.last_name}
                  className="pp-profil-user-fabriquant"
                />
                <div className="leader-auto-user-avatar-edit-hover">
                  {load ? (
                    <span
                      className="spinner-border spinner-border-sm custom-spinner text-dark me-1 d-inline-block"
                      role="status"
                    ></span>
                  ) : (
                    <BsCameraFill className="edit-avatar-camera-icon" />
                  )}
                </div>
              </label>
              <input
                type="file"
                className="input-add-avatar"
                id="input-test"
                accept="image/*"
                onChange={(e) => changeImage(e)}
              />
            </div>
          </div>
          <h3 className="name-profil-content py-3">
            {userInfos?.first_name + " " + userInfos?.last_name}
          </h3>
          {/* <div className="content-text-describe-name-profil ">
            <p className="text-describe-name-profil w-50">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus
              duis in nunc platea orci nibh. Sit ante viverra sed cursus
              commodo, eget. Enim vitae.
            </p>
          </div> */}
          <div className="container-info-contact-profil-page">
            <div className="container-info-contact-profil-page-item">
              <p className="text-info-contact-profil-page-item">Adresse</p>
              <div className="item-info-contact-profil">
                <span className="content-icon-info-contact-profil">
                  <IoLocationSharp />
                </span>
                <span className="text-libelle-info-contact-profil">
                  {userInfos?.address || "Non défini"}
                </span>
              </div>
            </div>
            <div className="container-info-contact-profil-page-item">
              <p className="text-info-contact-profil-page-item">Téléphone</p>
              <div className="item-info-contact-profil">
                <span className="content-icon-info-contact-profil">
                  <IoCallOutline />
                </span>
                <span className="text-libelle-info-contact-profil">
                  {userInfos?.phone || "Non défini"}
                </span>
              </div>
            </div>
            <div className="container-info-contact-profil-page-item">
              <p className="text-info-contact-profil-page-item">Email</p>
              <div className="item-info-contact-profil">
                <span className="content-icon-info-contact-profil">
                  <AiOutlineMail />
                </span>
                <span className="text-libelle-info-contact-profil">
                  {userInfos?.email}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-change-password-profil my-5">
          <div className="col-md-6 mb-3">
            <div className="row-addcontent-col-form-add">
              <input
                className="form-control form-control-add-form"
                type="password"
                placeholder="****************"
                readOnly
              />
            </div>
          </div>
          <div className="col-md-3 offset-md-3">
            <div className="content-btn-action-title-page-admin">
              <button
                className="btn btn-page-title-admin w-100"
                onClick={openModalChangePassword}
              >
                Editer mon mot de passe
              </button>
              <ChangePasswordModal
                modalChangePassword={modalChangePassword}
                setModalChangePassword={setModalChangePassword}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilPage;
