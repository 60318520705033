import React from "react";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ErrorMessage from "../../common/ErrorMessage";
import ReceptionnerCommandeTable from "./ReceptionnerCommandeTable/ReceptionnerCommandeTable";
import UseCrudCommandeForm from "./requestForm/UseCrudCommandeForm";

const ReceptionnerCommande = () => {
  const {
    register,
    onSubmit,
    isLoading,
    errors,
    Controller,
    control,
    handleChange,
    option,
    options,
  } = UseCrudCommandeForm();
  const animatedComponents = makeAnimated();
  return (
    <div className="fournisseur-list-component">
      <div className="container-title-page-admin mb-5">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">Enregistrer une commande </h3>
        </div>
      </div>
      <div className="container-fournisseur-list">
        <form
          className="container-filter-table pb-5 b-b-1 mb-5"
          onSubmit={onSubmit}
        >
          <div className="row mb-3">
            <div className="col-md-3 mb-3">
              <div className="form-filter-table">
                <label
                  htmlFor="num"
                  className="form-label form-label-add-commande"
                >
                  Numéro de la commande
                </label>
                <input
                  type="text"
                  className="form-control form-control-filter-table"
                  placeholder="Numéro de la commande"
                  id="num"
                  {...register("numero_commande")}
                />
                <ErrorMessage message={errors?.numero_commande?.message} />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="form-filter-table">
                <label
                  htmlFor="fournisseur_id"
                  className="form-label form-label-add-commande"
                >
                  Fournisseur
                </label>

                <Controller
                  name="fournisseur_id"
                  control={control}
                  render={({ field }: any) => {
                    return (
                      <Select
                        {...field}
                        closeMenuOnSelect={true}
                        placeholder="Fournisseur"
                        components={animatedComponents}
                        options={options}
                        inputClass="form-control form-control-select-filter-table"
                        classNamePrefix="select-comodite"
                        // isLoading={loading}
                        onChange={handleChange}
                        value={option}
                      />
                    );
                  }}
                />
                <ErrorMessage message={errors?.fournisseur_id?.message} />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="form-filter-table">
                <label
                  htmlFor="num_client"
                  className="form-label form-label-add-commande"
                >
                  Numéro de client{" "}
                </label>
                <input
                  type="text"
                  className="form-control form-control-filter-table"
                  placeholder="Numéro de client "
                  id="num_client"
                  {...register("numero_client")}
                />
                <ErrorMessage message={errors?.numero_client?.message} />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="form-filter-table">
                <label
                  htmlFor="date"
                  className="form-label form-label-add-commande"
                >
                  Date
                </label>
                <input
                  type="date"
                  id="date"
                  className="form-control form-control-filter-table"
                  {...register("date_commande")}
                />
                <ErrorMessage message={errors?.numero_client?.message} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 offset-md-9 col-lg-2 offset-md-10">
              <button
                type="submit"
                className="btn btn-page-title-admin w-100"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>Ajout...</span>
                  </>
                ) : (
                  "Ajouter"
                )}
              </button>
            </div>
          </div>
        </form>
        <div className="commande-tale pt-4 center-admin-table">
          <ReceptionnerCommandeTable />
        </div>
      </div>
    </div>
  );
};

export default ReceptionnerCommande;
