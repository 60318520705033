import React from "react";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../../../common/ErrorMessage";
import { PropsTypeFabric } from "../AddNewFabrication";

const FabricationTabs = ({
  navigation,
  register,
  errors,
}: PropsTypeFabric) => {
  return (
    <div className="quantite-tabs-component">
      <div className="container-form-add">
        <div>
          <div className="row row-add">
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="Coefficient"
                  className="form-label form-label-add-recette"
                >
                  Coefficient multiplicateur{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="number"
                  step={"any"}
                  min={0}
                  placeholder="Coefficient multiplicateur"
                  {...register("fab_coefficient_multiplicateur")}
                />
                <ErrorMessage
                  message={
                    errors?.fab_coefficient_multiplicateur &&
                    errors?.fab_coefficient_multiplicateur?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="moule"
                  className="form-label form-label-add-recette"
                >
                  Moules utilisés{" "}
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="text"
                  placeholder="Moules utilisés"
                  {...register("fab_moules_utilises")}
                />
                <ErrorMessage
                  message={
                    errors?.fab_moules_utilises &&
                    errors?.fab_moules_utilises?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="materiel"
                  className="form-label form-label-add-recette"
                >
                  Matériel utilisé
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="text"
                  placeholder="Matériel utilisé"
                  {...register("fab_materiel_utilise")}
                />
                <ErrorMessage
                  message={
                    errors?.fab_materiel_utilise &&
                    errors?.fab_materiel_utilise?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label htmlFor="" className="form-label form-label-add-recette">
                  A cocher <span className="text-danger">*</span>
                </label>
                <div className="flex-sb">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="Lessive de soude"
                      id="flexCheckDefault"
                      {...register("fab_acocher")}
                    />
                    <label
                      className="form-check-label form-check-label-costum"
                      htmlFor="flexCheckDefault"
                    >
                      Lessive de soude
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="Eau + soude"
                      id="flexCheckDefault"
                      {...register("fab_acocher")}
                    />
                    <label
                      className="form-check-label form-check-label-costum"
                      htmlFor="flexCheckDefault"
                    >
                      Eau + soude
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="Autre liquide"
                      id="flexCheckDefault"
                      {...register("fab_acocher")}
                    />
                    <label
                      className="form-check-label form-check-label-costum"
                      htmlFor="flexCheckDefault"
                    >
                      Autre liquide
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-control form-control-add-form"
                      type="number"
                      min={0}
                      step={"any"}
                      placeholder="Concentration (%) *"
                      {...register("fab_acocher_concentration")}
                    />
                  </div>
                </div>
                <div className="flex-sb">
                  <div className="w-60">
                    <ErrorMessage
                      message={
                        errors?.fab_acocher && errors?.fab_acocher?.message
                      }
                    />
                  </div>
                  <div className="w-35">
                    <ErrorMessage
                      message={
                        errors?.fab_acocher_concentration &&
                        errors?.fab_acocher_concentration?.message
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="fab_temperature_corps_gras"
                  className="form-label form-label-add-recette"
                >
                  Température des corps gras{" "}
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="number"
                  placeholder="Température des corps gras"
                  step={"any"}
                  {...register("fab_temperature_corps_gras")}
                />
                <ErrorMessage
                  message={
                    errors?.fab_temperature_corps_gras &&
                    errors?.fab_temperature_corps_gras?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="fab_temperature_soude"
                  className="form-label form-label-add-recette"
                >
                  Température de la soude 
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="number"
                  placeholder="Température de la soude"
                  step={"any"}
                  {...register("fab_temperature_soude")}
                />
                <ErrorMessage
                  message={
                    errors?.fab_temperature_soude &&
                    errors?.fab_temperature_soude?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label htmlFor="" className="form-label form-label-add-recette">
                  Additif ajouté dans 
                </label>
                <div className="flex-sb">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Soude"
                      id="flexCheckDefault"
                      {...register("fab_additif_ajoute_dans")}
                    />
                    <label
                      className="form-check-label form-check-label-costum"
                      htmlFor="flexCheckDefault"
                    >
                      Soude
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Corps gras"
                      id="flexCheckDefault"
                      {...register("fab_additif_ajoute_dans")}
                    />
                    <label
                      className="form-check-label form-check-label-costum"
                      htmlFor="flexCheckDefault"
                    >
                      Corps gras
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Trace"
                      id="flexCheckDefault"
                      {...register("fab_additif_ajoute_dans")}
                    />
                    <label
                      className="form-check-label form-check-label-costum"
                      htmlFor="flexCheckDefault"
                    >
                      Trace
                    </label>
                  </div>
                </div>
                <ErrorMessage
                  message={
                    errors?.fab_additif_ajoute_dans &&
                    errors?.fab_additif_ajoute_dans?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="fab_surgras"
                  className="form-label form-label-add-recette"
                >
                  Surgras 
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="number"
                  step={"any"}
                  readOnly
                  placeholder="Surgras"
                  {...register("fab_surgras")}
                />
                <ErrorMessage
                  message={errors?.fab_surgras && errors?.fab_surgras?.message}
                />
              </div>
            </div>
          </div>
          <div className="flex-r gap-4 align-items-center py-5">
            <button
              className="btn btn-cancelled-recette"
              onClick={() => navigation.previous()}
            >
              Retour
            </button>
            <button
              type="submit"
              className="btn auth-submit-btn-recette trans-0-2"
            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FabricationTabs;
