import React from "react";
import IconCall from "../../../../assets/icons/call.png";
import IconMail from "../../../../assets/icons/mail.png";
import IconLocate from "../../../../assets/icons/locate.png";
import IconInfo from "../../../../assets/icons/info.png";
import IconShop from "../../../../assets/icons/shop.png";
import { IUser } from "../../../../utils/api/user/user.type";
import { getAvatar, getInitials } from "../../../../utils/utils";
import { ApiBaseUrl } from "../../../../utils/http";

const UserInfosModal = ({ item }: { item: IUser }) => {
  return (
    <div
      className="modal fade"
      id={`userItemModal${item?.user_id}`}
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close no-style-btn-modal btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="container-detail-fournisseur">
              <div className="header-fournisseur text-center">
                <div className="container-photo-profil">
                  <img
                    src={getAvatar(item?.avatar)}
                    alt="Profil"
                    className="photo-profil-img"
                  />
                </div>
                <p className="name-fournisseur-item mb-1">
                  {item?.first_name + " " + item?.last_name}
                </p>
                <p className="text-description-fournisseur">
                  ID {item?.user_id || "non défini"}
                </p>
              </div>
              <h5 className="modal-title">Details</h5>
              <div className="container-info-fournisseur mt-4 row">
                <div className="info-fournisseur-detail-item col-md-6">
                  <div className="content-icon-detail-fournisseur">
                    <img
                      src={IconInfo}
                      alt="Nom"
                      className="icon-detail-fournisseur"
                    />
                  </div>
                  <div className="fournisseur-info-detail">
                    <p className="libelle-info-detail mb-1">Nom</p>
                    <p className="value-info-detail mb-0">
                      {item?.first_name + " " + item?.last_name}
                    </p>
                  </div>
                </div>
                <div className="info-fournisseur-detail-item col-md-6">
                  <div className="content-icon-detail-fournisseur">
                    <img
                      src={IconCall}
                      alt="Téléphone"
                      className="icon-detail-fournisseur"
                    />
                  </div>
                  <div className="fournisseur-info-detail">
                    <p className="libelle-info-detail mb-1">Téléphone</p>
                    <p className="value-info-detail mb-0">
                      {item?.phone || "Non défini"}
                    </p>
                  </div>
                </div>
                <div className="info-fournisseur-detail-item col-md-6">
                  <div className="content-icon-detail-fournisseur">
                    <img
                      src={IconMail}
                      alt="Téléphone"
                      className="icon-detail-fournisseur"
                    />
                  </div>
                  <div className="fournisseur-info-detail">
                    <p className="libelle-info-detail mb-1">Email</p>
                    <p className="value-info-detail mb-0">
                      {item?.email || "Non défini"}
                    </p>
                  </div>
                </div>
                <div className="info-fournisseur-detail-item col-md-6">
                  <div className="content-icon-detail-fournisseur">
                    <img
                      src={IconLocate}
                      alt="Téléphone"
                      className="icon-detail-fournisseur"
                    />
                  </div>
                  <div className="fournisseur-info-detail">
                    <p className="libelle-info-detail mb-1">Adresse</p>
                    <p className="value-info-detail mb-0">
                      {item?.address || "Non défini"}
                    </p>
                  </div>
                </div>
                {/* {!!item?.permissions?.length && (
                  <div className="info-fournisseur-detail-item row">
                    <div className="content-icon-detail-fournisseur">
                      <p className="libelle-info-detail mb-1">Permissions</p>
                    </div>
                    <ul className="fournisseur-info-detail row">
                      {item?.permissions?.map((el) => (
                        <li
                          className="value-info-detail mb-3 col-md-6"
                          key={el?.id}
                        >
                          {el?.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )} */}

                {/* */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfosModal;
