/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useResetUserPasswordMutation } from "../../../utils/api/auth/auth.api";
import { ForgetPasswordFormData, ResetPasswordFormData } from "../../../utils/api/auth/auth.type";
import { Color } from "../../../utils/theme";
import { cleannerError } from "../../../utils/utils";
import { validatePassword } from "./UseRegisterForm";

function UseResetPassword() {
  const [resetPassword, { error, isSuccess, isLoading, data }] =
    useResetUserPasswordMutation();

  const navigate = useNavigate();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        reset_password_code: yup.string().required().label("Le code"),
        password: validatePassword(yup),
        password_confirmation: yup
          .string()
          .required().label("La confirmation du mot de passe")
          .oneOf(
            [yup.ref("password"), null],
            "Les mots de passe doivent correspondre"
          ),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
    setValue,
  } = useForm<ResetPasswordFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [haveMinUppercase, setHaveMinUppercase] = useState(false);
  const [haveMinLowercase, setHaveMinLowercase] = useState(false);
  const [haveMinNumber, setHaveMinNumber] = useState(false);
  const [haveMinCharacter, setHaveMinCharacter] = useState(false);
  const [haveMinSpecialCharacter, setHaveMinSpecialCharacter] = useState(false);

  const state = useLocation().state as ForgetPasswordFormData;
  const countUppercase = (str: any) => {
    if (str.replace(/[^A-Z]/g, "")?.length >= 1) {
      return true;
    }
    return false;
  };

  const countLowercase = (str: any) => {
    if (str.replace(/[^a-z]/g, "")?.length >= 1) {
      return true;
    }
    return false;
  };

  const countNumber = (str: any) => {
    if (str.replace(/[^0-9]/g, "")?.length >= 1) {
      return true;
    }
    return false;
  };

  const countCharacters = (str: any) => {
    if (str?.length >= 8) {
      return true;
    }
    return false;
  };

  const countSpecial = (str: any) => {
    const punct = `/[!@#$%^&*()_+\\-=\\[\\]{};':"\\|,.<>\\/?]+/;`;
    let count = 0;
    for (let i = 0; i < str?.length; i++) {
      if (!punct.includes(str[i])) {
        continue;
      }
      count++;
    }
    // return count;
    if (count >= 1) {
      return true;
    }
    return false;
  };

  const passwordHandleChange = (e: any) => {
    var password = e.target.value;
    if (countUppercase(password)) {
      setHaveMinUppercase(true);
    } else {
      setHaveMinUppercase(false);
    }
    if (countLowercase(password)) {
      setHaveMinLowercase(true);
    } else {
      setHaveMinLowercase(false);
    }
    if (countNumber(password)) {
      setHaveMinNumber(true);
    } else {
      setHaveMinNumber(false);
    }
    if (countCharacters(password)) {
      setHaveMinCharacter(true);
    } else {
      setHaveMinCharacter(false);
    }
    if (countSpecial(password)) {
      setHaveMinSpecialCharacter(true);
    } else {
      setHaveMinSpecialCharacter(false);
    }

    setValue("password", e.target.value);
  };

  useEffect(() => {
    if (state?.email) {
      setValue("email", state.email);
    } else {
      setTimeout(() => navigate("/mot-de-passe-oublie"), 10);
    }
  }, [state?.email]);

  useEffect(() => {
    if (error) {
      console.log("error", error);
      const err = error as any;
      const { message = "Une erreur a survenue" } = err.data || {};
      setError("reset_password_code", { message: message });
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      if (data?.success) {
        Swal.fire({
          icon: "success",
          iconColor: Color.success,
          confirmButtonColor: Color.success,
          title: data?.message,
          showConfirmButton: false,
          timer: 3000,
        }).then(() => navigate("/"));
      } else {
        setError("reset_password_code", { message: data?.message });
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (errors) {
      cleannerError(errors, clearErrors);
    }
  }, [errors]);

  const onSubmit = async (data: ResetPasswordFormData) => {
    console.log("data",data)
    await resetPassword(data);
  };
  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
    clearErrors,
    isLoading,
    passwordHandleChange,
    haveMinCharacter,
    haveMinLowercase,
    haveMinNumber,
    haveMinSpecialCharacter,
    haveMinUppercase,
  };
}

export default UseResetPassword;
