import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import SavonRecetteTableData from "./SavonRecetteTableData";
import TableSkeleton from "../../../common/TableSkeleton";
import { FiEdit, FiEye, FiTrash } from "react-icons/fi";
import { MdOutlineNextPlan } from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";
import { useGetRecetteByUserQuery } from "../../../../utils/api/recette/recette.api";
import { useAppSelector } from "../../../../redux/hook";
import {
  Recette,
  RecetteItem,
} from "../../../../utils/api/recette/recette.type";
import { CustomPagination } from "../../StockMP/StockMPTable/StockMPTable";
import { UseDeleteRecetteItem } from "../AddNewRecette/requestForm/UseCrudRecetteForm";
import { getTotalWeight } from "../../../../utils/utils";

function DeleteItemBtn({ id }: { id: string }) {
  const onDelete = UseDeleteRecetteItem(id);

  return (
    <button
      className="btn btn-action-modal-icon with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={() => onDelete()}
    >
      <FiTrash />
    </button>
  );
}
function SavonRecetteTable({ type, term }: { type: string; term: string }) {
  const [page, setPage] = useState(1);
  const { userInfos } = useAppSelector((s) => s?.userInfos);
  const { data = { data: { data: [], total: 0 } }, isLoading } =
    useGetRecetteByUserQuery({
      id: userInfos?.user_id,
      page,
      type,
      term: term || null,
    });
  // const navigate = useNavigate();

  // useEffect(() => {
  //   console.log("data", data);
  //   if (data?.data?.data?.length) {
  //     console.log(
  //       getTotalWeight(data?.data?.data[0]?.recette_matiere_premieres),"kg"
  //     );
  //   }
  // }, [data]);

  const priceFormatter = (cell) => {
    return (
      <>
        <p className="price-table-format mb-0">{cell} €</p>
      </>
    );
  };

  const poidsFormatter = (cell: RecetteItem[]) => {
    return (
      <>
        <p className="price-table-format mb-0">{getTotalWeight(cell)} </p>
      </>
    );
  };
  const actionFormatter = (cell: string, row: Recette) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-2 align-items-center">
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/fabriquant/recette/${cell}`}
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Visualiser"
              state={row}
            >
              <FiEye />
            </NavLink>
          </div>

          <div className="container-btn-action-icon-modal">
            <NavLink
              to="/fabriquant/fabriquer"
              state={row}
              className="btn btn-action-redirect-table"
            >
              <span className="pe-3">Fabriquer cette recette</span>
              <MdOutlineNextPlan style={{ fontSize: 18 }} />
            </NavLink>
          </div>
          <div className="container-btn-action-icon-modal">
            <NavLink
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Modifier"
              to={`/fabriquant/modifier-recette/${cell}`}
              state={row}
            >
              <FiEdit />
            </NavLink>
          </div>
          <div className="container-btn-action-icon-modal">
            <DeleteItemBtn id={cell} />
          </div>
        </div>
      </>
    );
  };

  const rowClasses = () => {
    return "custom-row-class";
  };
  const columns = [
    {
      dataField: "nom_recette",
      text: "Nom de la recette",
    },
    {
      dataField: "code_interne_recette",
      text: "Code interne",
    },
    {
      dataField: "recette_matiere_premieres",
      text: "Poids",
      formatter: (cell: RecetteItem[]) => poidsFormatter(cell),
    },
    {
      dataField: "cout_fabrication_total",
      text: "Coût total",
      formatter: (cell: number) => priceFormatter(cell),
    },
    {
      dataField: "recette_id",
      text: "Actions",
      formatter: (cell: string, row: Recette) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.data?.data}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            // selectRow={selectRow}
            // rowEvents={rowEvents}
            rowClasses={rowClasses}
            noDataIndication={() => "Aucune donnée trouvée"}
          />
          <CustomPagination
            nbPages={data?.data?.total}
            page={page}
            onChange={(page) => {
              setPage(page);
            }}
            perPage={10}
          />
        </>
      )}
    </>
  );
}

export default SavonRecetteTable;
