/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { PaginationResults, PaginationResultsV2, TypeQuery } from "../../type";
import { QueryUrl, useLocationState } from "../../utils";
import { prepareHeaders } from "../user/user.api";
import {
  CommandeFormData,
  CommandeItem,
  CommandeItemFormData,
  CommandeLine,
  CommandeLineFormData,
  ICommandes,
} from "./commande.type";

export const CommandeApi = createApi({
  reducerPath: "commandeApi",
  tagTypes: [
    "commandeByID",
    "commandeList",
    "commandeListByUser",
    "commandeLinesByUser",
    "commandeLinesByFournisseur",
    "commandeLineList",
    "mpByFournisseur",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/v1/app/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getCommandeByUser: builder.query<
      PaginationResultsV2<ICommandes>,
      TypeQuery
    >({
      providesTags: ["commandeListByUser"],
      query: ({ id, ...query }) =>
        QueryUrl(`commandes-by-fabriquant/${id}/`, query),
    }),
    getCommandeList: builder.query<PaginationResults<ICommandes>, TypeQuery>({
      providesTags: ["commandeList"],
      query: (query) => QueryUrl("commandes/", query),
    }),
    addOrUpdateCommande: builder.mutation<
      { message: string; success: boolean; data: ICommandes },
      {
        commande_id?: string;
        data: CommandeFormData | FormData;
      }
    >({
      invalidatesTags: ["commandeList", "commandeListByUser", "commandeByID"],
      query: ({ commande_id, data }) => {
        if (commande_id) {
          return {
            url: `commandes/${commande_id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `commandes/`,
          method: "POST",
          body: data,
        };
      },
    }),
    getCommandeById: builder.query<
      { message: string; success: boolean; data: ICommandes },
      TypeQuery
    >({
      query: (id) => `commandes/${id}`,
      providesTags: ["commandeByID"],
    }),
    deleteCommande: builder.mutation<
      { message: string; success: boolean; data: string },
      string
    >({
      query: (commande_id) => ({
        url: `commandes/${commande_id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["commandeList", "commandeListByUser", "commandeByID"],
    }),
    addOrUpdateCommandeDetails: builder.mutation<
      { message: string; success: boolean; data: CommandeItem },
      {
        details_commande_id?: string;
        data: CommandeItemFormData | FormData;
      }
    >({
      invalidatesTags: [
        "commandeList",
        "commandeListByUser",
        "commandeByID",
        "commandeLinesByUser",
        "mpByFournisseur",
      ],
      query: ({ details_commande_id, data }) => {
        if (details_commande_id) {
          return {
            url: `/from-commande-update-details-commande/${details_commande_id}/`,
            method: "POST",
            body: data,
          };
        }
        return {
          url: `details_commandes/`,
          method: "POST",
          body: data,
        };
      },
    }),
    getCommandeLineList: builder.query<
      PaginationResults<CommandeLine>,
      TypeQuery
    >({
      providesTags: ["commandeLineList"],
      query: (query) => QueryUrl("ligne_commandes/", query),
    }),
    getCommandeLinesByUser: builder.query<
      PaginationResultsV2<CommandeLine>,
      TypeQuery
    >({
      providesTags: ["commandeLinesByUser"],
      query: ({ id, ...query }) => ({
        url: `post-filter-stocks-mp-by-fabriquant/${id}/`,
        method: "POST",
        body: query,
      }),
    }),
    getCommandeLinesByFournisseur: builder.query<
      PaginationResultsV2<CommandeLine>,
      TypeQuery
    >({
      providesTags: ["commandeLinesByFournisseur"],
      query: ({ id, ...query }) =>
        QueryUrl(`stocks-mp-from-by-fournisseur/${id}/`, query),
    }),
    getMPByFournisseur: builder.query<
      PaginationResults<CommandeLine>,
      TypeQuery
    >({
      providesTags: ["mpByFournisseur"],
      query: ({ id, ...query }) =>
        QueryUrl(`/stocks-mp-by-fabriquant-without-pagination/${id}/`, query),
    }),
    updateCommandeLine: builder.mutation<
      { message: string; success: boolean; data: CommandeLine },
      CommandeLineFormData
    >({
      invalidatesTags: [
        "commandeList",
        "commandeListByUser",
        "commandeLinesByUser",
        "mpByFournisseur",
      ],
      query: ({ ligne_commande_id, ...data }) => {
        return {
          url: `ligne_commandes/${ligne_commande_id}/`,
          method: "PUT",
          body: data,
        };
      },
    }),
    deleteCommandeLine: builder.mutation<
      { message: string; success: boolean; data: string },
      string
    >({
      query: (ligne_commande_id) => ({
        url: `ligne_commandes/${ligne_commande_id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "commandeList",
        "commandeListByUser",
        "commandeLinesByUser",
        "mpByFournisseur",
      ],
    }),
    getCoutMoyen: builder.mutation<any, any>({
      query: ({ fabricant_id, data }) => ({
        url: `cout-moyen-matiere-premiere/${fabricant_id}`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useAddOrUpdateCommandeDetailsMutation,
  useAddOrUpdateCommandeMutation,
  useDeleteCommandeMutation,
  useGetCommandeByIdQuery,
  useGetCommandeByUserQuery,
  useGetCommandeListQuery,
  useLazyGetCommandeByIdQuery,
  useGetCommandeLinesByUserQuery,
  useUpdateCommandeLineMutation,
  useDeleteCommandeLineMutation,
  useGetCommandeLinesByFournisseurQuery,
  useGetCommandeLineListQuery,
  useGetMPByFournisseurQuery,
  useGetCoutMoyenMutation,
} = CommandeApi;

export function useCommandeFromLocation(): [
  ICommandes,
  boolean,
  string,
  (id: string) => any
] {
  const itemState = useLocationState<ICommandes | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { id } = useParams<{ id: string }>();
  const [findById, { data, isError, isLoading }] =
    useLazyGetCommandeByIdQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      findById(id as any);
    }
    console.log("itemState", itemState);
  }, [itemState, id]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate("/fabriquant/stock-mp", { replace: true });
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      console.log("data", data);
      setItem(data?.data);
    }
  }, [data]);

  return [item as ICommandes, isLoading, id as string, findById as any];
}
