import React from "react";
import { NavLink } from "react-router-dom";
import HeaderAdmin from "../TableauDeBord/navBar/HeaderAdmin/HeaderAdmin";
import { BsCheckLg } from "react-icons/bs";
import { useRequestFromParams } from "../../utils/api/auth/auth.api";
import { Color } from "../../utils/theme";
import { MdClose } from "react-icons/md";

const RegisterOk = () => {
  const [item, isLoading] = useRequestFromParams();
  return (
    <div>
      <HeaderAdmin />
      <div className="container-page-login-app mt-5">
        <div className="auth-row pt-5">
          <div className="auth-col auth-right-side-col">
            <div className="auth-right-side-container">
              <div className="auth-form-container text-center pb-4">
                <div className="content-logo-auth pt-5">
                  {!!isLoading && (
                    <span
                      className="spinner-border spinner-border-xl text-light me-1 d-inline-block load-color"
                      role="status"
                      style={{
                        width: "10rem",
                        height: "10rem",
                      }}
                    ></span>
                  )}
                  {!isLoading &&
                    (item?.success ? (
                      <BsCheckLg
                        className="icon-info-after-register"
                        style={{ color: "#0D9C8F" }}
                      />
                    ) : item?.success === false ? (
                      <MdClose className="icon-info-after-register color-red" />
                    ) : (
                      <span
                        className="spinner-border spinner-border-xl text-light me-1 d-inline-block load-color"
                        role="status"
                        style={{
                          width: "10rem",
                          height: "10rem",
                        }}
                      ></span>
                    ))}
                </div>
                <h2 className="title-auth-form-text pt-4">
                  {" "}
                  {!isLoading &&
                    (item?.success
                      ? "Wooohooo!"
                      : item?.success === false
                      ? "Ohhh no!!"
                      : "")}{" "}
                </h2>
                <div className="content-text-message-login text-center">
                  <p className="auth-form-message">
                    {/* Lorem ipsum dolor sit amet consectetur. Commodo varius
                    turpis nibh congue arcu. Fringilla mauris euismod neque
                    egestas a. */}
                    {!isLoading && item?.message}
                  </p>
                </div>
                <div className="mt-5">
                  <form id="auth-form">
                    <div className="row auth-form-row">
                      <div className="col-md-12 auth-submit-btn-container mt-2">
                        {!isLoading && (
                          <NavLink
                            to="/"
                            replace
                            className="btn auth-submit-btn trans-0-2"
                          >
                            Connectez-vous
                          </NavLink>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterOk;
