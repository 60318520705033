import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { RegisterFormData } from "../../../utils/api/auth/auth.type";
import { useRegisterUserMutation } from "../../../utils/api/auth/auth.api";
import { cleannerError, validatePhone } from "../../../utils/utils";
import { Color } from "../../../utils/theme";

export function validatePassword(Yup: any) {
  return Yup.string()
    .required()
    .matches(/[a-z]+/, "Au moins une minuscule.")
    .matches(/[A-Z]+/, "Au moins une majuscule.")
    .matches(/[!@#$%^&*(),;-_+*/.?":{}|<>]+/, "Au moins un caractère spécial")
    .matches(/\d+/, "Au moins un chiffre.")
    .min(8)
    .label("Le mot de passe");
}

function UseRegisterForm() {
  const [country, setCountry] = useState<string>("");
  const validationSchema = yup.object().shape(
    {
      first_name: yup.string().required().label("Le prénom"),
      last_name: yup.string().required().label("Le nom"),
      // phone: yup.string().label("Le numéro de téléphone").nullable(),
      phone: validatePhone(yup, country),
      email: yup.string().email().required().label("L'email"),
      password: validatePassword(yup),
      password_confirmation: yup
        .string()
        .oneOf(
          [yup.ref("password"), null],
          "Les mots de passe doivent correspondre"
        )
        .required()
        .label("La confirmation du mot de passe"),
    },
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<RegisterFormData>({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const [haveMinUppercase, setHaveMinUppercase] = useState(false);
  const [haveMinLowercase, setHaveMinLowercase] = useState(false);
  const [haveMinNumber, setHaveMinNumber] = useState(false);
  const [haveMinCharacter, setHaveMinCharacter] = useState(false);
  const [haveMinSpecialCharacter, setHaveMinSpecialCharacter] = useState(false);
  //  Calling the Register Mutation
  const [registerUser, { isLoading, isSuccess, error, isError }] =
    useRegisterUserMutation();

  const [phone, setPhone] = useState<string | null>(null);
  const countUppercase = (str: any) => {
    if (str.replace(/[^A-Z]/g, "")?.length >= 1) {
      return true;
    }
    return false;
  };

  const countLowercase = (str: any) => {
    if (str.replace(/[^a-z]/g, "")?.length >= 1) {
      return true;
    }
    return false;
  };

  const countNumber = (str: any) => {
    if (str.replace(/[^0-9]/g, "")?.length >= 1) {
      return true;
    }
    return false;
  };

  const countCharacters = (str: any) => {
    if (str?.length >= 8) {
      return true;
    }
    return false;
  };

  const countSpecial = (str: any) => {
    const punct = `/[!@#$%^&*()_+\\-=\\[\\]{};':"\\|,.<>\\/?]+/;`;
    let count = 0;
    for (let i = 0; i < str?.length; i++) {
      if (!punct.includes(str[i])) {
        continue;
      }
      count++;
    }
    // return count;
    if (count >= 1) {
      return true;
    }
    return false;
  };

  const passwordHandleChange = (e: any) => {
    var password = e.target.value;
    if (countUppercase(password)) {
      setHaveMinUppercase(true);
    } else {
      setHaveMinUppercase(false);
    }
    if (countLowercase(password)) {
      setHaveMinLowercase(true);
    } else {
      setHaveMinLowercase(false);
    }
    if (countNumber(password)) {
      setHaveMinNumber(true);
    } else {
      setHaveMinNumber(false);
    }
    if (countCharacters(password)) {
      setHaveMinCharacter(true);
    } else {
      setHaveMinCharacter(false);
    }
    if (countSpecial(password)) {
      setHaveMinSpecialCharacter(true);
    } else {
      setHaveMinSpecialCharacter(false);
    }

    setValue("password", e.target.value);
  };

  const handleChangePhone = (item, country) => {
    setCountry(country?.countryCode);
    setPhone(item);
     if (parseInt(item) !== parseInt(country?.dialCode)) {
       setValue("phone", item);
     } else {
       setValue("phone", "");
     }
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Inscription reussie!",
        text: "Un email de confirmation vous a été envoyé pour valider votre inscription.",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 5000,
      }).then(() => {
        navigate("/", { replace: true });
      });
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);
      if (
        JSON.parse(err?.data)?.email?.includes(
          "The email has already been taken."
        )
      ) {
        Swal.fire({
          icon: "error",
          title: "Cet email est déjà associé à un compte.",
          showConfirmButton: false,
          timer: 5000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: JSON.parse(err?.data)?.message
            ? JSON.parse(err?.data)?.message
            : `Une erreur de statut ${err?.status} est survenue lors de l'inscription`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSubmit = async (data: RegisterFormData) => {
    console.log("data register", data);
    await registerUser(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    phone,
    handleChangePhone,
    passwordHandleChange,
    haveMinCharacter,
    haveMinLowercase,
    haveMinNumber,
    haveMinSpecialCharacter,
    haveMinUppercase,
  };
}

export default UseRegisterForm;
