/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleannerError, validatePhone } from "../../../../utils/utils";
import { UserFormData } from "../../../../utils/api/user/user.type";
import { useEditUserInfoMutation } from "../../../../utils/api/user/user.api";
import { Color } from "../../../../utils/theme";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { onSetUser } from "../../../../redux/slice/User.slice";

function UserEditUserForm() {
  const [country, setCountry] = useState<string>("");
  const validationSchema = yup.object().shape({
    first_name: yup.string().required().label("Le prénom"),
    last_name: yup.string().required().label("Le nom"),
    address: yup.string().required().label("L'adresse").nullable(),
    phone: validatePhone(yup, country),
    email: yup.string().email().required().label("L'email"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<UserFormData>({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userInfos } = useAppSelector((s) => s?.userInfos);

  const [editUser, { isLoading, isSuccess, error, isError, data }] =
    useEditUserInfoMutation();
  const [address, setAddress] = useState<string>("");
  const [phone, setPhone] = useState<string | null>(null);

  const handleChangePhone = (item, country) => {
    setCountry(country?.countryCode);
    setPhone(item);
     if (parseInt(item) !== parseInt(country?.dialCode)) {
       setValue("phone", item);
     } else {
      setValue("phone","")
     }
  };

  const onChangeAddress = (val) => {
    setAddress(val?.rue);
    setValue("address", val?.rue);
  };

  useEffect(() => {
    if (userInfos?.user_id) {
      const fields: (keyof UserFormData)[] = [
        "first_name",
        "last_name",
        "email",
        "phone",
        "address",
        "user_id",
      ];
      for (let field of fields) {
        register(field);
        setValue(field, userInfos[field]);
      }
      if (userInfos?.address) {
        setAddress(userInfos?.address);
      }

      if (userInfos?.phone) {
        setPhone(userInfos?.phone);
      }
    }
  }, [userInfos]);

  useEffect(() => {
    if (isSuccess) {
      if (data?.success) {
        dispatch(onSetUser(data.data));
        Swal.fire({
          icon: "success",
          iconColor: Color.success,
          confirmButtonColor: Color.success,
          title: data?.message,
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          if (userInfos?.status === "fabricant") {
            navigate("/fabriquant/profil");
          } else {
            navigate("/admin/profil");
          }
        });
      } else {
        // setError("password", { message: data?.message });
        Swal.fire({
          icon: "error",
          title: data?.message,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: JSON.parse(err?.data)?.message
          ? JSON.parse(err?.data)?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSubmit = async (data: UserFormData) => {
    await editUser(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    phone,
    handleChangePhone,
    onChangeAddress,
    address,
    country,
  };
}

export default UserEditUserForm;
