import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import GoogleInput from "../../common/GoogleInput";
import UseCrudUser from "./requestForm/UseCrudUser";
import ErrorMessage from "../../common/ErrorMessage";
import {
  useGetPermissionsQuery,
  useUserFromLocation,
} from "../../../utils/api/user/user.api";

const AddUserAdmin = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const [item] = useUserFromLocation();
  const {
    register,
    onSubmit,
    onChangeAddress,
    address,
    isLoading,
    errors,
    phone,
    handleChangePhone,
  } = UseCrudUser(item);
  const [title, setTitle] = React.useState("");
  const [forAdmin, setForAdmin] = React.useState(false);
  const { data: permission = { success: true, message: "", data: [] } } =
    useGetPermissionsQuery();

  React.useEffect(() => {
    if (location?.pathname?.endsWith("/ajouter-utilisateur")) {
      setTitle("Nouveau utilisateur");
      setForAdmin(false);
    } else if (location?.pathname?.endsWith("/ajouter-admin")) {
      setTitle("Nouveau sous admin");
      setForAdmin(true);
    } else if (
      item?.user_id &&
      location?.pathname?.endsWith(`/modifier-utilisateur/${item?.user_id}`)
    ) {
      setTitle("Modification d'un utilisateur");
      setForAdmin(false);
    } else {
      setTitle("Modification d'un sous admin");
      setForAdmin(true);
    }
  }, [location?.pathname, item]);

  // React.useEffect(() => {
  //   console.log("permissions", permission?.data);
  // }, [permission?.data]);

  return (
    <div className="list-monitors-admin">
      <div className="container-title-page-admin">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">{title}</h3>
        </div>
      </div>
      <div className="container-form-add">
        <form onSubmit={onSubmit}>
          <div className="row row-add">
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <input
                  className="form-control form-control-add-form"
                  type="text"
                  {...register("first_name")}
                  placeholder="Prénom *"
                />
                <ErrorMessage message={errors?.first_name?.message} />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <input
                  className="form-control form-control-add-form"
                  type="text"
                  placeholder="Nom *"
                  {...register("last_name")}
                />
                <ErrorMessage message={errors?.last_name?.message} />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <input
                  className="form-control form-control-add-form"
                  type="email"
                  placeholder="Email *"
                  {...register("email")}
                />
                <ErrorMessage message={errors?.email?.message} />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <PhoneInput
                  containerClass="mb-3 p-0"
                  inputClass="form-control form-control-add-form"
                  country={"fr"}
                  data-testid="phoneId"
                  placeholder=""
                  inputProps={{
                    name: "telephone",
                    required: true,
                    autoFocus: true,
                  }}
                  countryCodeEditable={false}
                  enableAreaCodes={true}
                  prefix="+"
                  value={phone}
                  onChange={(phone, country) =>
                    handleChangePhone(phone, country)
                  }
                  inputStyle={{
                    width: "100%",
                    paddingBottom: "30px",
                    paddingTop: "30px",
                  }}
                />
                <ErrorMessage message={errors?.phone?.message} />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <GoogleInput
                  className="form-control form-control-add-form"
                  value={address}
                  onChange={(val) => onChangeAddress(val)}
                />
                <ErrorMessage message={errors?.address?.message} />
              </div>
            </div>
            {/* {!!forAdmin && (
              <div className="col-md-12 col-form-add-student mb-3">
                <label htmlFor="text" className="custom-form-label mb-1">
                  Permissions d'accès
                </label>
                <div className="row mt-2">
                  {permission?.data?.map((item, i) => (
                    <div className="col-md-6" key={i}>
                      <div className="d-flex justify-content-start align-items-center mb-2 gap-1">
                        <input
                          type="checkbox"
                          className="custom-control-input-check form-check-input"
                          // {...register(`permissions.${i}`)}
                          id={`checkbox${i}`}
                          onChange={(e) => onChangePermission(e)}
                          value={item?.id}
                          checked={permissions?.some(
                            (el) => parseInt(el) === parseInt(item?.id)
                          )}
                        />
                        <label
                          className="custom-form-label mb-0"
                          htmlFor={`checkbox${i}`}
                        >
                          {item?.label}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
                <ErrorMessage message={errors?.permissions?.message} />
              </div>
            )} */}
          </div>
          <div className="row row-content-btn-action py-5">
            <div className="col-md-4 offset-md-4">
              <button
                className="btn btn-cancelled w-100"
                onClick={() => navigate(-1)}
              >
                Annuler
              </button>
            </div>
            <div className="col-md-4">
              <button
                className="btn btn-page-title-admin w-100"
                disabled={isLoading}
                type="submit"
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>Enregistrer...</span>
                  </>
                ) : (
                  "Enregistrer"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUserAdmin;
