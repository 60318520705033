/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForgetPasswordMutation } from "../../../utils/api/auth/auth.api";
import { ForgetPasswordFormData } from "../../../utils/api/auth/auth.type";
import { Color } from "../../../utils/theme";
import { cleannerError } from "../../../utils/utils";

function UseRequestPassword() {
  const [forgetPassword, { error, isSuccess, isLoading, originalArgs, data }] =
    useForgetPasswordMutation();
  const navigate = useNavigate();
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email().required().label("L'email"),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm<ForgetPasswordFormData>({
    resolver: yupResolver(validationSchema),
  });
  useEffect(() => {
    if (error) {
      const err = error as any;
      const { message = "Une erreur a survenue lors de l'envoi" } =
        err.data || {};
      setError("email", { message: message });
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      console.log("data", data);
      if (data?.success === true) {
        Swal.fire({
          icon: "success",
          iconColor: Color.success,
          confirmButtonColor: Color.success,
          title: data?.message,
          timer: 5000,
        }).then(() => {
          navigate("/renitialiser-mot-de-passe", {
            replace: true,
            state: { email: originalArgs?.email },
          });
        });
      } else {
        setError("email", { message: data?.message });
      }
    }
  }, [isSuccess, navigate, originalArgs?.email]);

  useEffect(() => {
    if (errors) {
      cleannerError(errors, clearErrors, 5000);
    }
  }, [clearErrors, errors]);

  const onSubmit = async (data: ForgetPasswordFormData) => {
    await forgetPassword(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    clearErrors,
    isLoading,
  };
}

export default UseRequestPassword;
