import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import TableSkeleton from "../../../common/TableSkeleton";
import {
  MdDisabledByDefault,
  MdModeEditOutline,
  MdOutlinePassword,
} from "react-icons/md";
import { BsTrash } from "react-icons/bs";
import { useGetUserListQuery } from "../../../../utils/api/user/user.api";
import { IUser, Permission } from "../../../../utils/api/user/user.type";
import { getAvatar } from "../../../../utils/utils";
import { NavLink } from "react-router-dom";
import {
  UseDeleteUserItem,
  UseGeneratePassword,
  UseToggleUserItem,
} from "../requestForm/UseCrudUser";
import { ErrorQuery } from "../../../../utils/type";
import { AlertInfo } from "../../../common/Alert";
import { useAppSelector } from "../../../../redux/hook";
import { IoCheckbox } from "react-icons/io5";

function DeleteItemBtn({ id }: { id: string }) {
  const onDelete = UseDeleteUserItem(id);

  return (
    <button
      className="btn btn-action-modal-icon with-tooltip"
      data-tooltip-content="Supprimer"
      style={{ color: "#EB5757" }}
      onClick={() => onDelete()}
    >
      <BsTrash />
    </button>
  );
}

export function ToggleItemBtn({ item }: { item: IUser }) {
  const onToggle = UseToggleUserItem(item);

  return (
    <button
      className="btn btn-action-modal-icon with-tooltip"
      data-tooltip-content={item?.is_banned === 1 ? "Réactiver" : "Suspendre"}
      style={{ color: item?.is_banned === 1 ? "#257a97" : "#EB5757" }}
      onClick={() => onToggle()}
    >
      {item?.is_banned === 1 ? <IoCheckbox /> : <MdDisabledByDefault />}
    </button>
  );
}

export function GenerateBtn({ id }: { id: string }) {
  const onSend = UseGeneratePassword(id);

  return (
    <button
      className="btn btn-action-modal-icon with-tooltip"
      data-tooltip-content="Générer un mot de passe"
      onClick={() => onSend()}
    >
      <MdOutlinePassword />
    </button>
  );
}
function GestionUsersTable() {
  const { userInfos } = useAppSelector((s) => s?.userInfos);
  const {
    data = { data: [], count: 0 },
    isLoading,
    error,
  } = useGetUserListQuery({
    type: "fabricant",
  });
  let err = error as ErrorQuery;
  const nameFormatter = (cell: string, row: IUser) => {
    return (
      <div className="container-display-info-user-table">
        <div className="content-pp-profil-table-user">
          <img
            src={getAvatar(row?.avatar)}
            alt="Profil"
            className="pp-profil-table-user"
          />
        </div>
        <div className="content-text-info-pp-table-user">
          <p className="text-info-pp-table-user mb-0">
            {cell + " " + row?.last_name}
          </p>
          <p className="text-id-pp-table-user mb-0">ID {row?.user_id}</p>
        </div>
      </div>
    );
  };
  const actionFormatter = (cell: string, row: IUser) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-2 align-items-center">
          <div className="container-btn-action-icon-modal">
            <NavLink
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Modifier"
              style={{ color: "#113846" }}
              to={`/admin/modifier-utilisateur/${cell}`}
              state={row}
            >
              <MdModeEditOutline />
            </NavLink>
          </div>

          <div className="container-btn-action-icon-modal">
            <DeleteItemBtn id={cell} />
          </div>

          <div className="container-btn-action-icon-modal">
            <GenerateBtn id={cell} />
          </div>
          <div className="container-btn-action-icon-modal">
            <ToggleItemBtn item={row} />
          </div>
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "first_name",
      text: "Photo + noms",
      formatter: (cell: string, row: IUser) => nameFormatter(cell, row),
    },
    {
      dataField: "phone",
      text: "Téléphone",
      formatter: (cell: string) => cell || "non défini",
    },
    {
      dataField: "address",
      text: "Adresse",
      formatter: (cell: string) => cell || "non défini",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "user_id",
      text: "Actions",
      formatter: (cell: string, row: IUser) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="user_id"
            data={data?.data?.filter((item) => item?.status === "fabricant")}
            columns={columns}
            pagination={paginationFactory()}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo
                message={err?.data?.message || "Aucune donnée trouvée"}
              />
            )}
          />
        </>
      )}
    </>
  );
}

export default GestionUsersTable;
