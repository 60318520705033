import React from "react";
import ErrorMessage from "../../../../common/ErrorMessage";
import { PropsTypeFabric } from "../AddNewFabrication";
import SuiviCureTable from "../SuiviCureTable/SuiviCureTable";

const CureTabs = ({
  navigation,
  register,
  errors,
  isLoading,
}: PropsTypeFabric) => {
  return (
    <div className="quantite-tabs-component">
      <div className="container-form-add">
        <h3 className="title-form-add">Temps de cure</h3>
        <div>
          <div className="row row-add">
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="temps_cure_prevu"
                  className="form-label form-label-add-recette"
                >
                  Prévu <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="number"
                  step={"any"}
                  min={0}
                  placeholder="Nombre de semaines prévu"
                  {...register("temps_cure_prevu")}
                />
                <ErrorMessage
                  message={
                    errors?.temps_cure_prevu &&
                    errors?.temps_cure_prevu?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="temps_cure_restant"
                  className="form-label form-label-add-recette"
                >
                  Restant <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="number"
                  step={"any"}
                  min={0}
                  placeholder="Nombre de semaine restant"
                  {...register("temps_cure_restant")}
                />
                <ErrorMessage
                  message={
                    errors?.temps_cure_restant &&
                    errors?.temps_cure_restant?.message
                  }
                />
              </div>
            </div>
            <h3 className="title-form-add">Mise en cure</h3>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="mise_encure_date"
                  className="form-label form-label-add-recette"
                >
                  Date
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="date"
                  placeholder="Date"
                  {...register("mise_encure_date")}
                />
                <ErrorMessage
                  message={
                    errors?.mise_encure_date &&
                    errors?.mise_encure_date?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="poids_lot"
                  className="form-label form-label-add-recette"
                >
                  Poids du lot en kg
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="number"
                  step={"any"}
                  min={0}
                  placeholder="Poids du lot en kg"
                  {...register("mise_encure_poids_lot")}
                />
                <ErrorMessage
                  message={
                    errors?.mise_encure_poids_lot &&
                    errors?.mise_encure_poids_lot?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-3 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label htmlFor="" className="form-label form-label-add-recette">
                  Contrôle olfactif
                </label>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    // value=""
                    id="flexCheckDefault"
                    {...register("mise_encure_controle_olfactif")}
                  />
                  <label
                    className="form-check-label form-check-label-costum"
                    htmlFor="flexCheckDefault"
                  >
                    OK
                  </label>
                </div>
                <ErrorMessage
                  message={
                    errors?.mise_encure_controle_olfactif &&
                    errors?.mise_encure_controle_olfactif?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-3 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label htmlFor="" className="form-label form-label-add-recette">
                  Contrôle visuel
                </label>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    // value=""
                    id="flexCheckDefault2"
                    {...register("mise_encure_controle_visuel")}
                  />
                  <label
                    className="form-check-label form-check-label-costum"
                    htmlFor="flexCheckDefault2"
                  >
                    OK
                  </label>
                </div>
                <ErrorMessage
                  message={
                    errors?.mise_encure_controle_visuel &&
                    errors?.mise_encure_controle_visuel?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-12 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="mise_encure_observations"
                  className="form-label form-label-add-recette"
                >
                  Observations
                </label>
                <textarea
                  className="form-control form-control-add-form"
                  rows={7}
                  {...register("mise_encure_observations")}
                  placeholder="Observations"
                />
                <ErrorMessage
                  message={
                    errors?.mise_encure_observations &&
                    errors?.mise_encure_observations?.message
                  }
                />
              </div>
            </div>
          </div>
          <h3 className="title-form-add">Cure (Suivi échantillon témoin)</h3>
          <div className="container-table-suivi-cure mb-4">
            {/* <SuiviCureTable register={register} errors={errors} /> */}
            {Array(4)
              .fill(4)
              ?.map((el, i) => (
                <div className="container-table-add-recette">
                  <div className="line-add-recette-item w-15 mb-1 mb-1">
                    {i === 0 ? (
                      <label
                        htmlFor=""
                        className="form-label form-label-add-recette"
                      >
                        Date
                      </label>
                    ) : null}
                    <input
                      type="date"
                      className="form-control form-control-add-recette"
                      placeholder="-"
                      {...register(`suivi_echantillon_temoins.${i}.set_date`)}
                    />
                    <ErrorMessage
                      message={
                        errors?.suivi_echantillon_temoins &&
                        errors?.suivi_echantillon_temoins[i]?.set_date &&
                        errors?.suivi_echantillon_temoins[i]?.set_date?.message
                      }
                    />
                  </div>
                  <div className="line-add-recette-item w-15 mb-1">
                    {i === 0 ? (
                      <label
                        htmlFor=""
                        className="form-label form-label-add-recette"
                      >
                        Poids
                      </label>
                    ) : null}
                    <input
                      type="number"
                      min={0}
                      step="any"
                      className="form-control form-control-add-recette"
                      placeholder="Poids"
                      {...register(`suivi_echantillon_temoins.${i}.set_poids`)}
                    />
                    <ErrorMessage
                      message={
                        errors?.suivi_echantillon_temoins &&
                        errors?.suivi_echantillon_temoins[i]?.set_poids &&
                        errors?.suivi_echantillon_temoins[i]?.set_poids?.message
                      }
                    />
                  </div>
                  <div className="line-add-recette-item w-15 mb-1">
                    {i === 0 ? (
                      <label
                        htmlFor=""
                        className="form-label form-label-add-recette"
                      >
                        Visuel
                      </label>
                    ) : null}
                    <select
                      className="form-control form-control-add-recette"
                      {...register(`suivi_echantillon_temoins.${i}.set_visuel`)}
                    >
                      <option value=""> Visuel </option>
                      <option value={`${true}`}>Ok</option>
                      <option value={`${false}`}>Non OK</option>
                    </select>
                    <ErrorMessage
                      message={
                        errors?.suivi_echantillon_temoins &&
                        errors?.suivi_echantillon_temoins[i]?.set_visuel &&
                        errors?.suivi_echantillon_temoins[i]?.set_visuel
                          ?.message
                      }
                    />
                  </div>
                  <div className="line-add-recette-item w-15 mb-1">
                    {i === 0 ? (
                      <label
                        htmlFor=""
                        className="form-label form-label-add-recette"
                      >
                        Olfactif
                      </label>
                    ) : null}
                    <select
                      className="form-control form-control-add-recette"
                      {...register(
                        `suivi_echantillon_temoins.${i}.set_olfactif`
                      )}
                    >
                      <option value=""> Olfactif </option>
                      <option value={`${true}`}>Ok</option>
                      <option value={`${false}`}>Non OK</option>
                    </select>
                    <ErrorMessage
                      message={
                        errors?.suivi_echantillon_temoins &&
                        errors?.suivi_echantillon_temoins[i]?.set_olfactif &&
                        errors?.suivi_echantillon_temoins[i]?.set_olfactif
                          ?.message
                      }
                    />
                  </div>
                  <div className="line-add-recette-item w-35 mb-1">
                    {i === 0 ? (
                      <label
                        htmlFor=""
                        className="form-label form-label-add-recette"
                      >
                        Observations
                      </label>
                    ) : null}
                    <textarea
                      {...register(
                        `suivi_echantillon_temoins.${i}.set_observations`
                      )}
                      placeholder="Observations"
                      className="form-control form-control-add-recette"
                      rows={1}
                    ></textarea>

                    <ErrorMessage
                      message={
                        errors?.suivi_echantillon_temoins &&
                        errors?.suivi_echantillon_temoins[i]
                          ?.set_observations &&
                        errors?.suivi_echantillon_temoins[i]?.set_observations
                          ?.message
                      }
                    />
                  </div>
                </div>
              ))}
          </div>
          <h3 className="title-form-add">Sortie de cure</h3>
          <div className="row row-add">
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label htmlFor="" className="form-label form-label-add-recette">
                  Date
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="date"
                  placeholder="Date"
                  {...register("sortie_cure_date")}
                />
                <ErrorMessage
                  message={
                    errors?.sortie_cure_date &&
                    errors?.sortie_cure_date?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-3 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label htmlFor="" className="form-label form-label-add-recette">
                  Contrôle olfactif
                </label>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    // value=""
                    id="flexCheckDefault3"
                    {...register("sortie_cure_controle_olfactif")}
                  />
                  <label
                    className="form-check-label form-check-label-costum"
                    htmlFor="flexCheckDefault3"
                  >
                    OK
                  </label>
                </div>
              </div>
              <ErrorMessage
                message={
                  errors?.sortie_cure_controle_olfactif &&
                  errors?.sortie_cure_controle_olfactif?.message
                }
              />
            </div>
            <div className="col-md-3 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label htmlFor="" className="form-label form-label-add-recette">
                  Contrôle visuel
                </label>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    // value=""
                    id="flexCheckDefault4"
                    {...register("sortie_cure_controle_visuel")}
                  />
                  <label
                    className="form-check-label form-check-label-costum"
                    htmlFor="flexCheckDefault4"
                  >
                    OK
                  </label>
                </div>
              </div>
              <ErrorMessage
                message={
                  errors?.sortie_cure_controle_visuel &&
                  errors?.sortie_cure_controle_visuel?.message
                }
              />
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label htmlFor="" className="form-label form-label-add-recette">
                  Poids échantillon témoin
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="number"
                  step={"any"}
                  min={0}
                  placeholder="Poids échantillon témoin"
                  {...register("sortie_cure_poids_echantillon_temoin")}
                />
                <ErrorMessage
                  message={
                    errors?.sortie_cure_poids_echantillon_temoin &&
                    errors?.sortie_cure_poids_echantillon_temoin?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label htmlFor="" className="form-label form-label-add-recette">
                  PH
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="number"
                  step={"any"}
                  min={0}
                  placeholder="PH"
                  {...register("sortie_cure_ph")}
                />
                <ErrorMessage
                  message={
                    errors?.sortie_cure_ph && errors?.sortie_cure_ph?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label htmlFor="" className="form-label form-label-add-recette">
                  Nombre de produits crées
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="number"
                  min={0}
                  placeholder="Nombre de produits crées"
                  {...register("sortie_cure_nombre_produits_crees")}
                />
                <ErrorMessage
                  message={
                    errors?.sortie_cure_nombre_produits_crees &&
                    errors?.sortie_cure_nombre_produits_crees?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label htmlFor="" className="form-label form-label-add-recette">
                  Nombre de produits conformes
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="number"
                  min={0}
                  placeholder="Nombre de produits conformes"
                  {...register("sortie_cure_nombre_produits_conformes")}
                />
                <ErrorMessage
                  message={
                    errors?.sortie_cure_nombre_produits_conformes &&
                    errors?.sortie_cure_nombre_produits_conformes?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label htmlFor="" className="form-label form-label-add-recette">
                  Nombre de produits non conformes
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="number"
                  min={0}
                  placeholder="Nombre de produits non conformes"
                  {...register("sortie_cure_nombre_produits_nonconformes")}
                />
                <ErrorMessage
                  message={
                    errors?.sortie_cure_nombre_produits_nonconformes &&
                    errors?.sortie_cure_nombre_produits_nonconformes?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-12 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label htmlFor="" className="form-label form-label-add-recette">
                  Observations
                </label>
                <textarea
                  className="form-control form-control-add-form"
                  rows={7}
                  placeholder="Observations"
                  {...register("sortie_cure_observations")}
                />
                <ErrorMessage
                  message={
                    errors?.sortie_cure_observations &&
                    errors?.sortie_cure_observations?.message
                  }
                />
              </div>
            </div>
          </div>

          <div className="flex-r gap-4 align-items-center py-5">
            <button
              className="btn btn-cancelled-recette"
              onClick={() => navigation.previous()}
            >
              Retour
            </button>
            <button
              className="btn auth-submit-btn-recette trans-0-2"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                    role="status"
                  ></span>
                  <span>Enregistrer...</span>
                </>
              ) : (
                "Enregistrer"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CureTabs;
