import React, { useState } from "react";
import StockMPTable from "./StockMPTable/StockMPTable";
import "./StockMP.css";
import { NavLink } from "react-router-dom";
import { MdOutlineAdd } from "react-icons/md";
import FilterIcon from "../../../assets/icons/filter icon.svg";
import { CiSearch } from "react-icons/ci";


const StockMP = () => {
  const [term, setTerm] = useState("")
  return (
    <div className="fournisseur-list-component">
      <div className="container-title-page-admin mb-5">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">Stock</h3>
        </div>
        <div className="content-btn-action-title-page-admin">
          <NavLink
            to="/fabriquant/receptionner-commande"
            className="btn btn-page-title-admin"
          >
            <MdOutlineAdd />
            <span className="ps-2">Réceptionner une commande</span>
          </NavLink>
        </div>
      </div>
      <div className="container-fournisseur-list">
        <div className="container-filter-table pb-4">
          <div className="row">
            <div className="col-md-3 mb-3">
              <div className="form-filter-table position-relative">
                <input
                  type="text"
                  className="form-control form-control-filter-table"
                  placeholder="Rechercher"
                  onChange={(e) => setTerm(e?.target?.value)}
                  value={term}
                />
                <span className="content-icon-filter-table">
                  <CiSearch
                    className="filter-icon-table"
                    style={{ color: "#7C97B6" }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <StockMPTable term={term} />
      </div>
    </div>
  );
};

export default StockMP;
