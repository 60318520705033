import React from "react";
// import Profil from "../../../assets/appImages/profil.png";
// import { BsCameraFill } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import UserEditUserForm from "./requestUser/UserEditUserForm";
import ErrorMessage from "../../common/ErrorMessage";
import GoogleInput from "../../common/GoogleInput";

const EditProfil = () => {
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    phone,
    handleChangePhone,
    onChangeAddress,
    address
  } = UserEditUserForm();
  return (
    <div className="fournisseur-list-component">
      <div className="container-title-page-admin mb-5">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">Mon profil</h3>
        </div>
      </div>
      <div className="container-profil-page">
        <div className="card-profil-fabriquant mb-3">
          <div className="container-info-user-profil">
            {/* <div className="content-pp-profil-fabriquant text-center pb-3">
              <div className="d-flex justify-content-center align-items-center pb-4 mb-4 leader-auto-user-avatar-container">
                <label htmlFor="input-test" className="input-testt">
                  <img
                    src={Profil}
                    alt="profil utilisateur"
                    className="pp-profil-user-fabriquant"
                  />
                  <div className="leader-auto-user-avatar-edit-hover">
                    <BsCameraFill className="edit-avatar-camera-icon" />
                  </div>
                </label>
                <input
                  type="file"
                  className="input-add-avatar"
                  id="input-test"
                />
              </div>
            </div> */}
            <form
              className="formulaire-midifier-profile-container"
              onSubmit={onSubmit}
            >
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="row-addcontent-col-form-add">
                    <input
                      className="form-control form-control-add-form"
                      type="text"
                      {...register("first_name")}
                      placeholder="Prénom"
                    />
                    <ErrorMessage message={errors?.first_name?.message} />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="row-addcontent-col-form-add">
                    <input
                      className="form-control form-control-add-form"
                      type="text"
                      {...register("last_name")}
                      placeholder="Nom"
                    />
                    <ErrorMessage message={errors?.last_name?.message} />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="row-addcontent-col-form-add">
                    <input
                      className="form-control form-control-add-form"
                      type="email"
                      {...register("email")}
                      placeholder="Email"
                    />
                    <ErrorMessage message={errors?.email?.message} />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="row-addcontent-col-form-add">
                    <GoogleInput
                      className="form-control form-control-add-form"
                      value={address}
                      onChange={onChangeAddress}
                    />
                    <ErrorMessage message={errors?.address?.message} />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="row-addcontent-col-form-add">
                    <PhoneInput
                      // defaultMask={".. ... .. .."}
                      containerClass="mb-3 p-0"
                      inputClass="form-control form-control-add-form"
                      country={"fr"}
                      data-testid="phoneId"
                      placeholder=""
                      inputProps={{
                        name: "telephone",
                        required: true,
                        autoFocus: true,
                      }}
                      countryCodeEditable={false}
                      enableAreaCodes={true}
                      prefix="+"
                      value={phone}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      inputStyle={{
                        width: "100%",
                        paddingBottom: "30px",
                        paddingTop: "30px",
                      }}
                    />
                    <ErrorMessage message={errors?.phone?.message} />
                  </div>
                </div>
                {/* <div className="col-md-12 mb-3">
                  <div className="row-addcontent-col-form-add">
                    <textarea
                      className="form-control form-control-add-form"
                      rows={5}
                    ></textarea>
                  </div>
                </div> */}
              </div>

              <div className="row">
                <div className="col-md-2 offset-md-10 mt-3">
                  <button
                    className="btn btn-page-title-admin w-100"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>Enregistrer...</span>
                      </>
                    ) : (
                      "Enregistrer"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfil;
