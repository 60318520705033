/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { ChangeEvent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppSelector } from "../../../../../redux/hook";
import {
  useGetCoutMoyenMutation,
  useGetMPByFournisseurQuery,
} from "../../../../../utils/api/commande/commande.api";
import { CommandeLine } from "../../../../../utils/api/commande/commande.type";
import {
  useAddOrUpdateRecetteMutation,
  useDeleteRecetteMutation,
} from "../../../../../utils/api/recette/recette.api";
import {
  Recette,
  RecetteFormData,
} from "../../../../../utils/api/recette/recette.type";
import { Color } from "../../../../../utils/theme";
import { cleannerError, useLocationState } from "../../../../../utils/utils";

function UseCrudRecetteForm(recette?: Recette) {
  const validationSchema = yup.object().shape({
    step: yup.number(),
    cosmetique: yup.boolean(),
    recette_matiere_premieres: yup.array().when("step", {
      is: 0,
      then: yup.array().of(
        yup.object().shape({
          categorie: yup.string().required().label("La catégorie").nullable(),
          nom: yup.string().required().label("Le nom").nullable(),
          pourcentage: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required()
            .label("Le pourcentage")
            .nullable(),
          poids: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required()
            .label("Le poids")
            .nullable(),
          unite_poids: yup
            .string()
            .required()
            .label("L'unité du poids")
            .nullable(),
          cout: yup.string().required().label("Le coût").nullable(),
          cout_total: yup.string().required().label("Le coût total").nullable(),
        })
      ),
    }),
    nom_recette: yup.string().when("step", {
      is: 1,
      then: yup.string().required().label("Le nom"),
    }),
    type_recette: yup.string().when("step", {
      is: 1,
      then: yup.string().required().label("Le type"),
    }),
    surgras: yup
      .number()
      .when("step", {
        is: 1,
        then: yup
          .number()
          .when("cosmetique", {
            is: false,
            then: yup
              .number()
              .transform((value) => (isNaN(value) ? undefined : value))
              .required()
              .label("Le surgras"),
          })
          .transform((value) => (isNaN(value) ? undefined : value)),
      })
      .transform((value) => (isNaN(value) ? undefined : value)),
    temps_cure: yup
      .number()
      .when("step", {
        is: 1,
        then: yup
          .number()
          .when("cosmetique", {
            is: false,
            then: yup
              .number()
              .required()
              .transform((value) => (isNaN(value) ? undefined : value))
              .label("Le temps de cure"),
          })
          .transform((value) => (isNaN(value) ? undefined : value)),
      })
      .transform((value) => (isNaN(value) ? undefined : value)),
    poids_total_initial: yup
      .number()
      .when("step", {
        is: 1,
        then: yup
          .number()
          .required()
          .transform((value) => (isNaN(value) ? undefined : value))
          .label("Le poids total initial"),
      })
      .transform((value) => (isNaN(value) ? undefined : value)),

    perte_prevue_durant_cure: yup
      .number()
      .when("step", {
        is: 1,
        then: yup
          .number()
          .when("cosmetique", {
            is: false,
            then: yup
              .number()
              .transform((value) => (isNaN(value) ? undefined : value))
              .required()
              .label("Perte prévue durant le cure"),
          })
          .transform((value) => (isNaN(value) ? undefined : value)),
        // then: yup.string().required().label("Perte prévue durant le cure"),
      })
      .transform((value) => (isNaN(value) ? undefined : value)),
    perte_prevue_durant_fabrication: yup
      .number()
      .when("step", {
        is: 1,
        then: yup
          .number()
          .required()
          .label("Perte prévue durant la fabrication")
          .transform((value) => (isNaN(value) ? undefined : value)),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value)),
    poids_prevu_fin_cure: yup
      .number()
      .when("step", {
        is: 1,
        then: yup
          .number()
          .when("cosmetique", {
            is: false,
            then: yup
              .number()
              .required()
              .label("Le poids prévu en fin de cure")
              .transform((value) => (isNaN(value) ? undefined : value)),
          })
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
      })
      .transform((value) => (isNaN(value) ? undefined : value)),
    cout_main_doeuvre: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .nullable()
          .label("Le coût main d'œuvre")
          .transform((value) => (isNaN(value) ? undefined : value)),
      })
      .transform((value) => (isNaN(value) ? undefined : value)),
    cout_emballage_unitaire: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .nullable()
          .label("Le coût emballage unitaire")
          .transform((value) => (isNaN(value) ? undefined : value)),
      })
      .transform((value) => (isNaN(value) ? undefined : value)),
    temps_fabrication: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .nullable()
          .label("Le temps de fabrication")
          .transform((value) => (isNaN(value) ? undefined : value)),
      })
      .transform((value) => (isNaN(value) ? undefined : value)),
    nbre_unite_fabrique: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .nullable()
          .label("Le nbre unité fabriqué")
          .transform((value) => (isNaN(value) ? undefined : value)),
      })
      .transform((value) => (isNaN(value) ? undefined : value)),

    nbre_emballage: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .nullable()
          .label("Le nbre d'emballage")
          .transform((value) => (isNaN(value) ? undefined : value)),
      })
      .transform((value) => (isNaN(value) ? undefined : value)),
    cout_fabrication_total: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .nullable()
          .label("Le coût de fabrication total")
          .transform((value) => (isNaN(value) ? undefined : value)),
      })
      .transform((value) => (isNaN(value) ? undefined : value)),
    type_cout_par_unite: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .nullable()
          .label("Le coût par unité")
          .transform((value) => (isNaN(value) ? undefined : value)),
      })
      .transform((value) => (isNaN(value) ? undefined : value)),
    cout_sans_emballage: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .nullable()
          .label("Le coût sans emballage")
          .transform((value) => (isNaN(value) ? undefined : value)),
      })
      .transform((value) => (isNaN(value) ? undefined : value)),
    cout_avec_emballage: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .nullable()
          .label("Le coût avec emballage")
          .transform((value) => (isNaN(value) ? undefined : value)),
      })
      .transform((value) => (isNaN(value) ? undefined : value)),
    cout_total_emballage: yup
      .number()
      .when("step", {
        is: 2,
        then: yup
          .number()
          .nullable()
          .label("Le total coût emballage")
          .transform((value) => (isNaN(value) ? undefined : value)),
      })
      .transform((value) => (isNaN(value) ? undefined : value)),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    clearErrors,
    setError,
  } = useForm<RecetteFormData>({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const [lines, setLines] = useState<any>([]);
  const [infos, setInfos] = useState({
    poids_total_initial: "",
    perte_prevue_durant_cure: "",
    perte_prevue_durant_fabrication: "",
  });
  const [infosFabic, setInfosFabic] = useState({
    cout_main_doeuvre: "",
    cout_emballage_unitaire: "",
    temps_fabrication: "",
    nbre_unite_fabrique: "",
    nbre_emballage: "",
    cout_fabrication_total: "",
    type_cout_par_unite: "",
    cout_sans_emballage: "",
    cout_avec_emballage: "",
    cout_total_emballage: "",
  });
  const [totals, setTotals] = useState({
    total_poids: "",
    total_pourcentage: "",
    total_cout: "",
    all_total: "",
  });
  const [options, setOptions] = useState<
    {
      nom: string;
      cout: string;
      cout_total: string | number;
      pourcentage: string;
      poids: string;
      categorie: string;
      unite_poids: string;
      unite_poids_mp: string;
    }[]
  >([
    {
      nom: "",
      cout: "",
      cout_total: "",
      pourcentage: "",
      poids: "",
      categorie: "",
      unite_poids: "",
      unite_poids_mp: "",
    },
  ]);
  const [
    getAmount,
    { data: amount, isSuccess: success, isError: isErr, error: erreur },
  ] = useGetCoutMoyenMutation();
  const [dataForm, setDataForm] = useState<any>();
  const [level, setLevel] = useState(0);
  const { userInfos } = useAppSelector((s) => s?.userInfos);
  const { data: stocks = { data: [] } } = useGetMPByFournisseurQuery({
    id: userInfos?.user_id,
  });
  const [type, setType] = useState("");
  // const { data: stocks = { data: { data: [], total: 0 } } } =
  //   useGetCommandeLinesByUserQuery({ id: userInfos?.user_id });
  const [cout_moyen_matiere_premiere, setCout_moyen_matiere_premiere] =
    useState<any>(0);
  const [sendData, { isLoading, isSuccess, error, isError, data }] =
    useAddOrUpdateRecetteMutation();
  const itemState = useLocationState<any>(undefined);

  useEffect(() => {
    if (itemState?.type) {
      setValue("type_recette", itemState?.type);
      setType(itemState?.type);
    }
  }, [itemState]);

  useEffect(() => {
    if (type === "cosmetique") {
      setValue("cosmetique", true);
    } else {
      setValue("cosmetique", false);
    }
  }, [type]);

  useEffect(() => {
    let val = 0;
    if (amount?.data?.length && options?.length) {
      // console.log("getAmount", amount);
      options.forEach((item, i) => {
        if (
          parseFloat(item?.poids) > 0 &&
          item?.unite_poids &&
          parseFloat(item?.cout) > 0
        ) {
          let mp: CommandeLine = amount?.data?.find(
            (el) => el?.ligne_commande_id === item?.nom
          );
          // console.log("mp", mp, "options", options);
          let cout_unit = mp?.count_moyen_ligne_commande;
          let poids_mp = parseFloat(mp?.poids);
          let prix_kg = cout_unit / poids_mp;
          let cout_total: any = 0;
          if (mp?.ligne_commande_id) {
            if (item?.unite_poids !== item?.unite_poids_mp) {
              if (item?.unite_poids_mp === "kg") {
                // cout_unit = parseFloat(mp?.prix_unite) / parseFloat(mp?.poids)
                cout_total = (parseFloat(item?.poids) / 1000) * prix_kg;
              } else {
                cout_total = parseFloat(item?.poids) * 1000 * prix_kg;
              }
            } else {
              cout_total = prix_kg * parseFloat(item?.poids);
            }
          }

          val = val + cout_total;
        } else {
          let total: any = 0;
          val += parseFloat(total);
        }
      });
      setCout_moyen_matiere_premiere(val);
    }

    if (isErr) {
      console.log("getAmount err", erreur);
    }
  }, [amount, erreur, isErr, success, options]);
  useEffect(() => {
    if (recette?.recette_id) {
      setValue("recette_id", recette?.recette_id);
    }
  }, [recette]);

  useEffect(() => {
    let totalVal: any = {
      total_poids: 0,
      total_pourcentage: 0,
      total_cout: 0,
      all_total: 0,
    };
    // console.log("options", options);
    options.forEach((item, i) => {
      // if (parseFloat(item?.pourcentage) > 0 && parseFloat(item?.cout) > 0) {
      //   let total: any =
      //     (parseFloat(item?.pourcentage) * parseFloat(item?.cout)) / 100;
      //   item.cout_total = total;
      //   setValue(`recette_matiere_premieres.${i}.cout_total`, total);
      //   totalVal.all_total += parseFloat(total);
      // } else {
      //   let total: any = 0;
      //   item.cout_total = total;
      //   setValue(`recette_matiere_premieres.${i}.cout_total`, total);
      //   totalVal.all_total += parseFloat(total);
      // }

      if (
        parseFloat(item?.poids) > 0 &&
        item?.unite_poids &&
        parseFloat(item?.cout) > 0
      ) {
        let mp: CommandeLine = lines[i];
        let cout_unit = parseFloat(mp?.prix_unite);
        let poids_mp = parseFloat(mp?.poids);
        let prix_kg = cout_unit / poids_mp;
        let cout_total: any = 0;
        if (item?.unite_poids !== item?.unite_poids_mp) {
          if (item?.unite_poids_mp === "kg") {
            // cout_unit = parseFloat(mp?.prix_unite) / parseFloat(mp?.poids)
            cout_total = (parseFloat(item?.poids) / 1000) * prix_kg;
            // console.log("cout_total", cout_total, "prix_kg", prix_kg,poids_mp);
          } else {
            cout_total = parseFloat(item?.poids) * 1000 * prix_kg;
          }
        } else {
          cout_total = prix_kg * parseFloat(item?.poids);
        }
        // let total: any =
        //   (parseFloat(item?.pourcentage) * parseFloat(item?.cout)) / 100;
        item.cout_total = cout_total?.toFixed(2);
        setValue(
          `recette_matiere_premieres.${i}.cout_total`,
          cout_total?.toFixed(2)
        );
        totalVal.all_total = totalVal.all_total + cout_total;
        // console.log(
        //   "359 line",
        //   totalVal.all_total,
        //   parseFloat(cout_total)?.toFixed(2)
        // );
        // totalVal.all_total = cout_total;
      } else {
        let total: any = 0;
        item.cout_total = total;
        setValue(`recette_matiere_premieres.${i}.cout_total`, total);
        totalVal.all_total += parseFloat(total);
      }

      if (parseFloat(item?.pourcentage) >= 0) {
        totalVal.total_pourcentage += parseFloat(item?.pourcentage);
      }
      if (parseFloat(item?.cout) >= 0) {
        totalVal.total_cout += parseFloat(item?.cout);
      }

      if (parseFloat(item?.poids) >= 0) {
        totalVal.total_poids += parseFloat(item?.poids);
      }
    });
    // console.log("totalval", totalVal);
    // setValue("total_mp", totalVal);
    setTotals(totalVal);
  }, [options]);

  useEffect(() => {
    // console.log("infos",infos)
    if (
      parseFloat(infos?.poids_total_initial) >= 0 ||
      parseFloat(infos?.perte_prevue_durant_cure) >= 0 ||
      parseFloat(infos?.perte_prevue_durant_fabrication) >= 0
    ) {
      let perteFabric =
        (parseFloat(infos?.poids_total_initial) *
          parseFloat(infos?.perte_prevue_durant_fabrication)) /
        100;

      let poids_fabric = parseFloat(infos?.poids_total_initial) - perteFabric;
      let perteCure =
        (poids_fabric * parseFloat(infos?.perte_prevue_durant_cure)) / 100;
      let poids_prevu_fin_cure: any = poids_fabric - perteCure;
      setValue("poids_prevu_fin_cure", poids_prevu_fin_cure?.toFixed(2));
    }
  }, [infos]);

  useEffect(() => {
    if (isSuccess) {
      if (data?.success) {
        Swal.fire({
          icon: "success",
          iconColor: Color.success,
          confirmButtonColor: Color.success,
          title: data?.message,
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          if (userInfos?.status === "fabricant") {
            navigate(`/fabriquant/recettes`);
          } else {
            navigate("/admin/recettes");
          }
        });
      } else {
        // setError("password", { message: data?.message });
        Swal.fire({
          icon: "error",
          title: data?.message,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    // Object.entries(errors).map(([key]: any) =>
    //   console.log("error", errors[key])
    // );

    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSelectType = (e: ChangeEvent<HTMLSelectElement>) => {
    setValue("type_recette", e?.target?.value);
    setType(e?.target?.value);
  };

  const HandleSelectName = (e, i) => {
    let optionsCopy = [...options];
    let linesCopy = [...lines];
    if (e?.target?.name === "nom") {
      let item: CommandeLine | undefined = stocks?.data?.find(
        (el) => el?.ligne_commande_id === e?.target?.value
      );
      console.log("item", item);
      setValue(
        `recette_matiere_premieres.${i}.ligne_commande_id`,
        e?.target?.value
      );

      if (item) {
        linesCopy[i] = item;
        optionsCopy[i] = { ...optionsCopy[i], cout: item?.prix_unite };
        optionsCopy[i] = {
          ...optionsCopy[i],
          unite_poids_mp: item?.unite_poids,
        };
        optionsCopy[i] = { ...optionsCopy[i], nom: item?.ligne_commande_id };
        setValue(`recette_matiere_premieres.${i}.nom`, item?.nom);
        setValue(`recette_matiere_premieres.${i}.cout`, item?.prix_unite);
      }
    } else {
      optionsCopy[i] = { ...optionsCopy[i], categorie: e?.target?.value };
      setValue(`recette_matiere_premieres.${i}.categorie`, e?.target?.value);
    }

    setLines(linesCopy);

    setOptions(optionsCopy);
  };

  const handleChange = (e, i) => {
    let optionsCopy = [...options];
    if (e.target.name === "pourcentage") {
      setValue(
        `recette_matiere_premieres.${i}.pourcentage`,
        parseFloat(e.target.value)
      );

      optionsCopy[i] = { ...optionsCopy[i], pourcentage: e.target.value };
    } else if (e.target.name === "poids") {
      setValue(
        `recette_matiere_premieres.${i}.poids`,
        parseFloat(e.target.value)
      );

      optionsCopy[i] = { ...optionsCopy[i], poids: e.target.value };
    } else {
      setValue(`recette_matiere_premieres.${i}.unite_poids`, e.target.value);

      optionsCopy[i] = { ...optionsCopy[i], unite_poids: e.target.value };
    }

    setOptions(optionsCopy);
  };

  const onChangeInfo = (e) => {
    let infosCopy = { ...infos };

    if (e.target.name === "poids_total_initial") {
      setValue("poids_total_initial", e.target.value);
      infosCopy["poids_total_initial"] = e.target.value;
    }

    if (e.target.name === "perte_prevue_durant_cure") {
      setValue("perte_prevue_durant_cure", e.target.value);
      infosCopy["perte_prevue_durant_cure"] = e.target.value;
    }

    if (e.target.name === "perte_prevue_durant_fabrication") {
      setValue("perte_prevue_durant_fabrication", e.target.value);
      infosCopy["perte_prevue_durant_fabrication"] = e.target.value;
    }
    // console.log("infos", infosCopy);
    setInfos(infosCopy);
  };

  const onChangeFabrication = (e) => {
    let fields = { ...infosFabic };

    if (e.target.name === "cout_main_doeuvre") {
      setValue("cout_main_doeuvre", e.target.value);
      fields["cout_main_doeuvre"] = e.target.value;
    }

    if (e.target.name === "cout_emballage_unitaire") {
      setValue("cout_emballage_unitaire", e.target.value);
      fields["cout_emballage_unitaire"] = e.target.value;
    }

    if (e.target.name === "temps_fabrication") {
      setValue("temps_fabrication", e.target.value);
      fields["temps_fabrication"] = e.target.value;
    }

    if (e.target.name === "nbre_unite_fabrique") {
      setValue("nbre_unite_fabrique", e.target.value);
      fields["nbre_unite_fabrique"] = e.target.value;
    }

    if (e.target.name === "nbre_emballage") {
      setValue("nbre_emballage", e.target.value);
      fields["nbre_emballage"] = e.target.value;
    }

    if (fields?.cout_main_doeuvre && fields?.temps_fabrication) {
      let total: any =
        parseFloat(fields?.cout_main_doeuvre) *
        parseFloat(fields?.temps_fabrication);
      setValue("cout_fabrication_total", total);
      fields["cout_fabrication_total"] = total;
    } else {
      setValue("cout_fabrication_total", "");
      fields["cout_fabrication_total"] = "";
    }

    if (fields?.nbre_emballage && fields?.cout_emballage_unitaire) {
      let total: any =
        parseInt(fields?.nbre_emballage) *
        parseFloat(fields?.cout_emballage_unitaire);
      setValue("cout_total_emballage", total);
      fields["cout_total_emballage"] = total;
    } else {
      setValue("cout_total_emballage", "");
      fields["cout_total_emballage"] = "";
    }

    if (e.target.name === "type_cout_par_unite") {
      setValue("type_cout_par_unite", e.target.value);
      fields["type_cout_par_unite"] = e.target.value;
    }

    if (
      fields?.type_cout_par_unite &&
      fields?.cout_main_doeuvre &&
      fields?.nbre_unite_fabrique
    ) {
      let total: any;
      let totalV: any;
      if (fields?.type_cout_par_unite === "cout_derniere_comme") {
        if (fields?.cout_emballage_unitaire && fields?.nbre_unite_fabrique) {
          // console.log("all_total", totals?.all_total);
          let totalCout: any =
            totals?.all_total +
            parseFloat(fields?.cout_main_doeuvre) +
            parseFloat(fields?.cout_emballage_unitaire);
          total =
            parseFloat(totalCout) / parseFloat(fields?.nbre_unite_fabrique);
          totalV =
            (parseFloat(totals?.all_total) +
              parseFloat(fields?.cout_main_doeuvre)) /
            parseFloat(fields?.nbre_unite_fabrique);
        } else {
          total = "";
          totalV = "";
        }
        setValue(
          "cout_avec_emballage",
          total ? parseFloat(total).toFixed(2) : total
        );
        fields["cout_avec_emballage"] = total
          ? parseFloat(total).toFixed(2)
          : total;
        setValue(
          "cout_sans_emballage",
          totalV ? parseFloat(totalV).toFixed(2) : totalV
        );
        fields["cout_sans_emballage"] = totalV
          ? parseFloat(totalV).toFixed(2)
          : totalV;
      } else {
        console.log("cout moyenne", cout_moyen_matiere_premiere);
        total =
          (cout_moyen_matiere_premiere +
            parseFloat(fields?.cout_main_doeuvre) +
            parseFloat(fields?.cout_emballage_unitaire)) /
          parseFloat(fields?.nbre_unite_fabrique);
        totalV =
          (cout_moyen_matiere_premiere +
            parseFloat(fields?.cout_main_doeuvre)) /
          parseFloat(fields?.nbre_unite_fabrique);
        setValue("cout_avec_emballage", parseFloat(total).toFixed(2));
        fields["cout_avec_emballage"] = parseFloat(total).toFixed(2);
        setValue("cout_sans_emballage", parseFloat(totalV).toFixed(2));
        fields["cout_sans_emballage"] = parseFloat(totalV).toFixed(2);
      }
      // console.log(
      //   "total",
      //   fields?.type_cout_par_unite,
      //   total,
      //   totalV,
      //   cout_moyen_matiere_premiere + fields?.cout_fabrication_total,
      //   totals?.total_cout + fields?.cout_fabrication_total
      // );
    } else {
      setValue("cout_avec_emballage", "");
      fields["cout_avec_emballage"] = "";
      setValue("cout_sans_emballage", "");
      fields["cout_sans_emballage"] = "";
    }

    // if (e.target.name === "cout_total_emballage") {
    //   setValue("cout_total_emballage", e.target.value;
    //   fields["cout_total_emballage"] = e.target.value;
    // }
    console.log("fields", fields);
    setInfosFabic(fields);
  };

  const onSubmit = async (data: RecetteFormData) => {
    if (!recette) {
      data["fabriquant_id"] = userInfos?.user_id;
    }
    setDataForm(data);
    console.log("data", data);
    if (data?.step < 2) {
      if (data?.step === 0) {
        console.log("lines", lines);
        getAmount({
          fabricant_id: userInfos?.user_id,
          data: { recette_matiere_premieres: lines },
        });
        if (parseInt(totals?.total_pourcentage) === 100) {
          setLevel(1);
        } else {
          setError("total_pourcentage", {
            message: "Le total des pourcentages doit être égal à 100%",
          });
        }
      } else {
        setLevel(2);
      }
    } else {
      data["cout_total_mp"] = totals?.total_cout;
      data["poids_total"] = totals?.total_poids;
      data["cout_total"] = totals?.all_total;
      let dataSend: any = data;
      for (let key of Object.keys(dataSend)) {
        if (
          key === "cout_avec_emballage" ||
          key === "cout_sans_emballage" ||
          key === "cout_emballage_unitaire" ||
          key === "cout_fabrication_total" ||
          key === "cout_main_doeuvre" ||
          key === "cout_total_emballage" ||
          key === "nbre_emballage" ||
          key === "nbre_unite_fabrique" ||
          key === "perte_prevue_durant_cure" ||
          key === "perte_prevue_durant_fabrication" ||
          key === "poids_prevu_fin_cure" ||
          key === "poids_total_initial" ||
          key === "surgras" ||
          key === "temps_cure" ||
          key === "temps_fabrication"
        ) {
          if (dataSend[key]) dataSend[key] = parseFloat(dataSend[key]);
        }
      }

      console.log("data", dataSend);

      await sendData({ recette_id: dataSend?.recette_id, data: dataSend });
    }
  };

  useEffect(() => {
    if (recette?.recette_id) {
      const fields: (keyof RecetteFormData)[] = [
        "cout_avec_emballage",
        "cout_emballage_unitaire",
        "cout_fabrication_total",
        "cout_sans_emballage",
        "cout_main_doeuvre",
        "cout_total_emballage",
        "cout_total_mp",
        "nbre_emballage",
        "nbre_unite_fabrique",
        "nom_recette",
        "perte_prevue_durant_cure",
        "perte_prevue_durant_fabrication",
        "poids_prevu_fin_cure",
        "poids_total_initial",
        "recette_id",
        "recette_matiere_premieres",
        "surgras",
        "temps_cure",
        "temps_fabrication",
        "type_cout_par_unite",
        "type_recette",
      ];
      for (let field of fields) {
        if (field !== "recette_matiere_premieres") {
          register(field);
          setValue(field, recette[field]);
        }
      }
      setType(recette?.type_recette);
      setInfos({
        poids_total_initial: recette?.poids_total_initial,
        perte_prevue_durant_cure: recette?.perte_prevue_durant_cure,
        perte_prevue_durant_fabrication:
          recette?.perte_prevue_durant_fabrication,
      });
      setInfosFabic({
        cout_main_doeuvre: recette?.cout_main_doeuvre,
        cout_emballage_unitaire: recette?.cout_emballage_unitaire,
        temps_fabrication: recette?.temps_fabrication,
        nbre_unite_fabrique: recette?.nbre_unite_fabrique,
        nbre_emballage: recette?.nbre_emballage,
        cout_fabrication_total: recette?.cout_fabrication_total,
        type_cout_par_unite: recette?.type_cout_par_unite,
        cout_sans_emballage: recette?.cout_sans_emballage,
        cout_avec_emballage: recette?.cout_avec_emballage,
        cout_total_emballage: recette?.cout_total_emballage,
      });
      if (recette?.recette_matiere_premieres?.length) {
        let optionsCopy = [...options];
        let linesCopy = [...lines];
        for (
          let i: number = 0;
          i < recette?.recette_matiere_premieres?.length;
          i++
        ) {
          const element = recette?.recette_matiere_premieres[i];
          setValue(
            `recette_matiere_premieres.${i}.recette_matiere_premiere_id`,
            element?.recette_matiere_premiere_id
          );
          setValue(`recette_matiere_premieres.${i}.nom`, element?.nom);
          setValue(
            `recette_matiere_premieres.${i}.ligne_commande_id`,
            element?.ligne_commande_id
          );
          setValue(
            `recette_matiere_premieres.${i}.categorie`,
            element?.categorie
          );
          setValue(`recette_matiere_premieres.${i}.poids`, element?.poids);

          setValue(
            `recette_matiere_premieres.${i}.unite_poids`,
            element?.unite_poids
          );
          setValue(
            `recette_matiere_premieres.${i}.pourcentage`,
            element?.pourcentage
          );
          setValue(
            `recette_matiere_premieres.${i}.cout`,
            element?.ligne_commande?.prix_unite
          );
          setValue(
            `recette_matiere_premieres.${i}.cout_total`,
            element?.cout_total
          );

          optionsCopy = [...optionsCopy];
          optionsCopy[i] = {
            ...optionsCopy[i],
            pourcentage: element?.pourcentage,
          };
          optionsCopy[i] = {
            ...optionsCopy[i],
            cout: element?.ligne_commande?.prix_unite,
          };
          optionsCopy[i] = {
            ...optionsCopy[i],
            unite_poids: element?.unite_poids,
          };
          optionsCopy[i] = {
            ...optionsCopy[i],
            unite_poids_mp: element?.ligne_commande?.unite_poids,
          };
          optionsCopy[i] = {
            ...optionsCopy[i],
            poids: element?.poids,
          };
          optionsCopy[i] = {
            ...optionsCopy[i],
            cout_total: element?.cout_total,
          };
          optionsCopy[i] = {
            ...optionsCopy[i],
            categorie: element?.categorie,
          };

          optionsCopy[i] = {
            ...optionsCopy[i],
            nom: element?.ligne_commande_id,
          };
          if (element?.ligne_commande) {
            linesCopy[i] = element?.ligne_commande;
          }
        }
        console.log(linesCopy);
        setLines(linesCopy);
        setOptions(optionsCopy);
      }
    }
  }, [recette]);
  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    control,
    Controller,
    options,
    handleChange,
    HandleSelectName,
    stocks: stocks?.data,
    totals,
    level,
    dataForm,
    onChangeInfo,
    infos,
    onChangeFabrication,
    infosFabic,
    type,
    onSelectType,
  };
}

export default UseCrudRecetteForm;

export function UseDeleteRecetteItem(id: string) {
  const [deleteData] = useDeleteRecetteMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette recette ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(id);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        console.log(result);
        Swal.fire({
          icon: "success",
          title: `Recette supprimée avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
