import React from "react";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../utils/type";
import UseRequestPassword from "./requestForm/UseRequestPassword";
import InfoEmail from "./TabsForgetPassword/InfoEmail";
import TabsMessageSend from "./TabsForgetPassword/TabsMessageSend";

const steps = [
  { id: "email", Component: InfoEmail },
  { id: "message", Component: TabsMessageSend },
];
const ForgetPassword = () => {
  const form = UseRequestPassword()
  const { step, navigation }: useStepType = useStep({
    initialStep: 0,
    steps,
  });
  const { Component } = step;
  const props = {
    ...form,
    navigation,
  };
  return (
    <div>
      <div className="container-page-login-app">
        <div className="auth-row">
          <div className="auth-col auth-right-side-col">
            <Component {...props} />
          </div>
          <p className="text-footer-auth">
            ©2021 All Rights Reserved. Volkeno Inc
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
