import React from "react";
import { NavLink } from "react-router-dom";
import HeaderAdmin from "../TableauDeBord/navBar/HeaderAdmin/HeaderAdmin";
import { MdClose } from "react-icons/md";

const RegisterFail = () => {
  return (
    <div>
      <HeaderAdmin />
      <div className="container-page-login-app mt-5">
        <div className="auth-row pt-5">
          <div className="auth-col auth-right-side-col">
            <div className="auth-right-side-container">
              <div className="auth-form-container text-center pb-4">
                <div className="content-logo-auth pt-5">
                  <MdClose className="icon-info-after-register color-red" />
                </div>
                <h2 className="title-auth-form-text pt-4">Ohhh no!!</h2>
                <div className="content-text-message-login text-center">
                  <p className="auth-form-message">
                  Lorem ipsum dolor sit amet consectetur. Commodo varius turpis nibh congue arcu. Fringilla mauris euismod neque egestas a.
                  </p>
                </div>
                <div className="mt-5">
                  <form id="auth-form">
                    <div className="row auth-form-row">
                      <div className="col-md-12 auth-submit-btn-container mt-2">
                        <NavLink
                          to="/inscription"
                          className="btn auth-submit-btn trans-0-2"
                        >
                          Retour
                        </NavLink>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterFail;
