import React, { ReactElement } from "react";
import Login from "../components/auth/Login";
import Register from "../components/auth/Register";
import { Navigate } from "react-router-dom";
import DashboardAdmin from "../components/admin/DashboardAdmin/DashboardAdmin";
import ForgetPassword from "../components/auth/ForgetPassword";
import FabriquantLayout from "../components/TableauDeBord/FabriquantLayout/FabriquantLayout";
import DashboardFabriquant from "../components/TableauDeBord/DashboardFabriquant/DashboardFabriquant";
import FournisseursList from "../components/TableauDeBord/FournisseursList/FournisseursList";
import AddFournisseur from "../components/TableauDeBord/FournisseursList/AddFournisseur";
import ComandesFournisseur from "../components/TableauDeBord/FournisseursList/ComandesFournisseur/ComandesFournisseur";
import StockMP from "../components/TableauDeBord/StockMP/StockMP";
import ReceptionnerCommande from "../components/TableauDeBord/StockMP/ReceptionnerCommande";
import AjouterCommande from "../components/TableauDeBord/StockMP/AjouterCommande";
import GestionRecetteFariquant from "../components/TableauDeBord/GestionRecetteFariquant/GestionRecetteFariquant";
import AddNewRecette from "../components/TableauDeBord/GestionRecetteFariquant/AddNewRecette/AddNewRecette";
import GestionFabrication from "../components/TableauDeBord/GestionFabrication/GestionFabrication";
import AddNewFabrication from "../components/TableauDeBord/GestionFabrication/AddNewFabrication/AddNewFabrication";
import StockPf from "../components/TableauDeBord/StockPf/StockPf";
import ProfilPage from "../components/TableauDeBord/ProfilPage/ProfilPage";
import EditProfil from "../components/TableauDeBord/ProfilPage/EditProfil";
import AdminLayout from "../components/admin/AdminLayout/AdminLayout";
import GestionUsers from "../components/admin/GestionUsers/GestionUsers";
import AddUserAdmin from "../components/admin/GestionUsers/AddUserAdmin";
import GestionStockAdmin from "../components/admin/GestionStockAdmin/GestionStockAdmin";
import GestionRecettes from "../components/admin/GestionRecettes/GestionRecettes";
import GestionFabricationAdmin from "../components/admin/GestionFabricationAdmin/GestionFabricationAdmin";
import ParametreAdmin from "../components/admin/ParametreAdmin/ParametreAdmin";
import AddCgu from "../components/admin/ParametreAdmin/AddCgu";
import AddConfidentialite from "../components/admin/ParametreAdmin/AddConfidentialite";
import RegisterOk from "../components/auth/RegisterOk";
import RegisterFail from "../components/auth/RegisterFail";
import ResetPassword from "../components/auth/ResetPassword";
import { useAppSelector } from "../redux/hook";
import { isAdmin, isfabriquant } from "../utils/utils";
import EditCommandeLine from "../components/TableauDeBord/StockMP/EditCommandeLine";
import DetailRecetteFabrication from "../components/TableauDeBord/GestionRecetteFariquant/DetailRecetteFabrication";
import DetailFabricantSavon from "../components/TableauDeBord/GestionFabrication/FabricationTabs/DetailFabricantSavon";
import DetailFabricantCosmetic from "../components/TableauDeBord/GestionFabrication/FabricationTabs/DetailFabricationCosmetic";
// UserRoutes

export const RedirectAuthRoute = ({ children }: { children: ReactElement }) => {
  const { token, userInfos } = useAppSelector((state) => state.userInfos);
  let path: string = "/";

  if (isfabriquant(userInfos)) {
    path = "/fabriquant/dashboard";
  } else if (isAdmin(userInfos)) {
    path = "/admin/dashboard";
  }

  return !token && !userInfos?.id ? children : <Navigate to={path} replace />;
};

const IsFabricantAllow = ({ children }: { children: ReactElement }) => {
  const { userInfos, token } = useAppSelector((state) => state.userInfos);
  let path: string = "/";
  if (token) {
    if (isAdmin(userInfos)) {
      path = "/admin/dashboard";
    }
  }

  // return children;
  return !token || !isfabriquant(userInfos) ? (
    <Navigate to={path} replace />
  ) : (
    children
  );
};

const IsAdminAllow = ({ children }: { children: ReactElement }) => {
  const { userInfos, token } = useAppSelector((state) => state.userInfos);
  let path: string = "/";
  if (token) {
    if (isfabriquant(userInfos)) {
      path = "/fabriquant/dashboard";
    }
  }

  return !token || !isAdmin(userInfos) ? (
    <Navigate to={path} replace />
  ) : (
    children
  );
};
export const AppRoutes = [
  {
    path: "/",
    element: (
      <RedirectAuthRoute>
        <Login />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "/inscription",
    element: (
      <RedirectAuthRoute>
        <Register />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "/mot-de-passe-oublie",
    element: (
      <RedirectAuthRoute>
        <ForgetPassword />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "/renitialiser-mot-de-passe",
    element: (
      <RedirectAuthRoute>
        <ResetPassword />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "/verify-email/fabricant/:token",
    element: (
      <RedirectAuthRoute>
        <RegisterOk />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "/inscription-echouee",
    element: <RegisterFail />,
  },
  {
    path: "fabriquant/",
    element: (
      <IsFabricantAllow>
        <FabriquantLayout />
      </IsFabricantAllow>
    ),
    children: [
      { element: <Navigate to="/fabriquant/dashboard" replace />, index: true },
      {
        path: "dashboard",
        element: <DashboardFabriquant />,
      },
      {
        path: "fournisseurs",
        element: <FournisseursList />,
      },
      {
        path: "ajouter-fournisseur",
        element: <AddFournisseur />,
      },
      {
        path: "modifier-fournisseur/:id",
        element: <AddFournisseur />,
      },
      {
        path: "commandes-fournisseur/:id",
        element: <ComandesFournisseur />,
      },
      {
        path: "stock-mp",
        element: <StockMP />,
      },
      {
        path: "receptionner-commande",
        element: <ReceptionnerCommande />,
      },
      {
        path: "ajouter-commande/:id",
        element: <AjouterCommande />,
      },
      {
        path: "modifier-stock/:id",
        element: <EditCommandeLine />,
      },
      {
        path: "recettes",
        element: <GestionRecetteFariquant />,
      },
      {
        path: "recette/:id",
        element: <DetailRecetteFabrication />,
      },
      {
        path: "ajouter-recette",
        element: <AddNewRecette />,
      },
      {
        path: "modifier-recette/:id",
        element: <AddNewRecette />,
      },
      {
        path: "fabrication",
        element: <GestionFabrication />,
      },
      {
        path: "fabrication/:id",
        element: <DetailFabricantSavon />,
      },
      {
        path: "fabrication-cosmetique/:id",
        element: <DetailFabricantCosmetic />,
      },
      {
        path: "fabriquer",
        element: <AddNewFabrication />,
      },
      {
        path: "modifier-fabrication/:id",
        element: <AddNewFabrication />,
      },
      {
        path: "stock-pf",
        element: <StockPf />,
      },
      {
        path: "profil",
        element: <ProfilPage />,
      },
      {
        path: "modifier-profil",
        element: <EditProfil />,
      },
    ],
  },
  {
    path: "admin/",
    element: (
      <IsAdminAllow>
        <AdminLayout />
      </IsAdminAllow>
    ),
    children: [
      { element: <Navigate to="/admin/dashboard" replace />, index: true },
      {
        path: "dashboard",
        element: <DashboardAdmin />,
      },
      {
        path: "utilisateurs",
        element: <GestionUsers />,
      },
      {
        path: "ajouter-utilisateur",
        element: <AddUserAdmin />,
      },
      {
        path: "modifier-utilisateur/:id",
        element: <AddUserAdmin />,
      },
      {
        path: "ajouter-admin",
        element: <AddUserAdmin />,
      },
      {
        path: "modifier-admin/:id",
        element: <AddUserAdmin />,
      },
      {
        path: "stock",
        element: <GestionStockAdmin />,
      },
      {
        path: "recettes",
        element: <GestionRecettes />,
      },
      {
        path: "recette/:id",
        element: <DetailRecetteFabrication />,
      },
      {
        path: "fabrication",
        element: <GestionFabricationAdmin />,
      },
      {
        path: "fabrication/:id",
        element: <DetailFabricantSavon />,
      },
      {
        path: "parametres",
        element: <ParametreAdmin />,
      },
      {
        path: "ajouter-cgu",
        element: <AddCgu />,
      },
      {
        path: "ajouter-politique-de-confidentialite",
        element: <AddConfidentialite />,
      },
      {
        path: "profil",
        element: <ProfilPage />,
      },
      {
        path: "modifier-profil",
        element: <EditProfil />,
      },
    ],
  },
];
