import moment from "moment";
import React from "react";
import { BsCurrencyEuro } from "react-icons/bs";
import { CommandeLine } from "../../../../utils/api/commande/commande.type";

const StockDetailsModal = ({ item }: { item: CommandeLine }) => {
  return (
    <div
      className="modal fade"
      id={`commandeItemModal${item?.ligne_commande_id}`}
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close no-style-btn-modal btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="container-detail-fournisseur">
              <h5 className="modal-title">Details stock</h5>
              <div className="container-info-fournisseur mt-4 row">
                <div className="info-fournisseur-detail-item col-md-6">
                  <div className="fournisseur-info-detail">
                    <p className="libelle-info-detail mb-1">Fournisseur</p>
                    <p className="value-info-detail mb-0">
                      {item?.details_commande?.commande?.fournisseur
                        ?.fullname || "Non défini"}
                    </p>
                  </div>
                </div>
                <div className="info-fournisseur-detail-item col-md-6">
                  <div className="fournisseur-info-detail">
                    <p className="libelle-info-detail mb-1">
                      Date de la commande
                    </p>
                    <p className="value-info-detail mb-0">
                      {moment(item?.details_commande?.commande?.date_commande, "DD-MM-YYYY").format(
                        "DD/MM/YYYY"
                      ) || "Non défini"}
                      {/* {item?.date_commande} */}
                    </p>
                  </div>
                </div>
                <div className="info-fournisseur-detail-item col-md-6">
                  <div className="fournisseur-info-detail">
                    <p className="libelle-info-detail mb-1">Nom</p>
                    <p className="value-info-detail mb-0">
                      {item?.nom || "Non défini"}
                    </p>
                  </div>
                </div>
                <div className="info-fournisseur-detail-item col-md-6">
                  <div className="fournisseur-info-detail">
                    <p className="libelle-info-detail mb-1">Catégorie</p>
                    <p className="value-info-detail mb-0">
                      {item?.category || "Non défini"}
                    </p>
                  </div>
                </div>
                <div className="info-fournisseur-detail-item col-md-6">
                  <div className="fournisseur-info-detail">
                    <p className="libelle-info-detail mb-1">N° Lot</p>
                    <p className="value-info-detail mb-0">
                      {item?.numero_lot || "Non défini"}
                    </p>
                  </div>
                </div>
                <div className="info-fournisseur-detail-item col-md-6">
                  <div className="fournisseur-info-detail">
                    <p className="libelle-info-detail mb-1">DLUO/DDM</p>
                    <p className="value-info-detail mb-0">
                      {moment(item?.dluo_ddm, "DD-MM-YYYY").format(
                        "DD/MM/YYYY"
                      ) || "Non défini"}
                    </p>
                  </div>
                </div>
                <div className="info-fournisseur-detail-item col-md-6">
                  <div className="fournisseur-info-detail">
                    <p className="libelle-info-detail mb-1">Date Ouverture</p>
                    <p className="value-info-detail mb-0">
                      {item?.date_ouverture
                        ? moment(item?.date_ouverture, "DD-MM-YYYY").format(
                            "DD/MM/YYYY"
                          )
                        : "Non défini"}
                    </p>
                  </div>
                </div>
                <div className="info-fournisseur-detail-item col-md-6">
                  <div className="fournisseur-info-detail">
                    <p className="libelle-info-detail mb-1">Quantité</p>
                    <p className="value-info-detail mb-0">
                      {item?.quantite || "Non défini"}
                    </p>
                  </div>
                </div>
                <div className="info-fournisseur-detail-item col-md-6">
                  <div className="fournisseur-info-detail">
                    <p className="libelle-info-detail mb-1">Poids</p>
                    <p className="value-info-detail mb-0">
                      {item?.poids || "Non défini"}
                      {!!item?.poids && item?.unite_poids}
                    </p>
                  </div>
                </div>
                <div className="info-fournisseur-detail-item col-md-6">
                  <div className="fournisseur-info-detail">
                    <p className="libelle-info-detail mb-1">Volume</p>
                    <p className="value-info-detail mb-0">
                      {item?.volume || "Non défini"}
                      {!!item?.volume && item?.unite_volume?.toUpperCase()}
                    </p>
                  </div>
                </div>
                <div className="info-fournisseur-detail-item col-md-6">
                  <div className="fournisseur-info-detail">
                    <p className="libelle-info-detail mb-1">Seuil d'alerte</p>
                    <p className="value-info-detail mb-0">
                      {item?.seuil_alerte || "Non défini"}
                    </p>
                  </div>
                </div>
                <div className="info-fournisseur-detail-item col-md-6">
                  <div className="fournisseur-info-detail">
                    <p className="libelle-info-detail mb-1">Prix par unité</p>
                    <p className="value-info-detail mb-0">
                      {item?.prix_unite || "Non défini"}€
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <div className="container-total-add-commande-table">
                  <div className="content-total-add-commande-table">
                    <h2 className="text-value-info-commande-add">Total</h2>
                    <div className="total-add-commande-table position-relative">
                      {item?.prix_total_ligne || 0}
                      <span className="unite-totaladd-commande-table">
                        <BsCurrencyEuro />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockDetailsModal;
