import React, { Dispatch, SetStateAction, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import TableSkeleton from "../../../common/TableSkeleton";
import StockPfTableData from "./StockPfTableData";
import { BsFileCheckFill } from "react-icons/bs";
import { useAppSelector } from "../../../../redux/hook";
import { useGetPFListQuery } from "../../../../utils/api/fabrication/fabrication.api";
import { CustomPagination } from "../../StockMP/StockMPTable/StockMPTable";
import { Recette } from "../../../../utils/api/recette/recette.type";
import { onFormatDate } from "../../../../utils/utils";
import { Fabrication } from "../../../../utils/api/fabrication/fabrication.type";

function StockPfTable({setItem}:{setItem: Dispatch<SetStateAction<Fabrication | undefined>>}) {
  const [page, setPage] = useState(1);
  // const [item, setItem] = useState<Fabrication | undefined>()
  const { userInfos } = useAppSelector((s) => s?.userInfos);
  const { data = { data: { data: [], total: 0 } }, isLoading } =
    useGetPFListQuery({
      id: userInfos?.user_id,
      page,
    });

  const cureFormatter = (cell, row:Fabrication) => {
    if (row?.type_produit === "cosmetique") {
      return ""
    }

    if (cell === "vert") {
      return (
        <>
          <span style={{ color: "#219653", fontSize: 20 }}>
            <BsFileCheckFill />
          </span>
        </>
      );
    }
    if (cell === "Orange") {
      return (
        <>
          <span style={{ color: "#F2994A", fontSize: 20 }}>
            <BsFileCheckFill />
          </span>
        </>
      );
    }

    return (
      <>
        <span style={{ color: "#EB5757", fontSize: 20 }}>
          <BsFileCheckFill />
        </span>
      </>
    );
  };

  const rowClasses = (row, rowIndex) => {
    return "custom-row-class-stock";
  };

  const selectRow = {
    mode: "radio",
    clickToSelect: true,
    onSelect: (row: Fabrication) => {
      setItem(row);
    },
    style: { backgroundColor: "#b9e6f2" },
    hideSelectColumn: true,
  };

  const columns = [
    {
      dataField: "numero_fabrication",
      text: "N° ",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: "type_produit",
      text: "Catégorie",
    },
    {
      dataField: "recette",
      text: "Nom",
      formatter: (cell: Recette) => cell?.nom_recette,
    },
    {
      dataField: "code_interne",
      text: "Code interne",
    },
    {
      dataField: "numero_lot",
      text: "N° de lot",
    },
    {
      dataField: "date_fabrication",
      text: "Date de fabrication",
      formatter: (cell: string) => onFormatDate(cell),
    },
    {
      dataField: "mise_encure_status",
      text: "Sortie de cure",
      formatter: (cell: any, row: any) => cureFormatter(cell, row),
    },
    {
      dataField: "sortie_cure_nombre_produits_conformes",
      text: "Quantité restante",
      formatter: (cell: number) => cell || "Non disponible",
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="fabrication_id"
            data={data?.data?.data}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            selectRow={selectRow}
            rowClasses={rowClasses}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => "Aucune donnée trouvée"}
          />
          <CustomPagination
            nbPages={data?.data?.total}
            page={page}
            onChange={(page) => {
              setPage(page);
            }}
            perPage={10}
          />
        </>
      )}
    </>
  );
}

export default StockPfTable;
