import React from "react";
import FilterIcon from "../../../../assets/icons/filter icon.svg";
import StockMPAdminTable from "../StockMPAdminTable/StockMPAdminTable";

const MatierePremiereTabs = () => {
  
  return (
    <div className="savon-tabs-component">
      <div className="container-filter-table pb-4">
        <div className="row pb-4">
        <div className="col-md-3 mb-3">
            <div className="form-filter-table position-relative">
              <input
                type="text"
                className="form-control form-control-filter-table"
                placeholder="Filtre"
              />
              <span className="content-icon-filter-table">
                <img
                  src={FilterIcon}
                  alt="filter"
                  className="filter-icon-table"
                />
              </span>
            </div>
          </div>
        </div>
        <div className="container-table-savon">
            <StockMPAdminTable />
        </div>
      </div>
    </div>
  );
};

export default MatierePremiereTabs;
