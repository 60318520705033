import React, { useState } from "react";
import Logo from "../../assets/appImages/logo.png";
import { NavLink } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import UseRegisterForm from "./requestForm/UseRegisterForm";
import PhoneInput from "react-phone-input-2";
import ErrorMessage from "../common/ErrorMessage";
import moment from "moment";
import VersionChecker from "../../versionChecker/VersionChecker";

const Register = () => {
  const {
    register,
    errors,
    phone,
    handleChangePhone,
    onSubmit,
    isLoading,
    passwordHandleChange,
    haveMinCharacter,
    haveMinLowercase,
    haveMinNumber,
    haveMinSpecialCharacter,
    haveMinUppercase,
  } = UseRegisterForm();
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  return (
    <div>
      <div className="container-page-login-app">
        <div className="auth-row">
          <div className="auth-col auth-right-side-col">
            <div className="auth-right-side-container">
              <div className="auth-form-container text-center pb-4">
                <div className="content-logo-auth pb-2">
                  <img src={Logo} alt="logo" className="logo-auth-page" />
                </div>
                <h2 className="title-auth-form-text pt-4">Inscription</h2>
                <div className="content-text-message-login text-center">
                  <span className="auth-form-message">
                    Vous avez déjà un compte?
                  </span>
                  <NavLink to="/" className="btn-text-message-login ps-2">
                    Connectez-vous
                  </NavLink>
                </div>
                <div className="mt-5">
                  <form id="auth-form" onSubmit={onSubmit}>
                    <div className="row auth-form-row">
                      <div className="col-md-12 auth-input-col mb-3">
                        <div className="auth-form-group">
                          <label className="form-label form-label-auth-login">
                            Prénom
                          </label>
                          <input
                            type={`text`}
                            className="form-control auth-form-control"
                            id="email-or-username"
                            {...register("first_name")}
                            placeholder="Prénom"
                            aria-label="Username"
                            data-testid="firstNameId"
                          />
                          <ErrorMessage message={errors?.first_name?.message} />
                        </div>
                      </div>
                      <div className="col-md-12 auth-input-col mb-3">
                        <div className="auth-form-group">
                          <label className="form-label form-label-auth-login">
                            Nom
                          </label>
                          <input
                            type={`text`}
                            className="form-control auth-form-control"
                            id="lastName"
                            placeholder="Nom"
                            {...register("last_name")}
                            aria-label="lastName"
                            data-testid="lastNameId"
                          />
                          <ErrorMessage message={errors?.last_name?.message} />
                        </div>
                      </div>
                      <div className="col-md-12 auth-input-col mb-3">
                        <div className="auth-form-group">
                          <label className="form-label form-label-auth-login">
                            Adresse mail
                          </label>
                          <input
                            type={`email`}
                            className="form-control auth-form-control"
                            id="email"
                            {...register("email")}
                            placeholder="Adresse mail"
                            aria-label="email"
                            data-testid="emailId"
                          />
                          <ErrorMessage message={errors?.email?.message} />
                        </div>
                      </div>
                      <div className="col-md-12 auth-input-col mb-3">
                        <div className="auth-form-group">
                          <label className="form-label form-label-auth-login">
                            Numéro de téléphone ( optionnel)
                          </label>
                          {/* <input
                            type={`tel`}
                            className="form-control auth-form-control"
                            id="email-or-username"
                            placeholder="Numéro de téléphone ( optionnel)"
                            aria-label="Username"
                          /> */}
                          <PhoneInput
                            containerClass="mb-3 p-0"
                            inputClass="form-control auth-form-control"
                            country={"fr"}
                            data-testid="phoneId"
                            placeholder=""
                            inputProps={{
                              name: "telephone",
                              required: true,
                              autoFocus: true,
                            }}
                            countryCodeEditable={false}
                            enableAreaCodes={true}
                            prefix="+"
                            value={phone}
                            onChange={(phone, country) =>
                              handleChangePhone(phone, country)
                            }
                            inputStyle={{
                              width: "100%",
                              paddingBottom: "30px",
                              paddingTop: "30px",
                            }}
                          />
                          <ErrorMessage message={errors?.phone?.message} />
                        </div>
                      </div>
                      <div className="col-md-12 auth-input-col mb-2">
                        <div className="auth-form-group position-relative">
                          <label className="form-label form-label-auth-login">
                            Mot de passe
                          </label>
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control auth-form-control"
                            id="password"
                            // {...register("password")}
                            onChange={passwordHandleChange}
                            placeholder="Mot de passe"
                            aria-label="Password"
                            data-testid="passwordId"
                          />
                          <span
                            className="show-hide-password"
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                          >
                            {showPassword ? (
                              <AiOutlineEyeInvisible />
                            ) : (
                              <AiOutlineEye />
                            )}
                          </span>
                        </div>
                        <ErrorMessage message={errors?.password?.message} />
                      </div>
                      <div className="col-md-12 auth-input-col mb-2">
                        <div className="auth-form-group position-relative">
                          <label className="form-label form-label-auth-login">
                            Confimer le mot de passe
                          </label>
                          <input
                            type={showCPassword ? "text" : "password"}
                            className="form-control auth-form-control"
                            id="password_confirmation"
                            {...register("password_confirmation")}
                            placeholder="Confimer le mot de passe"
                            aria-label="password_confirmation"
                            data-testid="cPasswordId"
                          />
                          <span
                            className="show-hide-password"
                            onClick={() => {
                              setShowCPassword(!showCPassword);
                            }}
                          >
                            {showCPassword ? (
                              <AiOutlineEyeInvisible />
                            ) : (
                              <AiOutlineEye />
                            )}
                          </span>
                        </div>
                        <ErrorMessage
                          message={errors?.password_confirmation?.message}
                        />
                      </div>
                      <div className="col-md-12 auth-submit-btn-container mt-2 mb-4">
                        <button
                          type="submit"
                          disabled={isLoading}
                          data-testid="submitBtnId"
                          className="btn auth-submit-btn trans-0-2"
                        >
                          {isLoading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                                role="status"
                              ></span>
                              <span>Inscription...</span>
                            </>
                          ) : (
                            "Inscription"
                          )}
                        </button>
                      </div>
                      <div className="col-md-12">
                        <div className="input-group my-2 password-validation-terms-row">
                          <div className="password-validation-terms-container">
                            <h4 className="password-validation-terms-title">
                              Votre mot de passe doit contenir :{" "}
                            </h4>
                            <ul className="password-validation-terms-ul p-l-14 text-start">
                              <li
                                className={
                                  "password-rule-item " +
                                  (haveMinCharacter && "text-success")
                                }
                              >
                                Au minimum 8 caractères
                              </li>
                              <li
                                className={
                                  "password-rule-item " +
                                  (haveMinUppercase && "text-success")
                                }
                              >
                                Au minimum 1 majuscule
                              </li>
                              <li
                                className={
                                  "password-rule-item " +
                                  (haveMinLowercase && "text-success")
                                }
                              >
                                Au minimum 1 minuscule
                              </li>
                              <li
                                className={
                                  "password-rule-item " +
                                  (haveMinNumber && "text-success")
                                }
                              >
                                Au minimum 1 chiffre
                              </li>
                              <li
                                className={
                                  "password-rule-item " +
                                  (haveMinSpecialCharacter && "text-success")
                                }
                              >
                                Au minimum 1 caractère spécial
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* <p className="text-footer-auth">
            ©2021 All Rights Reserved. Volkeno Inc
          </p> */}
          <p className="text-footer-auth d-flex justify-content-center">
            ©{moment(new Date()).format("YYYY")} All Rights Reserved. LOGISAOP{" "}
            &nbsp;
            <div className="version-view-container">
              {" "}
              {"v" + VersionChecker()}
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
