import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Echantillon } from "../../../../../utils/api/fabrication/fabrication.type";
import { onFormatDate } from "../../../../../utils/utils";
import ErrorMessage from "../../../../common/ErrorMessage";
import TableSkeleton from "../../../../common/TableSkeleton";
import SuiviCureTableData from "./SuiviCureTableData";

function SuiviCureTable({ data }: { data: Echantillon[] }) {
  const [page, setPage] = useState(SuiviCureTableData);
  const [isLoading, setIsLoading] = React.useState();

  const columns = [
    {
      dataField: "set_date",
      text: "Date",
      formatter: (cell) => !!cell && onFormatDate(cell),
    },
    {
      dataField: "set_poids",
      text: "Poids",
      formatter: (cell) => cell + "kg",
    },
    {
      dataField: "set_olfactif",
      text: "Visuel",
      formatter: (cell) => (cell ? "OK" : "NON OK"),
    },
    {
      dataField: "set_visuel",
      text: "Olfactif",
      formatter: (cell) => (cell ? "OK" : "NON OK"),
    },
    {
      dataField: "set_observations",
      text: "Observations",
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="suivi_echantillon_temoin_id"
            data={data}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => "Aucune donnée trouvée"}
          />
        </>
      )}
    </>
  );
}

export default SuiviCureTable;
