import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import TableSkeleton from "../../../common/TableSkeleton";
import { BsFileCheckFill } from "react-icons/bs";
import { useGetFabricationByUserQuery } from "../../../../utils/api/fabrication/fabrication.api";
import { useAppSelector } from "../../../../redux/hook";
import { Recette } from "../../../../utils/api/recette/recette.type";
import { onFormatDate } from "../../../../utils/utils";
import { CustomPagination } from "../../StockMP/StockMPTable/StockMPTable";
import { Fabrication } from "../../../../utils/api/fabrication/fabrication.type";
import { UseDeleteFabricationItem } from "../AddNewFabrication/requestFabrication/UseCrudFabricationForm";
import { FiEdit, FiEye, FiTrash } from "react-icons/fi";
import { NavLink, useNavigate } from "react-router-dom";

function DeleteItemBtn({ id }: { id: string }) {
  const onDelete = UseDeleteFabricationItem(id);

  return (
    <button
      className="btn btn-action-modal-icon with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={() => onDelete()}
    >
      <FiTrash />
    </button>
  );
}
function SavonFabricationTable({ type,term }: { type: string;term:string }) {
  const [page, setPage] = useState(1);
  const { userInfos } = useAppSelector((s) => s?.userInfos);
  const { data = { data: { data: [], total: 0 } }, isLoading } =
    useGetFabricationByUserQuery({
      id: userInfos?.user_id,
      page,
      type: type || "",
      term: term || null
    });
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("data", data);
  }, [data]);

  const actionFormatter = (cell: string, row: Fabrication) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-2 align-items-center">
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/fabriquant/fabrication/${cell}`}
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Visualiser"
              state={row}
            >
              <FiEye />
            </NavLink>
          </div>

          <div className="container-btn-action-icon-modal">
            <NavLink
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Modifier"
              to={`/fabriquant/modifier-fabrication/${cell}`}
              state={row}
            >
              <FiEdit />
            </NavLink>
          </div>
          <div className="container-btn-action-icon-modal">
            <DeleteItemBtn id={cell} />
          </div>
        </div>
      </>
    );
  };

  const cureFormatter = (cell, row) => {
    if (cell === "vert") {
      return (
        <>
          <span style={{ color: "#219653", fontSize: 20 }}>
            <BsFileCheckFill />
          </span>
        </>
      );
    }
    if (cell === "Orange") {
      return (
        <>
          <span style={{ color: "#F2994A", fontSize: 20 }}>
            <BsFileCheckFill />
          </span>
        </>
      );
    }

    return (
      <>
        <span style={{ color: "#EB5757", fontSize: 20 }}>
          <BsFileCheckFill />
        </span>
      </>
    );
  };

  const rowClasses = () => {
    return "custom-row-class";
  };

  const columns = [
    {
      dataField: "numero_fabrication",
      text: "N°",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: "recette",
      text: "Nom de la recette",
      formatter: (cell: Recette) => cell?.nom_recette,
    },
    {
      dataField: "numero_lot",
      text: "N° de lot",
    },
    {
      dataField: "date_fabrication",
      text: "Date de fabrication",
      formatter: (cell: string) => onFormatDate(cell),
    },
    {
      dataField: "mise_encure_status",
      text: "Sortie de cure",
      formatter: (cell: string, row: Fabrication) => cureFormatter(cell, row),
      hidden: type === "cosmetique"
    },
    {
      dataField: "fabrication_id",
      text: "Actions",
      formatter: (cell: string, row: Fabrication) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="fabrication_id"
            data={data?.data?.data}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            rowClasses={rowClasses}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => "Aucune donnée trouvée"}
          />
          <CustomPagination
            nbPages={data?.data?.total}
            page={page}
            onChange={(page) => {
              setPage(page);
            }}
            perPage={10}
          />
        </>
      )}
    </>
  );
}

export default SavonFabricationTable;
