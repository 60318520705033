import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import TableSkeleton from "../../../common/TableSkeleton";
import { BsFileCheckFill } from "react-icons/bs";
import { useGetCommandeLineListQuery } from "../../../../utils/api/commande/commande.api";
import { AlertInfo } from "../../../common/Alert";
import { ErrorQuery } from "../../../../utils/type";
import {
  CommandeItem,
  CommandeLine,
} from "../../../../utils/api/commande/commande.type";
import { onFormatDate } from "../../../../utils/utils";
import StockDetailsModal from "../../../TableauDeBord/StockMP/modal/StockDetailsModal";
import { FiEye } from "react-icons/fi";

function StockMPAdminTable() {
  const {
    data = { data: [] },
    isLoading,
    error,
  } = useGetCommandeLineListQuery({});
  // const [page, setPage] = useState(StockMPAdminTableData);
  let err = error as ErrorQuery;
  useEffect(() => {
    console.log("data", data);
  }, [data]);
  const ruptureFormatter = (cell, row) => {
    return (
      <p className="price-table-format mb-0">
        <span
          style={{
            color:
              parseInt(row?.seuil_alerte) >= (parseInt(row?.quantite) * parseFloat(row?.poids || row?.volume))
                ? "#EB5757"
                : "#50b2d4",
            fontSize: 20,
          }}
        >
          <BsFileCheckFill />{" "}
        </span>
      </p>
    );
  };

  const nameFormatter = (cell, row) => {
    return (
      <>
        <p className="nom-stock mb-1">{cell}</p>
        <span style={{ color: "#667085", fontSize: 14, fontWeight: 400 }}>
          {row?.category}
        </span>
      </>
    );
  };

  const quantiteFormatter = (cell, row) => {
    return (
      <div
        style={{
          color:
            cell === 0
              ? "#EB5757"
              : parseInt(row?.seuil_alerte) >= cell
              ? "#F2994A"
              : "",
          fontSize: 20,
        }}
      >
        {cell + (row?.unite_poids || row?.unite_volume)}
      </div>
    );
  };

  const actionFormatter = (cell, row) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-2 align-items-center">
          <div className="container-btn-action-icon-modal">
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Visualiser"
              data-bs-toggle="modal"
              data-bs-target={`#commandeItemModal${cell}`}
            >
              <FiEye />
            </button>
          </div>
        </div>
        <StockDetailsModal item={row} />
      </>
    );
  };

  const columns = [
    {
      dataField: "nom",
      text: "Nom MP",
      formatter: (cell: string, row: CommandeLine) => nameFormatter(cell, row),
    },
    {
      dataField: "fournisseur",
      text: "Fournisseurs",
      formatter: (cell: string, row: CommandeLine) =>
        row?.details_commande?.commande?.fournisseur?.fullname,
    },
    {
      dataField: "poids_volume_total",
      text: "Quantité en stock",
      formatter: (cell: number, row: CommandeLine) =>
        quantiteFormatter(cell, row),
    },
    {
      dataField: "seuil_alerte",
      text: "Stock bas",
      style: { color: "#EB5757" },
    },
    {
      dataField: "id",
      text: "Rupture de stock",
      formatter: (cell: string, row: CommandeLine) =>
        ruptureFormatter(cell, row),
    },
    {
      dataField: "details_commande",
      text: "Date commande",
      formatter: (cell: CommandeItem) =>
        onFormatDate(cell?.commande?.date_commande),
      headerStyle: () => {
        return { width: "80px", textAlign: "center" };
      },
    },
    {
      dataField: "date_ouverture",
      text: "Date ouverture",
      formatter: (cell: string) => (cell ? onFormatDate(cell) : "Non définie"),
    },
    {
      dataField: "ligne_commande_id",
      text: "Actions",
      formatter: (cell: string, row: CommandeLine) =>
        actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "80px", textAlign: "center" };
      },
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="ligne_commande_id"
            data={data?.data}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            pagination={paginationFactory()}
            noDataIndication={() => (
              <AlertInfo
                message={err?.data?.message || "Aucune donnée trouvée"}
              />
            )}
          />
        </>
      )}
    </>
  );
}

export default StockMPAdminTable;
