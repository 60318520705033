import React, { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Fabrication,
  FabricationFormData,
} from "../../../../../utils/api/fabrication/fabrication.type";
import {
  useAddOrUpdateFabricationMutation,
  useDeleteFabricationMutation,
} from "../../../../../utils/api/fabrication/fabrication.api";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { useAppSelector } from "../../../../../redux/hook";
import { cleannerError, useLocationState } from "../../../../../utils/utils";
import { useNavigate } from "react-router-dom";
import { useLazyGetRecetteListByUserQuery } from "../../../../../utils/api/recette/recette.api";
import {
  Recette,
  RecetteItem,
} from "../../../../../utils/api/recette/recette.type";
import moment from "moment";

function UseCrudFabricationForm(fabrication?: Fabrication) {
  const validationSchema = yup.object().shape({
    step: yup.number(),
    type_produit: yup.string().when("step", {
      is: 0,
      then: yup.string().required().label("Le type"),
    }),
    recette_id: yup.string().when("step", {
      is: 0,
      then: yup.string().required().label("La recette"),
    }),
    code_interne: yup.string().when("step", {
      is: 0,
      then: yup.string().required().label("Le code interne"),
    }),
    numero_lot: yup.string().when("step", {
      is: 0,
      then: yup.string().required().label("Le numéro de lot").nullable(),
    }),
    date_fabrication: yup.string().when("step", {
      is: 0,
      then: yup.string().required().label("La date de fabrication"),
    }),
    dluo_ddm_pf: yup.string().when("step", {
      is: 0,
      then: yup.string().required().label("DLUO/DDM PF"),
    }),
    env_temperature_labo: yup
      .number()
      .when("step", {
        is: 0,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .label("La température du labo")
          .nullable(),
      })
      .transform((value) => (isNaN(value) ? undefined : value)),
    env_hygrometrie_labo: yup
      .number()
      .when("step", {
        is: 0,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .label("L'hygrométrie du labo")
          .nullable(),
      })
      .transform((value) => (isNaN(value) ? undefined : value)),
    env_autre: yup
      .string()
      .when("step", {
        is: 0,
        then: yup.string().nullable().label("L'hygrométrie du labo"),
      })
      .nullable(),

    fab_coefficient_multiplicateur: yup
      .number()
      .when("step", {
        is: 1,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable()
          .required()
          .label("Le coefficient multiplicateur"),
      })
      .transform((value) => (isNaN(value) ? undefined : value)),
    fab_moules_utilises: yup.string().when("step", {
      is: 1,
      then: yup.string().label("Moules utilisés"),
    }),
    fab_materiel_utilise: yup.string().when("step", {
      is: 1,
      then: yup.string().label("Le matériel utilisé"),
    }),
    fab_acocher: yup.string().when("step", {
      is: 1,
      then: yup.string().required().label("Ceci").nullable(),
    }),
    fab_acocher_concentration: yup
      .number()
      .when("step", {
        is: 1,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable()
          .required()
          .label("La concentration"),
      })
      .transform((value) => (isNaN(value) ? undefined : value)),

    fab_temperature_corps_gras: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()
      .label("Température des corps gras"),
    fab_temperature_soude: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()
      .label("Température de la soude"),
    fab_additif_ajoute_dans: yup
      .array()
      .of(yup.string().label("Additif ajouté dans").nullable())
      .nullable()
      .label("Additif ajouté dans"),
    fab_surgras: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()
      .label("Le Surgras"),
    recette_fabrications: yup.array().when("step", {
      is: 2,
      then: yup.array().of(
        yup.object().shape({
          recette_matiere_premiere_id: yup
            .string()
            .required()
            .label("Ceci")
            .nullable(),
          pesee_reelle: yup
            .number()
            .required()
            .label("La pesée réelle")
            .nullable(),

          unite_poids: yup
            .string()
            .required()
            .label("L'unité du poids")
            .nullable(),
        })
      ),
    }),
    suivi_echantillon_temoins: yup
      .array()
      .when("step", {
        is: 3,
        then: yup.array().of(
          yup.mixed()
          // yup.object().shape({
          //   set_date: yup.string().label("La date").nullable(),
          //   set_poids: yup
          //     .number()
          //     .transform((value) => (isNaN(value) ? undefined : value))
          //     .label("Le poids")
          //     .nullable(),
          //   unite_poids: yup
          //     .string()
          //     .label("L'unité du poids")
          //     .nullable()
          //     .default("kg"),
          //   set_olfactif: yup
          //     .boolean()
          //     .label("L'olfactif")
          //     .default(true)
          //     .nullable(),
          //   set_visuel: yup
          //     .boolean()
          //     .label("Le visuel")
          //     .default(true)
          //     .nullable(),
          //   set_observations: yup.string().label("L'observation").nullable(),
          // })
        ),
      })
      .nullable(),
    temps_cure_prevu: yup
      .number()
      .when("step", {
        is: 3,
        then: yup
          .number()
          .required()
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable()
          .label("Le temps prévu"),
      })
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(),
    temps_cure_restant: yup
      .number()
      .when("step", {
        is: 3,
        then: yup
          .number()
          .required()
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable()
          .label("Le temps restant"),
      })
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(),
    mise_encure_date: yup.string().when("step", {
      is: 3,
      then: yup.string().label("La date").nullable(),
    }),

    mise_encure_poids_lot: yup
      .number()
      .when("step", {
        is: 3,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable()
          .label("Le poids du lot"),
      })
      .transform((value) => (isNaN(value) ? undefined : value)),
    mise_encure_controle_olfactif: yup.boolean().when("step", {
      is: 3,
      then: yup
        .boolean()
        .label("Le contrôle olfactif")
        .default(false)
        .nullable(),
    }),
    mise_encure_controle_visuel: yup.boolean().when("step", {
      is: 3,
      then: yup.boolean().label("Le contrôle visuel").default(false).nullable(),
    }),
    mise_encure_observations: yup
      .string()
      .when("step", {
        is: 3,
        then: yup.string().label("L'observation").nullable(),
      })
      .nullable(),
    sortie_cure_date: yup
      .string()
      .when("step", {
        is: 3,
        then: yup.string().label("La date").nullable(),
      })
      .nullable(),
    sortie_cure_poids_echantillon_temoin: yup
      .number()
      .when("step", {
        is: 3,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable()
          .label("Le poids échantillon"),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value)),
    sortie_cure_controle_olfactif: yup
      .boolean()
      .when("step", {
        is: 3,
        then: yup
          .boolean()
          .nullable()
          .label("Le contrôle olfactif")
          .default(false),
      })
      .nullable(),
    sortie_cure_controle_visuel: yup
      .boolean()
      .when("step", {
        is: 3,
        then: yup
          .boolean()
          .label("Le contrôle visuel")
          .default(false)
          .nullable(),
      })
      .nullable(),
    sortie_cure_ph: yup
      .number()
      .when("step", {
        is: 3,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable()
          .label("Le ph"),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value)),
    sortie_cure_nombre_produits_crees: yup
      .number()
      .when("step", {
        is: 3,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable()
          .label("Le nombre de produits créé"),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value)),
    sortie_cure_nombre_produits_conformes: yup
      .number()
      .when("step", {
        is: 3,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable()
          .label("Le nombre de produits conformes"),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value)),
    sortie_cure_nombre_produits_nonconformes: yup
      .number()
      .when("step", {
        is: 3,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable()
          .label("Le nombre de produits non conformes"),
      })
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value)),
    sortie_cure_observations: yup
      .string()
      .when("step", {
        is: 3,
        then: yup.string().label("Observations").nullable(),
      })
      .nullable(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    unregister,
  } = useForm<FabricationFormData>({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const itemState = useLocationState<Recette | undefined>(undefined);

  const [lines, setLines] = useState<any>([]);
  const [dataForm, setDataForm] = useState<any>();
  const [level, setLevel] = useState(0);
  const [produit, setProduit] = useState({
    type: "",
    recette_id: "",
  });
  const [recette, setRecette] = useState<Recette | undefined>();
  const { userInfos } = useAppSelector((s) => s?.userInfos);
  const [getData, { data: recettes }] = useLazyGetRecetteListByUserQuery();
  const [sendData, { isLoading, isSuccess, error, isError, data }] =
    useAddOrUpdateFabricationMutation();

  useEffect(() => {
    let produitCopy = { ...produit };
    if (itemState?.recette_id && itemState?.nom_recette) {
      // console.log(first)
      setRecette(itemState);
      setValue("recette_id", itemState?.recette_id);
      setValue("type_produit", itemState?.type_recette);
      produitCopy.type = itemState?.type_recette;
      produitCopy.recette_id = itemState?.recette_id;
    }
    setProduit(produitCopy);
  }, [itemState]);
  useEffect(() => {
    if (produit?.type) {
      getData({ id: userInfos?.user_id, type: produit?.type });
    }
  }, [produit?.type]);

  useEffect(() => {
    if (recette) {
      let linesCopy: any = [...recette?.recette_matiere_premieres];
      setValue("fab_surgras", recette?.surgras);
      // console.log(fabrication?.recette_fabrications);
      if (
        fabrication &&
        fabrication?.recette_fabrications?.length &&
        linesCopy?.length
      ) {
        linesCopy?.forEach((item, i) => {
          let pesee = fabrication?.recette_fabrications[i]?.pesee_reelle;

          linesCopy[i] = {
            ...linesCopy[i],
            pesee_reelle: pesee,
          };
          linesCopy[i] = {
            ...linesCopy[i],
            fav_unite_pods: fabrication?.recette_fabrications[i]?.unite_poids,
          };
        });
      } else {
        recette?.recette_matiere_premieres?.forEach((item, i) => {
          linesCopy[i] = {
            ...linesCopy[i],
            fav_unite_pods: item?.unite_poids,
          };
        });
      }
      // console.log(linesCopy)
      setLines(linesCopy);
    }
  }, [recette, fabrication]);

  useEffect(() => {
    // console.log("recette", lines);
  }, [lines]);
  useEffect(() => {
    if (isSuccess) {
      if (data?.success) {
        Swal.fire({
          icon: "success",
          iconColor: Color.success,
          confirmButtonColor: Color.success,
          title: data?.message,
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          if (userInfos?.status === "fabricant") {
            navigate(`/fabriquant/fabrication`);
          } else {
            navigate("/admin/fabrication");
          }
        });
      } else {
        // setError("password", { message: data?.message });
        Swal.fire({
          icon: "error",
          title: data?.message,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    // console.log("error", errors);
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const HandleSelectName = (e, i) => {
    let linesCopy = [...lines];
    if (e?.target?.name === "nom") {
      let item: RecetteItem | undefined =
        recette?.recette_matiere_premieres?.find(
          (el) => el?.recette_matiere_premiere_id === e?.target?.value
        );
      console.log("item", item);

      if (item) {
        linesCopy[i] = item;
      }
    } else if (e.target.name === "pesee_reelle") {
      linesCopy[i] = { ...linesCopy[i], pesee_reelle: e?.target?.value };
      setValue(
        `recette_fabrications.${i}.pesee_reelle`,
        parseFloat(e?.target?.value)
      );
      console.log("line copy", linesCopy);
    } else if (e.target.name === "unite_poids") {
      linesCopy[i] = { ...linesCopy[i], fav_unite_pods: e?.target?.value };
      setValue(`recette_fabrications.${i}.unite_poids`, e?.target?.value);
    }
    console.log("lines", linesCopy);
    setLines(linesCopy);
  };

  const handleChange = (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    let produitCopy = { ...produit };
    if (e.target.name === "type_produit") {
      setValue("type_produit", e.target.value);
      produitCopy.type = e.target.value;
    }

    if (e.target.name === "recette_id") {
      setValue("recette_id", e.target.value);
      produitCopy.recette_id = e.target.value;
      let item = recettes?.data?.find(
        (el) => el?.recette_id === e?.target?.value
      );
      console.log("item", item);
      if (item) {
        setRecette(item);
      }
    }
    setProduit(produitCopy);
  };

  const onSubmit = async (data: FabricationFormData) => {
    if (!fabrication) {
      data["fabricant_id"] = userInfos?.user_id;
    }
    setDataForm(data);
    if (data?.step === 0) {
      setLevel(data?.step + 1);
      // console.log("data step 0", data);
    } else if (data?.step === 1) {
      setLevel(data?.step + 1);
      // console.log("data step 1", data);
    } else if (data?.step === 2 && data?.type_produit !== "cosmetique") {
      setLevel(data?.step + 1);
      // console.log("data step 2", data);
    } else {
      if (data?.suivi_echantillon_temoins?.length) {
        data["suivi_echantillon_temoins"] =
          data?.suivi_echantillon_temoins?.filter((el) =>
            Object.entries(el).some(([key]: any) => el[key])
          );
      }

      data.fab_additif_ajoute_dans = JSON.stringify(
        data?.fab_additif_ajoute_dans
      );
      if (!data?.fab_temperature_corps_gras) {
        data.fab_temperature_corps_gras = "";
      }
      if (!data?.fab_temperature_soude) {
        data.fab_temperature_soude = "";
      }
      if (!data?.env_temperature_labo) {
        data.env_temperature_labo = "";
      }

      if (!data?.env_hygrometrie_labo) {
        data.env_hygrometrie_labo = "";
      }
      console.log("data to send", data);
      await sendData({ fabrication_id: data?.fabrication_id, data: data });
    }
  };

  useEffect(() => {
    if (fabrication?.fabrication_id) {
      const fields: (keyof FabricationFormData)[] = [
        "fabrication_id",
        "recette_id",
        "recette_fabrications",
        "suivi_echantillon_temoins",
        "numero_fabrication",
        "type_produit",
        "code_interne",
        "numero_lot",
        "date_fabrication",
        "dluo_ddm_pf",
        "env_temperature_labo",
        "env_hygrometrie_labo",
        "env_autre",
        "fab_coefficient_multiplicateur",
        "fab_moules_utilises",
        "fab_materiel_utilise",
        "fab_acocher",
        "fab_acocher_concentration",
        "fab_temperature_corps_gras",
        "fab_temperature_soude",
        "fab_additif_ajoute_dans",
        "fab_surgras",
        "temps_cure_prevu",
        "temps_cure_restant",
        "mise_encure_date",
        "mise_encure_poids_lot",
        "mise_encure_controle_olfactif",
        "mise_encure_controle_visuel",
        "mise_encure_observations",
        "sortie_cure_date",
        "sortie_cure_poids_echantillon_temoin",
        "sortie_cure_controle_olfactif",
        "sortie_cure_controle_visuel",
        "sortie_cure_ph",
        "sortie_cure_nombre_produits_crees",
        "sortie_cure_nombre_produits_conformes",
        "sortie_cure_nombre_produits_nonconformes",
        "sortie_cure_observations",
        "fabricant_id",
      ];
      for (let field of fields) {
        if (
          field !== "recette_fabrications" &&
          field !== "suivi_echantillon_temoins" &&
          field !== "fab_additif_ajoute_dans"
        ) {
          register(field);
          if (
            field === "date_fabrication" ||
            field === "dluo_ddm_pf" ||
            field === "sortie_cure_date" ||
            field === "mise_encure_date"
          ) {
            if (fabrication[field]) {
              setValue(
                field,
                moment(fabrication[field], "DD-MM-YYYY").format("YYYY-MM-DD")
              );
            }
          } else {
            setValue(field, fabrication[field]);
          }
        } else {
          if (field === "fab_additif_ajoute_dans" && fabrication[field]) {
            setValue("fab_additif_ajoute_dans", fabrication[field]);
          } else {
            setValue(field, fabrication[field]);
          }
        }
      }
      setProduit({
        type: fabrication?.type_produit,
        recette_id: fabrication?.recette_id,
      });
      setRecette(fabrication?.recette);
    }
  }, [fabrication]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    produit,
    unregister,
    handleChange,
    HandleSelectName,
    recettes: recettes?.data,
    lines,
    level,
    dataForm,
    recette,
  };
}

export default UseCrudFabricationForm;

export function UseDeleteFabricationItem(id: string) {
  const [deleteData] = useDeleteFabricationMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette fabrication ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(id);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        console.log(result);
        Swal.fire({
          icon: "success",
          title: `Fabrication supprimée avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
