import React, { useState } from "react";
import "./Login.css";
import { Link } from "react-router-dom";
import Logo from "../../assets/appImages/logo.png";
import { NavLink } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import UseLoginForm from "./requestForm/UseLoginForm";
import ErrorMessage from "../common/ErrorMessage";
import moment from "moment";
import VersionChecker from "../../versionChecker/VersionChecker";

const Login = () => {
  const { register, errors, onSubmit, isLoading } = UseLoginForm();
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div>
      <div className="container-page-login-app">
        <div className="auth-row">
          <div className="auth-col auth-right-side-col">
            <div className="auth-right-side-container">
              <div className="auth-form-container text-center pb-4">
                <div className="content-logo-auth pb-2">
                  <img src={Logo} alt="logo" className="logo-auth-page" />
                </div>
                <h2 className="title-auth-form-text pt-4">Connexion</h2>
                <div className="content-text-message-login text-center">
                  <span className="auth-form-message">
                    Vous n’avez pas de compte?
                  </span>
                  <NavLink
                    to="/inscription"
                    className="btn-text-message-login ps-2"
                  >
                    Inscrivez-vous
                  </NavLink>
                </div>
                <div className="mt-5">
                  <form id="auth-form" onSubmit={onSubmit}>
                    <div className="row auth-form-row">
                      <div className="col-md-12 auth-input-col mb-3">
                        <div className="auth-form-group">
                          <label className="form-label form-label-auth-login">
                            Adresse mail
                          </label>
                          <input
                            type={`email`}
                            className="form-control auth-form-control"
                            id="email"
                            {...register("email")}
                            placeholder="Adresse mail"
                            aria-label="Username"
                            data-testid="emailId"
                          />
                          <ErrorMessage message={errors?.email?.message} />
                        </div>
                      </div>
                      <div className="col-md-12 auth-input-col mb-2">
                        <div className="auth-form-group position-relative">
                          <label className="form-label form-label-auth-login">
                            Mot de passe
                          </label>
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control auth-form-control"
                            id="password"
                            {...register("password")}
                            placeholder="Mot de passe"
                            aria-label="Password"
                            data-testid="passwordId"
                          />
                          <span
                            className="show-hide-password"
                            onClick={(e) => {
                              setShowPassword(!showPassword);
                            }}
                          >
                            {showPassword ? (
                              <AiOutlineEyeInvisible />
                            ) : (
                              <AiOutlineEye />
                            )}
                          </span>
                        </div>
                        <ErrorMessage message={errors?.password?.message} />
                      </div>
                      <div className="content-forget-password-link mb-3">
                        <Link
                          to={"/mot-de-passe-oublie"}
                          className="forget-password-link"
                        >
                          Mot de passe oublié ?
                        </Link>
                      </div>
                      <div className="col-md-12 auth-submit-btn-container mt-2">
                        <button
                          type="submit"
                          disabled={isLoading}
                          data-testid="submitBtnId"
                          className="btn auth-submit-btn trans-0-2"
                        >
                          {isLoading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                                role="status"
                              ></span>
                              <span>Connexion...</span>
                            </>
                          ) : (
                            "Connexion"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <p className="text-footer-auth d-flex justify-content-center">
            ©{moment(new Date()).format("YYYY")} All Rights Reserved. LOGISAOP{" "}
            &nbsp;
            <div className="version-view-container">
              {" "}
              {"v" + VersionChecker()}
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
