import React from "react";
import "./HeaderAdmin.css";
import { NavLink } from "react-router-dom";
import avatar from "../../../../assets/appImages/image.png";
import SidebarMobile from "../SidebarAdmin/SidebarMobile";
import { IoNotificationsOutline } from "react-icons/io5";
import { FiSearch } from "react-icons/fi";
import Logo from "../../../../assets/appImages/logo.png";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { onlogout } from "../../../../redux/slice/User.slice";
import { getAvatar } from "../../../../utils/utils";

const HeaderAdmin: React.FC = () => {
  const [word, setWord] = React.useState<string>("");
  const { userInfos } = useAppSelector((s) => s?.userInfos);
  var [showNotifications, setShowNotifications] = React.useState(false);

  var onShowAndHideNotification = () => {
    if (showNotifications === false) {
      setShowNotifications(true);
    } else {
      setShowNotifications(false);
    }
  };

  const dispatch = useAppDispatch();
  const handleLogout = () => {
    dispatch(onlogout());
    window.location.pathname = "/";
  };

  return (
    <div className="admin-header-component">
      <nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark fixed-top">
        <div className="headering-admin">
          <div className="container-header-left">
            <div className="logo-header-container">
              <img src={Logo} alt="Logo" className="logo-header-admin" />
            </div>
            <div className="search-form-container">
              <div className="search-form-content">
                {/* <label>
                  <FiSearch />
                </label>
                <input
                  name="search"
                  className="form-control search-input"
                  placeholder="Rechercher"
                  value={word}
                  onChange={(e) => setWord(e.target.value)}
                /> */}
              </div>
            </div>
          </div>

          <div className="header-others-items">
            <div className="container-menu-header-admin gap-3">
              {/* <div
                className="notification-item-container linkable position-relative"
                onClick={() => onShowAndHideNotification()}
              >
                <div className="notification-item-content">
                  <IoNotificationsOutline className="notif-icon" />
                  <span className="notification-counter"></span>
                </div>
              </div> */}
            </div>
            <div className="connected-user-container">
              <div className="connected-user-content ml-auto">
                <div className="dropdown dis-flex">
                  <button
                    className="user-acount-dashboard-header dropdown-toggle btn"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="user-acount-dashboard-header-display g-2">
                      <img
                        src={getAvatar(userInfos?.avatar)}
                        className="user-avatar"
                        alt="User Avatar"
                      />
                      {userInfos?.first_name + " " + userInfos?.last_name}
                    </div>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-custom"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <NavLink
                      to={
                        userInfos?.status === "fabricant"
                          ? "/fabriquant/profil"
                          : "/admin/profil"
                      }
                      className="dropdown-item"
                      style={{ fontSize: 16 }}
                    >
                      {userInfos?.first_name + " " + userInfos?.last_name}
                    </NavLink>
                    <div className="dropdown-divider"></div>
                    <button
                      type="button"
                      className="dropdown-item"
                      style={{ fontSize: 16 }}
                      onClick={() => handleLogout()}
                    >
                      Déconnexion
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="hiddenable-nav-mobile-container no-view-desktop">
              <div className="hiddenable-nav-mobile-content">
                <div className="container-nav-mobile">
                  <SidebarMobile />
                </div>
              </div>
            </div>
          </div>
        </div>

        {showNotifications && (
          <div className={"header-hiddenable-notification-section"}>
            <ul className="header-hiddenable-notification-ul">
              <li className="header-hiddenable-notification-li">
                Il n'y a aucune notification .
              </li>
            </ul>
          </div>
        )}
      </nav>
    </div>
  );
};

export default HeaderAdmin;
