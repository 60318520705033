import React from "react";
import { useRecetteFromLocation } from "../../../utils/api/recette/recette.api";
import BootstrapTable from "react-bootstrap-table-next";
import { RecetteItem } from "../../../utils/api/recette/recette.type";
import { getTotalWeight } from "../../../utils/utils";

const DetailRecetteFabrication = () => {
  const [item] = useRecetteFromLocation();

  const columns = [
    {
      dataField: "categorie",
      text: "Catégorie",
      // formatter: (cell) => dateFormatter(cell),
    },
    {
      dataField: "nom",
      text: "Nom MP",
      formatter: (cell,row:RecetteItem) => row?.ligne_commande?.nom || cell,
    },
    {
      dataField: "poids",
      text: "Poids",
      formatter: (cell, row: RecetteItem) => cell + row?.unite_poids,
    },
    {
      dataField: "pourcentage",
      text: "Pourcentage",
      formatter: (cell) => cell + "%",
    },
    {
      dataField: "cout",
      text: "Cout MP",
      formatter: (cell) => cell + "€",
    },
    {
      dataField: "cout_total",
      text: "Total",
      formatter: (cell) => cell + "€",
    },
  ];
  return (
    <div className="fournisseur-list-component">
      <div className="container-title-page-admin mb-5">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">
            Détail recette{" "}
            <span className="ps-2 nom-de-la-recette">{item?.nom_recette}</span>
          </h3>
        </div>
      </div>
      <div className="container-detail-recette pb-5">
        <div className="content-detail-recette-item">
          <p className="title-detail-recette-fabrication">
            {item?.nom_recette}
          </p>
          <div className="info-detail-recette-container pt-4 row">
            <div className="col-md-12">
              <h3 className="title-form-add py-4">Infos Fabrication</h3>
            </div>
            <div className="info-detail-recette-item mb-3 col-md-4">
              <p className="text-libelle-info-detail-recette">Code interne: </p>
              <p className="text-value-info-detail-recette">
                {item?.code_interne_recette}
              </p>
            </div>
            <div className="info-detail-recette-item mb-3 col-md-4">
              <p className="text-libelle-info-detail-recette">Poids :</p>
              <p className="text-value-info-detail-recette">{getTotalWeight(item?.recette_matiere_premieres)}</p>
            </div>

            <div className="info-detail-recette-item mb-3 col-md-4">
              <p className="text-libelle-info-detail-recette">Recette de: </p>
              <p className="text-value-info-detail-recette">
                {item?.type_recette}
              </p>
            </div>

            <div className="info-detail-recette-item mb-3 col-md-4">
              <p className="text-libelle-info-detail-recette">Surgras: </p>
              <p className="text-value-info-detail-recette">{item?.surgras}%</p>
            </div>
            <div className="info-detail-recette-item mb-3 col-md-4">
              <p className="text-libelle-info-detail-recette">Temps cure: </p>
              <p className="text-value-info-detail-recette">
                {item?.temps_cure} semaines
              </p>
            </div>

            <div className="info-detail-recette-item mb-3 col-md-4">
              <p className="text-libelle-info-detail-recette">
                Poids total initial:{" "}
              </p>
              <p className="text-value-info-detail-recette">
                {item?.poids_total_initial}kg
              </p>
            </div>

            <div className="info-detail-recette-item mb-3 col-md-4">
              <p className="text-libelle-info-detail-recette">
                Perte prévue durant la fabrication:{" "}
              </p>
              <p className="text-value-info-detail-recette">
                {item?.perte_prevue_durant_fabrication}%
              </p>
            </div>

            <div className="info-detail-recette-item mb-3 col-md-4">
              <p className="text-libelle-info-detail-recette">
                Perte prévue durant la cure:{" "}
              </p>
              <p className="text-value-info-detail-recette">
                {item?.perte_prevue_durant_cure}%
              </p>
            </div>

            <div className="info-detail-recette-item mb-3 col-md-4">
              <p className="text-libelle-info-detail-recette">
                Poids prévu en fin de cure:{" "}
              </p>
              <p className="text-value-info-detail-recette">
                {item?.poids_prevu_fin_cure}kg
              </p>
            </div>

            <div className="col-md-12">
              <h3 className="title-form-add py-4">Coût Fabrication</h3>
            </div>
            <div className="info-detail-recette-item mb-3 col-md-4">
              <p className="text-libelle-info-detail-recette">
                Coût main d’œuvre:{" "}
              </p>
              <p className="text-value-info-detail-recette">
                {item?.cout_main_doeuvre}€
              </p>
            </div>
            <div className="info-detail-recette-item mb-3 col-md-4">
              <p className="text-libelle-info-detail-recette">
                Temps de fabrication en heure:{" "}
              </p>
              <p className="text-value-info-detail-recette">
                {item?.temps_fabrication}H
              </p>
            </div>

            <div className="info-detail-recette-item mb-3 col-md-4">
              <p className="text-libelle-info-detail-recette">
                Coût fabrication total:{" "}
              </p>
              <p className="text-value-info-detail-recette">
                {item?.cout_fabrication_total}€
              </p>
            </div>

            <div className="info-detail-recette-item mb-3 col-md-4">
              <p className="text-libelle-info-detail-recette">
                Coût emballage unitaire:{" "}
              </p>
              <p className="text-value-info-detail-recette">
                {item?.cout_emballage_unitaire}€
              </p>
            </div>

            <div className="info-detail-recette-item mb-3 col-md-4">
              <p className="text-libelle-info-detail-recette">
                Nombre d’emballage:{" "}
              </p>
              <p className="text-value-info-detail-recette">
                {item?.nbre_emballage}
              </p>
            </div>

            <div className="info-detail-recette-item mb-3 col-md-4">
              <p className="text-libelle-info-detail-recette">
                Total coût emballage:{" "}
              </p>
              <p className="text-value-info-detail-recette">
                {item?.cout_total_emballage}€
              </p>
            </div>

            <div className="info-detail-recette-item mb-3 col-md-4">
              <p className="text-libelle-info-detail-recette">
                Nombre d’unité fabriqué:{" "}
              </p>
              <p className="text-value-info-detail-recette">
                {item?.nbre_unite_fabrique}€
              </p>
            </div>

            <div className="info-detail-recette-item mb-3 col-md-4">
              <p className="text-libelle-info-detail-recette">
                Coût par unité:{" "}
              </p>
              <p className="text-value-info-detail-recette">
                {item?.type_cout_par_unite === "cout_derniere_comme"
                  ? "Coût dernière commande"
                  : "Coût moyen"}
              </p>
            </div>

            <div className="info-detail-recette-item mb-3 col-md-4">
              <p className="text-libelle-info-detail-recette">
                Coût sans emballage:{" "}
              </p>
              <p className="text-value-info-detail-recette">
                {item?.cout_sans_emballage}€
              </p>
            </div>

            <div className="info-detail-recette-item mb-3 col-md-4">
              <p className="text-libelle-info-detail-recette">
                Coût avec emballage:{" "}
              </p>
              <p className="text-value-info-detail-recette">
                {item?.cout_avec_emballage}€
              </p>
            </div>

            <div className="col-md-12">
              <h3 className="title-form-add py-4">Quantite MP</h3>
            </div>
            <div className="col-md-12">
              <BootstrapTable
                keyField="recette_matiere_premiere_id"
                data={item?.recette_matiere_premieres}
                columns={columns}
                bordered={true}
                condensed={false}
                responsive={true}
                wrapperClasses="table-responsive admin-table"
                noDataIndication={() => "Aucune donnée trouvée"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailRecetteFabrication;
