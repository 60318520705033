import React, { useEffect, useState } from "react";
import { AlertInfo } from "../../../../common/Alert";
import ErrorMessage from "../../../../common/ErrorMessage";
import { PropsTypeFabric } from "../AddNewFabrication";

const RecetteTabs = ({
  navigation,
  register,
  errors,
  recette,
  HandleSelectName,
  lines,
  unregister,
  setValue,
  dataForm,
  produit,
  isLoading
}: PropsTypeFabric) => {
  // const navigate = useNavigate();
  const [index, setIndex] = useState(0);

  // console.log("recette tabs", lines);
  useEffect(() => {
    if (recette?.recette_matiere_premieres?.length) {
      recette?.recette_matiere_premieres?.forEach((item, i) => {
        register(`recette_fabrications.${i}.recette_matiere_premiere_id`);
        register(`recette_fabrications.${i}.unite_poids`);
        register(`recette_fabrications.${i}.pesee_reelle`);
        setValue(
          `recette_fabrications.${i}.recette_matiere_premiere_id`,
          item?.recette_matiere_premiere_id
        );
        setValue(`recette_fabrications.${i}.unite_poids`, item?.unite_poids);
      });
    }
  }, [recette]);
  let recettes: any = recette?.recette_matiere_premieres;
  let arr: any = [];
  if (index === 0) {
    arr = recette?.recette_matiere_premieres?.filter(
      (item) => item?.categorie === "Corps gras"
    );
  } else if (index === 1) {
    arr = recette?.recette_matiere_premieres?.filter(
      (item) => item?.categorie === "Soude"
    );
  } else if (index === 2) {
    arr = recette?.recette_matiere_premieres?.filter(
      (item) => item?.categorie === "Additif"
    );
  } else if (index === 3) {
    arr = recette?.recette_matiere_premieres?.filter(
      (item) => item?.categorie === "Liquide"
    );
  } else if (index === 4) {
    arr = recette?.recette_matiere_premieres?.filter(
      (item) => item?.categorie === "Autre"
    );
  }

  useEffect(() => {
    if (index && !recettes?.length) {
      // console.log("here");
      unregister(`recette_fabrications.${index}.recette_matiere_premiere_id`);
      unregister(`recette_fabrications.${index}.unite_poids`);
      unregister(`recette_fabrications.${index}.pesee_reelle`);
    }
  }, [index]);
  return (
    <div className="quantite-tabs-component">
      <div className="conatiner-quantite-recette mb-4">
        <div className="container-filter-recette-table mb-4">
          <div className="container-filter-recette-table-item">
            <button
              className={`btn btn-filter-recette ${
                index === 0 ? "active" : ""
              }`}
              onClick={() => setIndex(0)}
              type="button"
            >
              Corps gras
            </button>
          </div>
          <div className="container-filter-recette-table-item">
            <button
              className={`btn btn-filter-recette ${
                index === 1 ? "active" : ""
              }`}
              onClick={() => setIndex(1)}
              type="button"
            >
              Soude
            </button>
          </div>
          <div className="container-filter-recette-table-item">
            <button
              className={`btn btn-filter-recette ${
                index === 2 ? "active" : ""
              }`}
              onClick={() => setIndex(2)}
              type="button"
            >
              Additifs
            </button>
          </div>
          <div className="container-filter-recette-table-item">
            <button
              className={`btn btn-filter-recette ${
                index === 3 ? "active" : ""
              }`}
              onClick={() => setIndex(3)}
              type="button"
            >
              Liquide
            </button>
          </div>
          <div className="container-filter-recette-table-item">
            <button
              className={`btn btn-filter-recette ${
                index === 4 ? "active" : ""
              }`}
              onClick={() => setIndex(4)}
              type="button"
            >
              Autres
            </button>
          </div>
        </div>
        <>
          {recettes?.map((item, i) => (
            <div
              className="container-table-add-recette"
              key={item?.recette_matiere_premiere_id}
              style={
                (index === 0 && item?.categorie !== "Corps gras") ||
                (index === 1 && item?.categorie !== "Soude") ||
                (index === 2 && item?.categorie !== "Additif") ||
                (index === 3 && item?.categorie !== "Liquide") ||
                (index === 4 && item?.categorie !== "Autre")
                  ? { display: "none" }
                  : {}
              }
            >
              <div className="line-add-recette-item w-15">
                <label htmlFor="" className="form-label form-label-add-recette">
                  Nom
                </label>
                <input
                  type="text"
                  className="form-control form-control-add-recette"
                  placeholder="-"
                  readOnly
                  value={item?.ligne_commande?.nom || ""}
                />

                <ErrorMessage
                  message={
                    errors?.recette_fabrications &&
                    errors?.recette_fabrications[i]
                      ?.recette_matiere_premiere_id &&
                    errors?.recette_fabrications[i]?.recette_matiere_premiere_id
                      ?.message
                  }
                />
              </div>
              <div className="line-add-recette-item">
                <label htmlFor="" className="form-label form-label-add-recette">
                  Numéro de lot
                </label>
                <input
                  type="text"
                  className="form-control form-control-add-recette"
                  placeholder="-"
                  value={
                    lines?.length ? lines[i]?.ligne_commande?.numero_lot : ""
                  }
                  readOnly
                />
              </div>
              <div className="line-add-recette-item">
                <label htmlFor="" className="form-label form-label-add-recette">
                  Fournissseur
                </label>
                <input
                  type="text"
                  className="form-control form-control-add-recette"
                  placeholder="-"
                  value={
                    lines?.length
                      ? lines[i]?.ligne_commande?.details_commande?.commande
                          ?.fournisseur?.fullname
                      : ""
                  }
                />
              </div>
              <div className="line-add-recette-item">
                <label htmlFor="" className="form-label form-label-add-recette">
                  Date d’ouverture
                </label>
                <input
                  type="text"
                  className="form-control form-control-add-recette"
                  placeholder="-"
                  readOnly
                  value={`${
                    lines?.length
                      ? lines[i]?.ligne_commande?.date_ouverture || ""
                      : ""
                  }`}
                />
              </div>
              <div className="line-add-recette-item">
                <label htmlFor="" className="form-label form-label-add-recette">
                  % dans la recette
                </label>
                <input
                  type="text"
                  className="form-control form-control-add-recette"
                  placeholder="-"
                  readOnly
                  value={`${lines?.length ? lines[i]?.pourcentage : ""}`}
                />
              </div>
              <div className="line-add-recette-item">
                <label htmlFor="" className="form-label form-label-add-recette">
                  Poids théorique
                </label>
                <input
                  type="text"
                  className="form-control form-control-add-recette"
                  placeholder="-"
                  readOnly
                  value={`${
                    lines?.length
                      ? parseFloat(lines[i]?.poids) *
                        parseInt(dataForm?.fab_coefficient_multiplicateur)
                      : ""
                  }`}
                />
              </div>
              <div className="line-add-recette-item">
                <label htmlFor="" className="form-label form-label-add-recette">
                  Pesée réelle <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  min={0}
                  step={"any"}
                  className="form-control form-control-add-recette"
                  placeholder="-"
                  // {...register(`recette_fabrications.${index}.pesee_reelle`)}
                  name="pesee_reelle"
                  onChange={(e) => HandleSelectName(e, i)}
                  value={lines?.length ? lines[i]?.pesee_reelle : ""}
                  // value={`${
                  //   lines?.length ? lines[index]?.data[i]?.pesee_reelle : ""
                  // }`}
                />
                {/* <ErrorMessage
                  message={
                    errors?.recette_fabrications &&
                    errors?.recette_fabrications[i]?.pesee_reelle &&
                    errors?.recette_fabrications[i]?.pesee_reelle?.message
                  }
                /> */}
              </div>
              <div className="line-add-recette-item w-15">
                <label htmlFor="" className="form-label form-label-add-recette">
                  Unité de poids <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control form-control-add-recette"
                  name="unite_poids"
                  onChange={(e) => HandleSelectName(e, i)}
                  value={`${lines?.length ? lines[i]?.fav_unite_pods : ""}`}
                >
                  <option value="">Unité</option>
                  <option value="g">g</option>
                  <option value="kg">kg</option>
                </select>
                {/* <ErrorMessage
                  message={
                    errors?.recette_fabrications &&
                    errors?.recette_fabrications[i]?.unite_poids &&
                    errors?.recette_fabrications[i]?.unite_poids?.message
                  }
                /> */}
              </div>
            </div>
          ))}
        </>
        {!arr?.length && (
          <AlertInfo
            message={"Aucune Ligne disponible pour cette catégorie!"}
          />
        )}
        <div className="container-table-add-recette-total pb-4">
          {errors?.recette_fabrications?.length && (
            <ErrorMessage
              message={
                "Veuillez renseigner la pesée réelle et l'unité du poids sur tous les lignes disponibles"
              }
            />
          )}
        </div>
        {/* <div className="container-table-add-recette-total pb-4">
          <div className="line-add-recette-item-total">
            <div
              className="form-control-add-recette"
              style={{ color: "#667085", fontWeight: 700 }}
            >
              Total
            </div>
          </div>
          <div className="line-add-recette-item-total">
            <div
              className="form-control-add-recette"
              style={{ color: "#50B2D4", fontWeight: 700 }}
            >
              3
            </div>
          </div>
          <div className="line-add-recette-item-total">
            <div
              className="form-control-add-recette"
              style={{ color: "#50B2D4", fontWeight: 700 }}
            >
              3
            </div>
          </div>
          <div className="line-add-recette-item-total">
            <div
              className="form-control-add-recette"
              style={{ color: "#50B2D4", fontWeight: 700 }}
            >
              3
            </div>
          </div>
          <div className="line-add-recette-item-total">
            <div
              className="form-control-add-recette"
              style={{ color: "#50B2D4", fontWeight: 700 }}
            >
              3
            </div>
          </div>
          <div className="line-add-recette-item-total">
            <div
              className="form-control-add-recette"
              style={{ color: "#50B2D4", fontWeight: 700 }}
            >
              3
            </div>
          </div>
          <div className="line-add-recette-item-total">
            <div
              className="form-control-add-recette"
              style={{ color: "#50B2D4", fontWeight: 700 }}
            >
              3
            </div>
          </div>
          <div className="line-add-recette-item-total">
            <div
              className="form-control-add-recette"
              style={{ color: "#50B2D4", fontWeight: 700 }}
            >
              3
            </div>
          </div>
        </div> */}
        {/* <div className="container-btn-add-newrecette-table pb-4">
          <button
            className="btn btn-add-new-recette-table"
            onClick={(e: any) => {
              e.preventDefault();
              addFields();
            }}
          >
            <AiOutlinePlus />
          </button>
        </div> */}
        <div className="flex-r gap-4 align-items-center">
          <button
            onClick={() => navigation.previous()}
            className="btn btn-cancelled-recette trans-0-2"
            type="button"
          >
            Retour
          </button>
          <button
            // onClick={(e) => handleSubmit(e)}
            type="submit"
            className="btn auth-submit-btn-recette trans-0-2"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                  role="status"
                ></span>
                <span>Enregistrer...</span>
              </>
            ) : produit?.type === "cosmetique" ? (
              "Enregistrer"
            ) : (
              "Suivant"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RecetteTabs;
