import { PhoneNumberUtil } from "google-libphonenumber";
import * as Yup from "yup";
import $ from "jquery";
import { IUser } from "./api/user/user.type";
import { ApiBaseUrl } from "./http";
import Avatar from "../assets/appImages/avatar1.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import moment from "moment";
import { RecetteItem } from "./api/recette/recette.type";

const phoneUtil = PhoneNumberUtil.getInstance();

export function validateNumber(phone?: string, country?: string) {
  if (!phone || !country) return true;
  try {
    const number = phoneUtil.parse(phone, country);
    if (phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number)) {
      return true;
    }
  } catch (ex) {
    console.log("error phone", ex);
  }
  return false;
}

export function validatePhone(yup: typeof Yup, country: string) {
  return yup
    .string()
    .test("passwords-match", "Numéro téléphone invalide", (val) =>
      validateNumber(val, country)
    )
    .nullable();
}

export function cleannerError(
  errors: any,
  cleanner: any,
  timeOut: number = 3000
) {
  if (errors) {
    setTimeout(
      () => Object.entries(errors).map(([key]: any) => cleanner(key)),
      timeOut
    );
  }
}

export const isfabriquant = (user: IUser) => {
  return user?.status === "fabricant";
};

export const isAdmin = (user: IUser) => {
  return user?.status === "admin" || user?.status === "superadmin";
};

export const getAvatar = (avatar: string | any) => {
  if (avatar && !avatar?.includes("default.png")) {
    if (avatar.indexOf("http") === 0) return avatar;
    return ApiBaseUrl + avatar;
  }
  return Avatar;
};

export const onShow = (modalId: string) => {
  document.getElementById(modalId)?.classList.add("show");
  $(`#${modalId}`).show();
};

export const onHide = (modalId: string) => {
  document.getElementById(modalId)?.classList.remove("show");
  $(".modal-backdrop").remove();
  $(`#${modalId}`).hide();
  $(`#${modalId}`).click();
  $(`body`).css({ overflowY: "scroll" });
  $(`#${modalId}`).click();
};

export function QueryUrl(baseUrl: string, additionalSearchQuery: any) {
  if (additionalSearchQuery) {
    for (let key of Object.keys(additionalSearchQuery)) {
      let val = additionalSearchQuery[key];

      if (Array.isArray(val)) {
        if (val.length > 0) {
          const string = val.map((v) => `${key}=${v}`).join("&");
          if (baseUrl.includes("?")) {
            baseUrl += `&${string}`;
          } else {
            baseUrl += `?${string}`;
          }
        }
      } else if (typeof val === "boolean") {
        if (baseUrl.includes("?")) {
          baseUrl += `&${key}=${val}`;
        } else {
          baseUrl += `?${key}=${val}`;
        }
      } else {
        if (val) {
          if (baseUrl.includes("?")) {
            baseUrl += `&${key}=${val}`;
          } else {
            baseUrl += `?${key}=${val}`;
          }
        }
      }
    }
  }
  return baseUrl;
}

export function useLocationState<T>(
  defaultValue: T | any,
  redirectToWhenNull: string | null = null
): T {
  const state = useLocation().state as T;
  const navigate = useNavigate();
  useEffect(() => {
    if (!state && redirectToWhenNull) {
      navigate(redirectToWhenNull);
    }
  }, [state]);
  return state || defaultValue;
}

export const getInitials = (name: string) => {
  var names = name.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const onFormatDate = (date: string | Date) => {
  return moment(date, "DD-MM-YYYY").format("DD/MM/YYYY");
};

export const convertUnit = (value: number, from: "g" | "kg" | string) => {
  if (!value) return 0;
  if (from === "g") {
    return value / 1000;
  }

  return value * 1000;
};

export const getTotalWeight = (arr: RecetteItem[]) => {
  if (!arr?.length) return 0;
  // let isToKg = arr?.some((item) => item?.unite_poids === "kg");
  let allIsG = arr?.every((item) => item?.unite_poids === "g");
  let allIsKg = arr?.every((item) => item?.unite_poids === "kg");
  const sum = arr.reduce((accumulator, item: RecetteItem) => {
    if (allIsG || allIsKg) {
      return accumulator + parseFloat(item?.poids);
    }

    return (
      accumulator +
      (item?.unite_poids !== "kg"
        ? convertUnit(parseFloat(item?.poids), item?.unite_poids)
        : parseFloat(item?.poids))
    );
  }, 0);

  return sum + (allIsG ? " g" : " kg");
};
