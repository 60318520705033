/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { AppStorage } from "../../storage";
import { PaginationResults, TypeQuery } from "../../type";
import { QueryUrl, useLocationState } from "../../utils";
import { ChangePasswordData, LoginResult } from "../auth/auth.type";
import {
  AuthState,
  Dashboard,
  Graphe,
  IUser,
  PermissionResponse,
  RefreshType,
  UserFormData,
} from "./user.type";

export const prepareHeaders = (headers: Headers, { getState }: any) => {
  const token =
    (getState() as { userInfos: LoginResult }).userInfos?.access_token ??
    AppStorage.getItem<AuthState>("userInfos")?.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

export const UserApi = createApi({
  reducerPath: "userApi",
  tagTypes: ["user", "userList", "userByID", "permissions"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/v1/app/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    me: builder.query<IUser, void>({
      providesTags: ["user"],
      transformResponse: ({ data }) => data,
      query: () => {
        return "me/";
      },
    }),
    editUserInfo: builder.mutation<
      { message: string; success: boolean; data: IUser },
      UserFormData
    >({
      invalidatesTags: ["user", "userList"],
      query: ({ user_id, ...data }) => {
        return {
          url: `/update-auth-user-infos/${user_id}/`,
          method: "POST",
          body: data,
        };
      },
    }),
    editUserAvatar: builder.mutation<
      IUser,
      { user_id: string; data: FormData }
    >({
      invalidatesTags: ["user", "userList"],
      query: ({ user_id, data }) => {
        return {
          url: `/update-auth-user-avatar/${user_id}/`,
          method: "POST",
          body: data,
        };
      },
    }),
    changePassword: builder.mutation<any, ChangePasswordData>({
      query: ({ userID, ...data }) => ({
        url: `/update-auth-user-password/${userID}/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["user"],
    }),
    refreshToken: builder.mutation<RefreshType, void>({
      invalidatesTags: ["user"],
      query: () => {
        return {
          url: `/refresh/`,
          method: "GET",
        };
      },
    }),
    getUserList: builder.query<PaginationResults<IUser>, TypeQuery>({
      providesTags: ["userList"],
      query: ({ type, ...query }) => QueryUrl(`all-users/${type}`, query),
    }),
    addOrUpdateUser: builder.mutation<
      { message: string; success: boolean; data: IUser },
      UserFormData
    >({
      invalidatesTags: ["userList"],
      query: ({ user_id, ...data }) => {
        if (user_id) {
          return {
            url: `users/${user_id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `users/`,
          method: "POST",
          body: data,
        };
      },
    }),
    getUserById: builder.query<
      { message: string; success: boolean; data: IUser },
      TypeQuery
    >({
      query: (id) => `users/${id}`,
      providesTags: ["userByID"],
    }),
    deleteUser: builder.mutation<
      { message: string; success: boolean; data: string },
      string
    >({
      query: (user_id) => ({
        url: `users/${user_id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["userList"],
    }),
    getPermissions: builder.query<PermissionResponse, void>({
      providesTags: ["permissions"],
      // transformResponse: ({ data }) => data,
      query: () => {
        return "permissions/";
      },
    }),
    getDashboardByUser: builder.query<Dashboard, TypeQuery>({
      query: ({ id, ...query }) =>
        QueryUrl(`dashboard-data-by-fabricant/${id}/`, query),
    }),
    getDashboardByAdmin: builder.query<
      { data: Graphe },
      { number: number; label: string }
    >({
      query: ({ number, label }) =>
        QueryUrl(`admin-dashboard-data/${number}/${label}`, ""),
    }),
    generePassword: builder.mutation<
      { message: string; success: boolean; data: IUser },
      string
    >({
      query: (user_id) => ({
        url: `regenerate-user-password/${user_id}/`,
        method: "GET",
      }),
      // invalidatesTags: ["userList"],
    }),
    toggleUser: builder.mutation<
      { message: string; success: boolean; data: IUser },
      UserFormData
    >({
      query: ({user_id,...data}) => {
        return {
          url: `/edit-user-banned-status/${user_id}`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["userList"],
    }),
  }),
});

export const {
  useMeQuery,
  useLazyMeQuery,
  useEditUserAvatarMutation,
  useEditUserInfoMutation,
  useChangePasswordMutation,
  useRefreshTokenMutation,
  useGetUserListQuery,
  useAddOrUpdateUserMutation,
  useLazyGetUserByIdQuery,
  useDeleteUserMutation,
  useGetPermissionsQuery,
  useGetDashboardByUserQuery,
  useGetDashboardByAdminQuery,
  useGenerePasswordMutation,
  useToggleUserMutation
} = UserApi;

export function useUserFromLocation(): [
  IUser,
  boolean,
  string,
  (id: string) => any
] {
  const itemState = useLocationState<IUser | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { id } = useParams<{ id: string }>();
  const [findById, { data, isError, isLoading }] = useLazyGetUserByIdQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      findById(id as any);
    }
  }, [itemState, id]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      // console.log("data",data)
      setItem(data?.data);
    }
  }, [data]);

  return [item as IUser, isLoading, id as string, findById as any];
}
