/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { RecetteFormData } from "../../../../../utils/api/recette/recette.type";
import ErrorMessage from "../../../../common/ErrorMessage";

const CoutFabricationTabs = ({
  navigation,
  register,
  errors,
  onChangeFabrication,
  infosFabic,
  isLoading,
}: {
  navigation: any;
  register: UseFormRegister<RecetteFormData>;
  errors: Partial<FieldErrorsImpl<RecetteFormData>>;
  onChangeFabrication: any;
  infosFabic: any;
  isLoading: boolean;
}) => {
  return (
    <div className="quantite-tabs-component">
      <div className="container-form-add">
        <div>
          <div className="container-form-cout-recette">
            <div className="form-add-cout-recette-item">
              <div className="col-form-add-student mb-3">
                <div className="row-addcontent-col-form-add">
                  <label
                    htmlFor="cout_main_doeuvre"
                    className="form-label form-label-add-recette"
                  >
                    Coût main d’œuvre (€)
                  </label>
                  <input
                    className="form-control form-control-add-form"
                    placeholder="Coût main d’œuvre (€)"
                    type="number"
                    step={"any"}
                    min={0}
                    onChange={(e) => onChangeFabrication(e)}
                    value={infosFabic?.cout_main_doeuvre}
                    name="cout_main_doeuvre"
                  />
                  <ErrorMessage
                    message={
                      errors?.cout_main_doeuvre &&
                      errors?.cout_main_doeuvre?.message
                    }
                  />
                </div>
              </div>
              {/* <div className="col-form-add-student mb-3">
                <div className="row-addcontent-col-form-add">
                  <label
                    htmlFor="nbre_unite_fabrique"
                    className="form-label form-label-add-recette"
                  >
                    Nombre d’unité fabriqué
                  </label>
                  <input
                    className="form-control form-control-add-form"
                    placeholder="Nombre d’unité fabriqué"
                    type="number"
                    step={"any"}
                    min={0}
                    onChange={(e) => onChangeFabrication(e)}
                    value={infosFabic?.nbre_unite_fabrique}
                    name="nbre_unite_fabrique"
                  />
                  <ErrorMessage
                    message={
                      errors?.nbre_unite_fabrique &&
                      errors?.nbre_unite_fabrique?.message
                    }
                  />
                </div>
              </div> */}
              <div className="col-form-add-student mb-3">
                <div className="row-addcontent-col-form-add">
                  <label
                    htmlFor="cout_emballage_unitaire"
                    className="form-label form-label-add-recette"
                  >
                    Coût emballage unitaire (€)
                  </label>
                  <input
                    className="form-control form-control-add-form"
                    placeholder="Coût emballage unitaire (€)"
                    type="number"
                    step={"any"}
                    min={0}
                    onChange={(e) => onChangeFabrication(e)}
                    value={infosFabic?.cout_emballage_unitaire}
                    name="cout_emballage_unitaire"
                  />
                  <ErrorMessage
                    message={
                      errors?.cout_emballage_unitaire &&
                      errors?.cout_emballage_unitaire?.message
                    }
                  />
                </div>
              </div>
            </div>
            <div className="form-add-cout-recette-item">
              <div className="col-form-add-student mb-3">
                <div className="row-addcontent-col-form-add">
                  <label
                    htmlFor="temps_fabrication"
                    className="form-label form-label-add-recette"
                  >
                    Temps de fabrication en heure
                  </label>
                  <input
                    className="form-control form-control-add-form"
                    placeholder="Temps de fabrication en heure "
                    type="number"
                    step={"any"}
                    min={0}
                    onChange={(e) => onChangeFabrication(e)}
                    value={infosFabic?.temps_fabrication}
                    name="temps_fabrication"
                  />
                  <ErrorMessage
                    message={
                      errors?.temps_fabrication &&
                      errors?.temps_fabrication?.message
                    }
                  />
                </div>
              </div>

              <div className="col-form-add-student mb-3">
                <div className="row-addcontent-col-form-add">
                  <label
                    htmlFor="nbre_emballage"
                    className="form-label form-label-add-recette"
                  >
                    Nombre d’emballage
                  </label>
                  <input
                    className="form-control form-control-add-form"
                    placeholder="Nombre d’emballage "
                    type="number"
                    step={"any"}
                    min={0}
                    onChange={(e) => onChangeFabrication(e)}
                    value={infosFabic?.nbre_emballage}
                    name="nbre_emballage"
                  />
                  <ErrorMessage
                    message={
                      errors?.nbre_emballage && errors?.nbre_emballage?.message
                    }
                  />
                </div>
              </div>
              {/* <div className="col-form-add-student mb-3">
                <div className="row-addcontent-col-form-add">
                  <label
                    htmlFor="cout_total_emballage"
                    className="form-label form-label-add-recette"
                  >
                    Total coût emballage (€)
                  </label>
                  <input
                    className="form-control form-control-add-form"
                    placeholder="Total coût emballage (€)"
                    type="number"
                    step={"any"}
                    min={0}
                    readOnly
                    onChange={(e) => onChangeFabrication(e)}
                    value={infosFabic?.cout_total_emballage}
                    name="cout_total_emballage"
                  />
                  <ErrorMessage
                    message={
                      errors?.cout_total_emballage &&
                      errors?.cout_total_emballage?.message
                    }
                  />
                </div>
              </div> */}
              <div className="col-form-add-student mb-3">
                <div className="row-addcontent-col-form-add">
                  <label
                    htmlFor="nbre_unite_fabrique"
                    className="form-label form-label-add-recette"
                  >
                    Nombre d’unité fabriqué
                  </label>
                  <input
                    className="form-control form-control-add-form"
                    placeholder="Nombre d’unité fabriqué"
                    type="number"
                    step={"any"}
                    min={0}
                    onChange={(e) => onChangeFabrication(e)}
                    value={infosFabic?.nbre_unite_fabrique}
                    name="nbre_unite_fabrique"
                  />
                  <ErrorMessage
                    message={
                      errors?.nbre_unite_fabrique &&
                      errors?.nbre_unite_fabrique?.message
                    }
                  />
                </div>
              </div>
              <div className="col-form-add-student mb-3">
                <div className="row-addcontent-col-form-add">
                  <label
                    htmlFor=""
                    className="form-label form-label-add-recette"
                  >
                    Coût par unité (€)
                  </label>
                  <div className="flex-sb">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="cout_derniere_comme"
                        // {...register("type_cout_par_unite")}
                        id="flexCheckDefault"
                        onChange={(e) => onChangeFabrication(e)}
                        defaultChecked={
                          infosFabic?.type_cout_par_unite ===
                          "cout_derniere_comme"
                        }
                        name="type_cout_par_unite"
                      />
                      <label
                        className="form-check-label form-check-label-costum"
                        htmlFor="flexCheckDefault"
                      >
                        Coût dernière commande
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="cout_moyen"
                        // {...register("type_cout_par_unite")}
                        id="flexCheckDefault"
                        onChange={(e) => onChangeFabrication(e)}
                        defaultChecked={
                          infosFabic?.type_cout_par_unite === "cout_moyen"
                        }
                        name="type_cout_par_unite"
                      />
                      <label
                        className="form-check-label form-check-label-costum"
                        htmlFor="flexCheckDefault"
                      >
                        Coût moyen
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-form-add-student mb-3">
                <div className="row-addcontent-col-form-add">
                  <label
                    htmlFor="cout_sans_emballage"
                    className="form-label form-label-add-recette"
                  >
                    Coût sans emballage
                  </label>
                  <input
                    className="form-control form-control-add-form"
                    placeholder="Coût sans emballage"
                    type="number"
                    step={"any"}
                    readOnly
                    min={0}
                    onChange={(e) => onChangeFabrication(e)}
                    value={infosFabic?.cout_sans_emballage}
                    name="cout_sans_emballage"
                  />
                  <ErrorMessage
                    message={
                      errors?.cout_sans_emballage &&
                      errors?.cout_sans_emballage?.message
                    }
                  />
                </div>
              </div>
              <div className="col-form-add-student mb-3">
                <div className="row-addcontent-col-form-add">
                  <label
                    htmlFor="cout_avec_emballage"
                    className="form-label form-label-add-recette"
                  >
                    Coût avec emballage
                  </label>
                  <input
                    className="form-control form-control-add-form"
                    placeholder="Coût avec emballage"
                    type="number"
                    step={"any"}
                    readOnly
                    min={0}
                    onChange={(e) => onChangeFabrication(e)}
                    value={infosFabic?.cout_avec_emballage}
                    name="cout_avec_emballage"
                  />
                  <ErrorMessage
                    message={
                      errors?.cout_avec_emballage &&
                      errors?.cout_avec_emballage?.message
                    }
                  />
                </div>
              </div>
            </div>
            <div className="form-add-cout-recette-item">
              <div className="col-form-add-student mb-3">
                <div className="row-addcontent-col-form-add">
                  <label
                    htmlFor="cout_fabrication_total"
                    className="form-label form-label-add-recette"
                  >
                    Coût fabrication total
                  </label>
                  <input
                    className="form-control form-control-add-form"
                    placeholder="Coût fabrication total"
                    type="number"
                    step={"any"}
                    readOnly
                    min={0}
                    onChange={(e) => onChangeFabrication(e)}
                    value={parseFloat(infosFabic?.cout_fabrication_total).toFixed(2)}
                    name="cout_fabrication_total"
                  />
                  <ErrorMessage
                    message={
                      errors?.cout_fabrication_total &&
                      errors?.cout_fabrication_total?.message
                    }
                  />
                </div>
              </div>
              <div className="col-form-add-student mb-3">
                <div className="row-addcontent-col-form-add">
                  <label
                    htmlFor="cout_total_emballage"
                    className="form-label form-label-add-recette"
                  >
                    Total coût emballage (€)
                  </label>
                  <input
                    className="form-control form-control-add-form"
                    placeholder="Total coût emballage (€)"
                    type="number"
                    step={"any"}
                    min={0}
                    readOnly
                    onChange={(e) => onChangeFabrication(e)}
                    value={parseFloat(infosFabic?.cout_total_emballage).toFixed(2)}
                    name="cout_total_emballage"
                  />
                  <ErrorMessage
                    message={
                      errors?.cout_total_emballage &&
                      errors?.cout_total_emballage?.message
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex-r gap-4 pe-5 align-items-center py-5">
            <button
              className="btn btn-cancelled-recette"
              onClick={() => navigation.previous()}
            >
              Retour
            </button>
            <button
              className="btn auth-submit-btn-recette trans-0-2"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                    role="status"
                  ></span>
                  <span>Enregistrer...</span>
                </>
              ) : (
                "Fabriquer cette recette"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoutFabricationTabs;
