import React from "react";
import { useNavigate } from "react-router-dom";
import { CommandeLine } from "../../../utils/api/commande/commande.type";
import { Categories, Names } from "../../../utils/constante";
import { useLocationState } from "../../../utils/utils";
import ErrorMessage from "../../common/ErrorMessage";
import UseEditCommandeLineForm from "./requestForm/UseEditCommandeLineForm";

function EditCommandeLine() {
  const itemState = useLocationState<CommandeLine>(undefined);
  const {
    register,
    handleChange,
    handleChangeQty,
    errors,
    isLoading,
    onSubmit,
    options,
  } = UseEditCommandeLineForm(itemState);
  const navigate = useNavigate();
  return (
    <div className="list-monitors-admin">
      <div className="container-title-page-admin">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">Modification du stock</h3>
        </div>
      </div>
      <div className="container-form-add">
        <form onSubmit={onSubmit}>
          <div className="row row-add">
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="category"
                  className="form-label form-label-add-commande"
                >
                  Catégorie
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                <select
                  className="form-control form-control-add-form-select"
                  {...register(`category`)}
                >
                  <option value="">Sélectionnez</option>
                  {Categories?.map((category, i) => (
                    <option value={category?.value} key={i}>
                      {" "}
                      {category?.label}{" "}
                    </option>
                  ))}
                </select>
                {/* <input
                  className="form-control form-control-add-form"
                  type="text"
                  placeholder="Catégorie"
                  {...register("category")}
                /> */}

                <ErrorMessage message={errors?.category?.message} />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="nom"
                  className="form-label form-label-add-commande"
                >
                  Nom
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                {/* <select
                  className="form-control form-control-add-form-select"
                  {...register(`nom`)}
                >
                  <option value="">Sélectionnez</option>
                  {Names?.map((name, i) => (
                    <option value={name?.value} key={i}>
                      {" "}
                      {name?.label}{" "}
                    </option>
                  ))}
                </select> */}
                <input
                  className="form-control form-control-add-form"
                  type="text"
                  placeholder="Nom"
                  {...register("nom")}
                />

                <ErrorMessage message={errors?.nom?.message} />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="lot"
                  className="form-label form-label-add-commande"
                >
                  Num. Lot{" "}
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="text"
                  placeholder="Num. Lot"
                  {...register("numero_lot")}
                />
                <ErrorMessage message={errors?.numero_lot?.message} />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="dluo_ddm"
                  className="form-label form-label-add-commande"
                >
                  DLUO/DDM{" "}
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="date"
                  {...register("dluo_ddm")}
                />
                <ErrorMessage message={errors?.dluo_ddm?.message} />
              </div>
            </div>

            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="date_ouverture"
                  className="form-label form-label-add-commande"
                >
                  Date Ouverture
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="date"
                  {...register("date_ouverture")}
                />
                <ErrorMessage message={errors?.date_ouverture?.message} />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="quantite"
                  className="form-label form-label-add-commande"
                >
                  Quantité{" "}
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                <input
                  type="number"
                  min={0}
                  name={`quantite`}
                  placeholder="Quantité"
                  // step={"any"}
                  value={options?.quantite}
                  onChange={(e) => handleChangeQty(e)}
                  className="form-control form-control-add-form"
                />

                <ErrorMessage message={errors?.quantite?.message} />
              </div>
            </div>

            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="poids"
                  className="form-label form-label-add-commande"
                >
                  Poids
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="number"
                  step={"any"}
                  min={0}
                  placeholder="Poids"
                  {...register("poids")}
                />
                <ErrorMessage message={errors?.poids?.message} />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="unite_poids"
                  className="form-label form-label-add-commande"
                >
                  Unité de Poids
                </label>
                <select
                  className="form-control form-control-add-form"
                  {...register(`unite_poids`)}
                >
                  <option value="">Sélectionnez</option>
                  <option value="g">g</option>
                  <option value="kg">kg</option>
                </select>

                <ErrorMessage message={errors?.unite_poids?.message} />
              </div>
            </div>

            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="volume"
                  className="form-label form-label-add-commande"
                >
                  Volume
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="number"
                  step={"any"}
                  min={0}
                  placeholder="Volume"
                  {...register("volume")}
                />
                <ErrorMessage message={errors?.volumeExist?.message} />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="unite_volume"
                  className="form-label form-label-add-commande"
                >
                  Unité de Volume
                </label>
                <select
                  className="form-control form-control-add-form"
                  {...register(`unite_volume`)}
                >
                  <option value="">Sélectionnez</option>
                  <option value="ml">mL</option>
                  <option value="l">L</option>
                </select>

                <ErrorMessage message={errors?.unite_volume?.message} />
              </div>
            </div>

            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="seuil_alerte"
                  className="form-label form-label-add-commande"
                >
                  Seuil d'alerte{" "}
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="number"
                  min={0}
                  placeholder="Seuil d'alerte"
                  {...register("seuil_alerte")}
                />
                <ErrorMessage message={errors?.seuil_alerte?.message} />
              </div>
            </div>

            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="prix_unite"
                  className="form-label form-label-add-commande"
                >
                  Prix par unité{" "}
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="number"
                  step={"any"}
                  min={0}
                  name={`prix_unite`}
                  onChange={(e) => handleChange(e)}
                  value={options?.prix_unite}
                  placeholder="Prix par unité"
                />
                <ErrorMessage message={errors?.prix_unite?.message} />
              </div>
            </div>

            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="prix_total_ligne"
                  className="form-label form-label-add-commande"
                >
                  Prix total{" "}
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="number"
                  step={"any"}
                  readOnly
                  min={0}
                  {...register(`prix_total_ligne`)}
                  placeholder="Prix total"
                />
                <ErrorMessage message={errors?.prix_total_ligne?.message} />
              </div>
            </div>
          </div>
          <div className="row row-content-btn-action py-5">
            <div className="col-md-4 offset-md-4">
              <button
                className="btn btn-cancelled w-100"
                onClick={() => navigate(-1)}
              >
                Annuler
              </button>
            </div>
            <div className="col-md-4">
              <button
                className="btn btn-page-title-admin w-100"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>Enregistrer...</span>
                  </>
                ) : (
                  "Enregistrer"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditCommandeLine;
