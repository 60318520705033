import React from "react";
import "./GestionFabricationAdmin.css";
import { useStepType } from "../../../utils/type";
import { useStep } from "react-hooks-helper";
import CosmeticFabricationAdminTabsItem from "./FabricationAdminTabs/CosmeticFabricationAdminTabsItem";
import SavonFabricationAdminTabsItem from "./FabricationAdminTabs/SavonFabricationAdminTabsItem";

const steps = [
  { id: "savon", Component: SavonFabricationAdminTabsItem },
  { id: "cosmetique", Component: CosmeticFabricationAdminTabsItem },
];
const tabs = ["Savon", "Cosmétique"];

const GestionFabricationAdmin = () => {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const props = {
    type: step?.id,
  };
  return (
    <div className="fournisseur-list-component">
      <div className="container-title-page-admin mb-5">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">Liste des fabrications</h3>
        </div>
        {/* <div className="content-btn-action-title-page-admin">
          <NavLink
            to="/fabriquant/fabriquer"
            className="btn btn-page-title-admin"
          >
            <MdOutlineAdd />
            <span className="ps-2">Fabriquer</span>
          </NavLink>
        </div> */}
      </div>
      <div className="container-fournisseur-list">
        <div className="container-ul-recette">
          <ul className="tabs-container">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
        </div>
        <div className="tabs-component-container">
          <Component {...props} />
        </div>
      </div>
    </div>
  );
};

export default GestionFabricationAdmin;
