import React, { Fragment, useState } from "react";
import "./FournisseursList.css";
import { NavLink } from "react-router-dom";
import { TfiMoreAlt } from "react-icons/tfi";
import { MdOutlineAdd } from "react-icons/md";
import DetailFournisseurModal from "./modal/DetailFournisseurModal";
import { useGetFournisseurByUserQuery } from "../../../utils/api/fournisseur/fournisseur.api";
import { useAppSelector } from "../../../redux/hook";
import { getInitials } from "../../../utils/utils";
import { AlertInfo } from "../../common/Alert";
import { UseDeleteItem } from "./requestForm/UseCrudFournisseurForm";
import Skeleton from "react-loading-skeleton";
import Pagination from "../../common/Pagination";

function DeleteItemBtn({ id }: { id: string }) {
  const onDelete = UseDeleteItem(id);

  return (
    <button
      className="dropdown-item"
      style={{ fontSize: 16 }}
      onClick={() => onDelete()}
    >
      Supprimer
    </button>
  );
}

export const CardSkeleton = ({ key }: { key: number }) => {
  return (
    <div className="col-lg-4 col-md-6 mb-3 d-flex" key={key}>
      <div className="content-fournisseur-item position-relative">
        <div className="content-info-fournisseur-detail">
          <div className="pb-3">
            <Skeleton
              circle
              className="content-img-profile-fournisseur-skeleton "
            />
          </div>

          <Skeleton
            className="name-fournisseur-item mb-1"
            width={200}
            height={20}
          />
          <Skeleton
            className="text-description-fournisseur"
            width={150}
            height={15}
          />
        </div>
        <div className="dropdown content-icon-menu d-flex">
          <Skeleton width={5} height={5} style={{ marginRight: "0.1rem" }} />
          <Skeleton width={5} height={5} style={{ marginRight: "0.1rem" }} />
          <Skeleton width={5} height={5} />
        </div>
      </div>
    </div>
  );
};
const FournisseursList = () => {
  const [page, setPage] = useState(1);
  const { userInfos } = useAppSelector((s) => s?.userInfos);
  const { data = { data: { data: [] }, count: 0 }, isLoading } =
    useGetFournisseurByUserQuery({ id: userInfos?.user_id, page });

  return (
    <div className="fournisseur-list-component">
      <div className="container-title-page-admin mb-5">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">Liste des fournisseurs</h3>
        </div>
        <div className="content-btn-action-title-page-admin">
          <NavLink
            to="/fabriquant/ajouter-fournisseur"
            className="btn btn-page-title-admin"
          >
            <MdOutlineAdd />
            <span className="ps-2">Ajouter un fournisseur</span>
          </NavLink>
        </div>
      </div>
      <div className="container-fournisseur-list">
        <div className="row row-fournisseur-list">
          {!isLoading &&
            (data?.data?.data?.length ? (
              data?.data?.data?.map((item) => (
                <div className="col-lg-4 col-md-6 mb-3 d-flex">
                  <div className="content-fournisseur-item position-relative">
                    <div
                      className="content-info-fournisseur-detail linkable"
                      data-bs-toggle="modal"
                      data-bs-target={`#fournisseurItemModal${item?.fournisseur_id}`}
                    >
                      <div className="content-img-profile-fournisseur pb-3">
                        <span className="img-profile-fournisseur-item">
                          {getInitials(item?.fullname)}
                        </span>
                      </div>
                      <p className="name-fournisseur-item mb-1">
                        {item?.fullname}
                      </p>
                      {/* <p className="text-description-fournisseur">
                        ID {item?.fournisseur_id || "non défini"}
                      </p> */}
                      <DetailFournisseurModal item={item} />
                    </div>
                    <div className="dropdown content-icon-menu">
                      <button
                        className="dropdown-toggle btn"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <TfiMoreAlt />
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-custom"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <NavLink
                          to={`/fabriquant/modifier-fournisseur/${item?.fournisseur_id}`}
                          className="dropdown-item"
                          state={item}
                          style={{ fontSize: 16 }}
                        >
                          Modifier
                        </NavLink>
                        <DeleteItemBtn id={item?.fournisseur_id} />
                        <NavLink
                          to={`/fabriquant/commandes-fournisseur/${item?.fournisseur_id}`}
                          state={item}
                          className="dropdown-item"
                          style={{ fontSize: 16 }}
                        >
                          Commandes
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <AlertInfo message="Aucun fournisseur disponible pour le moment!" />
            ))}
          {!!isLoading &&
            [...Array(6)]?.map((item, i) => (
              <Fragment key={i}>
                <CardSkeleton key={i} />
              </Fragment>
            ))}
        </div>
        <div className="flex-r mb-t">
          <Pagination
            page={page}
            total={data?.count}
            perPage={10}
            onPageChange={(page: number) => setPage(page)}
          />
        </div>
      </div>
    </div>
  );
};

export default FournisseursList;
