const SuiviCureTableData: any = [
  {
    id: 1,
    visuel: "Test",
    olfactif: "Test",
    observation: "Test",
    date: "20/10/2022",
    poids: 30,
  },
  {
    id: 2,
    visuel: "Test",
    olfactif: "Test",
    observation: "Test",
    date: "20/10/2022",
    poids: 30,
  },
  {
    id: 3,
    visuel: "Test",
    olfactif: "Test",
    observation: "Test",
    date: "20/10/2022",
    poids: 30,
  },
  {
    id: 4,
    visuel: "Test",
    olfactif: "Test",
    observation: "Test",
    date: "20/10/2022",
    poids: 30,
  },
];

export default SuiviCureTableData;
