/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { IUser, UserFormData } from "../../../../utils/api/user/user.type";
import { cleannerError, validatePhone } from "../../../../utils/utils";
import {
  useAddOrUpdateUserMutation,
  useDeleteUserMutation,
  useGenerePasswordMutation,
  useToggleUserMutation,
} from "../../../../utils/api/user/user.api";
import { Color } from "../../../../utils/theme";
import { onSetUser } from "../../../../redux/slice/User.slice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";

function UseCrudUser(user?: IUser) {
  let location = useLocation();
  const [country, setCountry] = useState<string>("");
  const validationSchema = yup.object().shape({
    isAdmin: yup.boolean(),
    first_name: yup.string().required().label("Le prénom"),
    last_name: yup.string().required().label("Le nom"),
    address: yup.string().label("L'adresse").nullable(),
    phone: validatePhone(yup, country),
    email: yup.string().email().required().label("L'email"),
    // permissions: yup.array().when("isAdmin", {
    //   is: true,
    //   then: yup.array().required(),
    // }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<UserFormData>({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const [permissions, setPermissions] = useState<any>([]);
  const { userInfos } = useAppSelector((s) => s?.userInfos);
  const [sendData, { isLoading, isSuccess, error, isError, data }] =
    useAddOrUpdateUserMutation();
  const [address, setAddress] = useState<string>("");
  const [phone, setPhone] = useState<string | null>(null);

  const handleChangePhone = (item, country) => {
    setCountry(country?.countryCode);
    setPhone(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue("phone", item);
    } else {
      setValue("phone", "");
    }
  };

  const onChangeAddress = (val) => {
    setAddress(val?.rue);
    setValue("address", val?.rue);
  };

  // useEffect(() => {
  //   setValue(`permissions`, permissions);
  // }, [permissions]);

  // const onChangePermission = (e: ChangeEvent<HTMLInputElement>) => {
  //   let data = [...permissions];
  //   if (e.target.checked) {
  //     data.push(parseInt(e.target.value));
  //     setPermissions(data);
  //   } else {
  //     setPermissions(
  //       data?.filter((el) => parseInt(el) !== parseInt(e.target.value))
  //     );
  //   }
  // };

  useEffect(() => {
    if (!user?.user_id) {
      if (location?.pathname?.endsWith("/ajouter-utilisateur")) {
        setValue("isAdmin", false);
      } else {
        setValue("isAdmin", true);
      }
    } else {
      if (user?.status === "fabriquant") {
        setValue("isAdmin", false);
      } else {
        setValue("isAdmin", true);
      }
    }
  }, [location, user]);

  useEffect(() => {
    if (user?.user_id) {
      const fields: (keyof UserFormData)[] = [
        "first_name",
        "last_name",
        "email",
        "phone",
        "address",
        "user_id",
        "status"
      ];
      for (let field of fields) {
        register(field);
        if (field !== "permissions") {
          setValue(field, user[field]);
        }
      }
      if (user?.address) {
        setAddress(user?.address);
      }
      // if (user?.permissions?.length) {
      //   setPermissions(user?.permissions?.map((item) => item?.id));
      // }

      if (user?.phone) {
        setPhone(user?.phone);
      }
    }
  }, [user]);

  useEffect(() => {
    if (isSuccess) {
      if (data?.success) {
        Swal.fire({
          icon: "success",
          iconColor: Color.success,
          confirmButtonColor: Color.success,
          title: data?.message,
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          if (data?.data?.status !== "fabricant") {
            if (data?.data?.user_id === userInfos?.user_id) {
              dispatch(onSetUser(data?.data));
            }

            navigate("/admin/parametres");
          } else {
            navigate("/admin/utilisateurs");
          }
        });
      } else {
        // setError("password", { message: data?.message });
        Swal.fire({
          icon: "error",
          title: data?.message,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    console.log(errors);
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSubmit = async (data: UserFormData) => {
    if (!user?.user_id) {
      if (location?.pathname?.endsWith("/ajouter-utilisateur")) {
        data["status"] = "fabricant";
        data["type"] = "fabricant";
      } else {
        data["status"] = "admin";
        data["type"] = "admin";
      }
    }

    await sendData(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    phone,
    handleChangePhone,
    onChangeAddress,
    address,
    country,
    // permissions,
    // onChangePermission,
  };
}

export default UseCrudUser;

export function UseDeleteUserItem(id: string) {
  const [deleteData] = useDeleteUserMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cet utilisateur ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(id);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        console.log(result);
        Swal.fire({
          icon: "success",
          title: `Utilisateur supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}

export function UseGeneratePassword(id: string) {
  const [sendData] = useGenerePasswordMutation();
  const onSend = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir générer un nouveau mot de passe pour cet utilisateur ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return sendData(id);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result);
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Mot de passe généré avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: result?.value?.error?.message || `Une erreur est survenue`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    });
  };
  return onSend;
}

export function UseToggleUserItem(user: IUser) {
  const [toggleData] = useToggleUserMutation();
  const onDelete = async () => {
    if (user?.is_banned === 1) {
      await Swal.fire({
        title: `Êtes-vous sûr de vouloir réactiver le compte de cet utilisateur ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "OUI",
        cancelButtonText: "NON",
        showLoaderOnConfirm: true,
        iconColor: Color.themeColor,
        confirmButtonColor: Color.themeColor,
        preConfirm: () => {
          return toggleData({
            user_id: user?.user_id,
            banned_reasons: null,
          });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result: any) => {
        console.log("res", result?.value);
        if (result?.isConfirmed) {
          if (!result?.value?.error) {
            console.log(result);
            Swal.fire({
              icon: "success",
              title: `Compte réactivé avec succès!`,
              iconColor: Color.themeColor,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: result?.value?.error?.data?.message
                ? result?.value?.error?.data?.message
                : `Une erreur de statut ${result?.value?.error?.status} est survenue`,
              showConfirmButton: false,
              timer: 5000,
            });
          }
        }
          
      });
    } else {
      await Swal.fire({
        title: `Êtes-vous sûr de vouloir suspendre le compte de cet utilisateur ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "OUI",
        cancelButtonText: "NON",
        showLoaderOnConfirm: true,
        iconColor: Color.themeColor,
        confirmButtonColor: Color.themeColor,
      }).then((result: any) => {
        if (result?.isConfirmed) {
          Swal.fire({
            title: "Veuillez donner le motif de cette action",
            input: "textarea",
            inputPlaceholder: "Motif...",
            inputAttributes: {
              "aria-label": "Motif",
            },
            showCancelButton: true,
            confirmButtonText: "Envoyer",
            cancelButtonText: "Annuler",
            showLoaderOnConfirm: true,
            iconColor: Color.themeColor,
            confirmButtonColor: Color.themeColor,
            preConfirm: (data) => {
              if (data) return toggleData({
                user_id: user?.user_id,
                banned_reasons: data,
              });
              return Swal.showValidationMessage(`Le motif est obligatoire`);
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }).then((res) => {
            if (result?.isConfirmed) {
              if (!result?.value?.error) {
                console.log(result);
                Swal.fire({
                  icon: "success",
                  title: `Compte suspendu avec succès!`,
                  iconColor: Color.themeColor,
                  showConfirmButton: false,
                  timer: 1500,
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: result?.value?.error?.data?.message
                    ? result?.value?.error?.data?.message
                    : `Une erreur de statut ${result?.value?.error?.status} est survenue`,
                  showConfirmButton: false,
                  timer: 5000,
                });
              }
            }
            
          });
        }
      });
    }
  };
  return onDelete;
}
