import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ReceptionnerCommandeTableData from "./ReceptionnerCommandeTableData";
import TableSkeleton from "../../../common/TableSkeleton";
import { BsPlusSquare } from "react-icons/bs";
import { NavLink, useNavigate } from "react-router-dom";
import "./ReceptionnerCommandeTable.css";
import { useAppSelector } from "../../../../redux/hook";
import { useGetCommandeByUserQuery } from "../../../../utils/api/commande/commande.api";
import { IFournisseur } from "../../../../utils/api/fournisseur/fournisseur.type";
import { ICommandes } from "../../../../utils/api/commande/commande.type";
import { CustomPagination } from "../StockMPTable/StockMPTable";

function ReceptionnerCommandeTable() {
  const [page, setPage] = useState(1);
  const { userInfos } = useAppSelector((s) => s?.userInfos);
  const { data = { data: {data:[],total: 0}, count: 0 }, isLoading } =
    useGetCommandeByUserQuery({
      id: userInfos?.user_id,
    });
  const navigate = useNavigate();

  useEffect(() => {
    console.log("data", data);
  }, [data]);
  const actionFormatter = (cell:string, row: ICommandes) => {
    return (
      <p className="price-table-format mb-0">
        <NavLink
          style={{ color: "#50B2D4", fontSize: 20 }}
          to={`/fabriquant/ajouter-commande/${cell}`}
          state={row}
        >
          <BsPlusSquare />{" "}
        </NavLink>
      </p>
    );
  };
  const rowEvents = {
    onClick: (e: any, row:ICommandes, rowIndex: number) => {
      // console.log(e,row,rowIndex)
      navigate(`/fabriquant/ajouter-commande/${row?.commande_id}`,{state:row});
    },
  };
  const rowClasses = () => {
    return "custom-row-class";
  };
  const columns = [
    {
      dataField: "numero_commande",
      text: "N° de la commande",
    },
    {
      dataField: "fournisseur",
      text: "Fournisseurs",
      formatter: (cell: IFournisseur) => cell?.fullname,
    },
    {
      dataField: "numero_client",
      text: "Numéro de client",
    },
    {
      dataField: "commande_id",
      text: "",
      formatter: (cell: string, row: ICommandes) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="commande_id"
            data={data?.data?.data}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            rowEvents={rowEvents}
            rowClasses={rowClasses}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => "Aucune donnée trouvée"}
          />
          <CustomPagination
            nbPages={data?.data?.total}
            page={page}
            onChange={(page) => {
              setPage(page);
            }}
            perPage={10}
          />
        </>
      )}
    </>
  );
}

export default ReceptionnerCommandeTable;
