import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { AiOutlineFileAdd } from "react-icons/ai";
import { useGetDashboardByAdminQuery } from "../../../utils/api/user/user.api";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function CommandeGraph() {
  const [selected, setSelected] = useState<{ number: number; label: string }>({
    number: 12,
    label: "month",
  });
  const { data,isLoading } = useGetDashboardByAdminQuery(selected);
  const [label, setLabel] = useState<string[]>([]);
  const [stats, setStats] = useState<any>([]);

  // console.log(data);

  useEffect(() => {
    let statsCopy = [...stats];
    if (data?.data) {
      let keys: any = Object.keys(data?.data);

      if (selected?.label === "day") {
        setLabel(
          keys
            ?.sort(
              (a: any, b: any) => new Date(a).getTime() - new Date(b).getTime()
            )
            ?.map((key) => moment(key).format("DD MMMM YYYY"))
        );
      } else {
        setLabel(
          keys
            ?.sort(
              (a: any, b: any) => new Date(a).getTime() - new Date(b).getTime()
            )
            ?.map((key) => moment(key).format("MMMM YYYY"))
        );
      }

      statsCopy = keys?.map((key) => data?.data[key]?.total_inscrits);
      // statsCopy["recette"] = keys?.map(
      //   (key) => data?.data[key]?.total_recettes
      // );

      // statsCopy["fab"] = keys?.map(
      //   (key) => data?.data[key]?.total_fabrications
      // );

      // console.log("keys", data?.data, statsCopy);
    }
    setStats(statsCopy)
  }, [data]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
    },
  };

  const labels = label;

  const lines = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Nombre d'inscrits",
        data: stats,
        lineTension: 0.3,
        borderColor: "#257A97",
        backgroundColor: "rgba(238, 242, 255, 0.5)",
      },
      // {
      //   fill: true,
      //   label: "Recettes",
      //   data: stats?.recette,
      //   lineTension: 0.3,
      //   borderColor: "#50B2D4",
      //   backgroundColor: "rgba(255, 255, 255, 0.04)",
      // },
      // {
      //   fill: true,
      //   label: "Fabrications",
      //   data: stats?.fab,
      //   lineTension: 0.3,
      //   borderColor: "#66a1b6",
      //   backgroundColor: "#bdd7df",
      // },
    ],
  };

  return (
    <div className="chart-item-container">
      <div className="container-header-graph-page mb-4">
        <div className="content-title-graph">
          <h2 className="chart-item-admin-title-admin mb-0">Nombre d'inscriptions</h2>
        </div>
        <div className="content-tn-filter-graph">
          <button
            className={`btn btn-filter-graph ${
              selected?.number === 12 ? "active" : ""
            }`}
            onClick={() => setSelected({ number: 12, label: "month" })}
          >
            12 Mois
          </button>
          <button
            className={`btn btn-filter-graph ${
              selected?.number === 6 ? "active" : ""
            }`}
            onClick={() => setSelected({ number: 6, label: "month" })}
          >
            6 Mois
          </button>
          <button
            className={`btn btn-filter-graph ${
              selected?.number === 30 ? "active" : ""
            }`}
            onClick={() => setSelected({ number: 30, label: "day" })}
          >
            30 Jours
          </button>
          <button
            className={`btn btn-filter-graph ${
              selected?.number === 7 ? "active" : ""
            }`}
            onClick={() => setSelected({ number: 7, label: "day" })}
          >
            7 Jours
          </button>
        </div>
        {/* <div className="content-btn-action-graph">
          <button className="btn btn-export-graph">
            <AiOutlineFileAdd />
            <span className="ps-2">Export PDF</span>
          </button>
        </div> */}
      </div>
      {!!isLoading && <Skeleton height={475} width={900} />}
      {!isLoading && <Line options={options} data={lines} />}
      
    </div>
  );
}

export default CommandeGraph;
