import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import TableSkeleton from "../../../common/TableSkeleton";
import ProduitFiniAdminTableData from "./ProduitFiniAdminTableData";
import { BsFileCheckFill } from "react-icons/bs";
import { useGetAllFabricationListQuery } from "../../../../utils/api/fabrication/fabrication.api";
import { Recette } from "../../../../utils/api/recette/recette.type";
import { onFormatDate } from "../../../../utils/utils";

function ProduitFiniAdminTable() {
  const [page, setPage] = useState(1);
  const { data = { data: { data: [], total: 0 } }, isLoading } =
    useGetAllFabricationListQuery({
      page,
    });

  const cureFormatter = (cell, row) => {
    if (cell === "vert") {
      return (
        <>
          <span style={{ color: "#219653", fontSize: 20 }}>
            <BsFileCheckFill />
          </span>
        </>
      );
    }
    if (cell === "Orange") {
      return (
        <>
          <span style={{ color: "#F2994A", fontSize: 20 }}>
            <BsFileCheckFill />
          </span>
        </>
      );
    }

    return (
      <>
        <span style={{ color: "#EB5757", fontSize: 20 }}>
          <BsFileCheckFill />
        </span>
      </>
    );
  };

  const columns = [
    {
      dataField: "numero_fabrication",
      text: "N° ",
    },
    {
      dataField: "type_produit",
      text: "Catégorie",
    },
    {
      dataField: "recette",
      text: "Nom",
      formatter: (cell: Recette) => cell?.nom_recette,
    },
    {
      dataField: "code_interne",
      text: "Code interne",
    },
    {
      dataField: "numero_lot",
      text: "N° de lot",
    },
    {
      dataField: "date_fabrication",
      text: "Date de fabrication",
      formatter: (cell: string) => onFormatDate(cell),
    },
    {
      dataField: "mise_encure_status",
      text: "Sortie de cure",
      formatter: (cell: any, row: any) => cureFormatter(cell, row),
    },
    {
      dataField: "sortie_cure_nombre_produits_conformes",
      text: "Quantité restante",
      formatter: (cell: number) => cell || "Non disponible",
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="fabrication_id"
            data={data?.data?.data}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => "Aucune donnée trouvée"}
          />
        </>
      )}
    </>
  );
}

export default ProduitFiniAdminTable;
