import React from "react";
import IconCall from "../../../../assets/icons/call.png";
import IconMail from "../../../../assets/icons/mail.png";
import IconLocate from "../../../../assets/icons/locate.png";
import IconInfo from "../../../../assets/icons/info.png";
import IconShop from "../../../../assets/icons/shop.png";
import { getInitials } from "../../../../utils/utils";
import { IFournisseur } from "../../../../utils/api/fournisseur/fournisseur.type";

const DetailFournisseurModal = ({ item }: { item: IFournisseur }) => {
  return (
    <div
      className="modal fade"
      id={`fournisseurItemModal${item?.fournisseur_id}`}
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close no-style-btn-modal btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="container-detail-fournisseur">
              <div className="header-fournisseur text-center">
                <div className="content-img-profile-fournisseur pb-3">
                  <span className="img-profile-fournisseur-item">
                    {getInitials(item?.fullname)}
                  </span>
                </div>
                <p className="name-fournisseur-item mb-1">{item?.fullname}</p>
                {/* <p className="text-description-fournisseur">
                  ID {item?.fournisseur_id || "non défini"}
                </p> */}
              </div>
              <h5 className="modal-title">Details</h5>
              <div className="container-info-fournisseur mt-4">
                <div className="info-fournisseur-detail-item">
                  <div className="content-icon-detail-fournisseur">
                    <img
                      src={IconCall}
                      alt="Téléphone"
                      className="icon-detail-fournisseur"
                    />
                  </div>
                  <div className="fournisseur-info-detail">
                    <p className="libelle-info-detail mb-1">Téléphone</p>
                    <p className="value-info-detail mb-0">
                      {item?.phone || "Non défini"}
                    </p>
                  </div>
                </div>
                <div className="info-fournisseur-detail-item">
                  <div className="content-icon-detail-fournisseur">
                    <img
                      src={IconMail}
                      alt="Téléphone"
                      className="icon-detail-fournisseur"
                    />
                  </div>
                  <div className="fournisseur-info-detail">
                    <p className="libelle-info-detail mb-1">Email</p>
                    <p className="value-info-detail mb-0">
                      {item?.email || "Non défini"}
                    </p>
                  </div>
                </div>
                <div className="info-fournisseur-detail-item">
                  <div className="content-icon-detail-fournisseur">
                    <img
                      src={IconLocate}
                      alt="Téléphone"
                      className="icon-detail-fournisseur"
                    />
                  </div>
                  <div className="fournisseur-info-detail">
                    <p className="libelle-info-detail mb-1">Adresse</p>
                    <p className="value-info-detail mb-0">
                      {item?.address || "Non défini"}
                    </p>
                  </div>
                </div>
                <div className="info-fournisseur-detail-item">
                  <div className="content-icon-detail-fournisseur">
                    <img
                      src={IconInfo}
                      alt="Téléphone"
                      className="icon-detail-fournisseur"
                    />
                  </div>
                  <div className="fournisseur-info-detail">
                    <p className="libelle-info-detail mb-1">Nom commercial</p>
                    <p className="value-info-detail mb-0">
                      {item?.nom_commercial || "Non défini"}
                    </p>
                  </div>
                </div>
                <div className="info-fournisseur-detail-item">
                  <div className="content-icon-detail-fournisseur">
                    <img
                      src={IconShop}
                      alt="Téléphone"
                      className="icon-detail-fournisseur"
                    />
                  </div>
                  <div className="fournisseur-info-detail">
                    <p className="libelle-info-detail mb-1">Commande</p>
                    <p className="value-info-detail mb-0">
                      {item?.minimum_commande || "Non défini"}
                    </p>
                  </div>
                </div>
                <div className="info-fournisseur-detail-item">
                  <div className="content-icon-detail-fournisseur">
                    <img
                      src={IconInfo}
                      alt="Téléphone"
                      className="icon-detail-fournisseur"
                    />
                  </div>
                  <div className="fournisseur-info-detail">
                    <p className="libelle-info-detail mb-1">Franco de port</p>
                    <p className="value-info-detail mb-0">
                      {item?.franco_port || "Non défini"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailFournisseurModal;

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    zIndex: 99999,
  },
};
