import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleannerError } from "../../../../utils/utils";
import {
  CommandeItemFormData,
  CommandeLineFormData,
  ICommandes,
} from "../../../../utils/api/commande/commande.type";
import { useAddOrUpdateCommandeDetailsMutation } from "../../../../utils/api/commande/commande.api";
import { Color } from "../../../../utils/theme";
import { useAppSelector } from "../../../../redux/hook";
import moment from "moment";

function UseAddDetailsCommande(commande: ICommandes) {
  const validationSchema = yup.object().shape({
    commande_id: yup.string().required().label("La commande"),
    total_mp: yup.string().required().label("Le total MP").nullable(),
    taxe: yup.string().required().label("La taxe").nullable(),
    frais_port: yup.string().required().label("Le frais de port"),
    reduction: yup.string().required().label("Le réduction"),
    total_commande: yup.string().required().label("Le total de la commande"),
    ligne_commandes: yup.array().of(
      yup.object().shape({
        category: yup.string().required().label("La catégorie").nullable(),
        nom: yup.string().required().label("Le nom").nullable(),
        numero_lot: yup.string().required().label("Le N° Lot ").nullable(),
        dluo_ddm: yup.string().required().label("DLUO / DDM").nullable(),
        date_ouverture: yup.string().label("La date d'ouverture").nullable(),
        quantite: yup.string().required().label("La quantité").nullable(),
        seuil_alerte: yup
          .string()
          .required()
          .label("Le seuil d'alerte")
          .nullable(),
        poids: yup
          .string()
          .when("volume", {
            is: (val) => !val,
            then: yup.string().required().label("Le poids").nullable(),
          })
          .nullable(),
        unite_poids: yup
          .string()
          .when("poids", {
            is: (val) => val,
            then: yup.string().required().label("L'unité du poids").nullable(),
          })
          .nullable(),
        volume: yup.string().label("Le volume").nullable(),

        unite_volume: yup
          .string()
          .when("volume", {
            is: (val) => val,
            then: yup.string().required().label("L'unité du volume").nullable(),
          })
          .nullable(),
        volumeExist: yup
          .string()
          .when(["poids", "volume"], {
            is: (val, vol) => !val && !vol,
            then: yup.string().required().label("Le volume").nullable(),
          })
          .nullable(),
        poids_volume_total: yup
          .number()
          .label("Poids/Volume total")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
        prix_unite: yup
          .string()
          .required()
          .label("Le prix par unité")
          .nullable(),
        prix_total_ligne: yup
          .string()
          .required()
          .label("Le prix total de la ligne")
          .nullable(),
      })
    ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    clearErrors,
  } = useForm<CommandeItemFormData>({
    resolver: yupResolver(validationSchema),
  });
  const { userInfos } = useAppSelector((s) => s?.userInfos);
  const [fields, setFields] = useState({
    taxe: "0",
    frais_port: "0",
    reduction: "0",
  });
  const [totalPM, setTotalPM] = useState<string>("0");
  const [total, setTotal] = useState<string>("0");
  const [options, setOptions] = useState<any>([
    {
      quantite: "",
      prix_unite: "",
      prix_total_ligne: "",
      dluo_ddm: "",
      poids: "",
      volume: "",
    },
  ]);
  const navigate = useNavigate();
  const [sendData, { isLoading, isSuccess, error, isError, data }] =
    useAddOrUpdateCommandeDetailsMutation();

  useEffect(() => {
    let totalVal: any = 0;
    console.log("options", options);
    options.forEach((item, i) => {
      if (item?.quantite > 0 && item?.prix_unite > 0) {
        let total: any =
          parseInt(item?.quantite) * parseFloat(item?.prix_unite);
        item.prix_total_ligne = total;
        setValue(`ligne_commandes.${i}.prix_total_ligne`, total);
        totalVal += parseFloat(total);
        if (!item?.ligne_commande_id) {
          console.log(
            "total",
            parseInt(item?.quantite),
            parseFloat(item?.poids || item?.volume)
          );
          setValue(
            `ligne_commandes.${i}.poids_volume_total`,
            parseInt(item?.quantite) * parseFloat(item?.poids || item?.volume)
          );
          item.poids_volume_total =
            parseInt(item?.quantite) * parseFloat(item?.poids || item?.volume);
        }
      } else {
        let total: any = 0;
        item.prix_total_ligne = total;
        setValue(`ligne_commandes.${i}.prix_total_ligne`, total);
        totalVal += parseFloat(total);
        if (!item?.ligne_commande_id) {
          setValue(`ligne_commandes.${i}.poids_volume_total`, NaN);
        }
      }
    });
    setValue("total_mp", totalVal);
    setTotalPM(totalVal);
  }, [options]);

  useEffect(() => {
    let totalVal: any = 0;
    if (totalPM) {
      totalVal +=
        parseFloat(totalPM) +
        parseFloat(fields?.frais_port) +
        parseFloat(fields?.taxe) -
        parseFloat(fields?.reduction);
    }
    setValue("total_commande", totalVal);
    setTotal(totalVal);
  }, [totalPM, fields]);

  const handleChange = (e, i) => {
    // console.log("e", e.target.name);
    let optionsCopy = [...options];
    if (e.target.name === "prix_unite") {
      setValue(`ligne_commandes.${i}.prix_unite`, e.target.value);

      optionsCopy[i] = { ...optionsCopy[i], prix_unite: e.target.value };
    } else if (e.target.name === "poids") {
      setValue(`ligne_commandes.${i}.poids`, e.target.value);

      optionsCopy[i] = { ...optionsCopy[i], poids: e.target.value };
    } else {
      setValue(`ligne_commandes.${i}.volume`, e.target.value);

      optionsCopy[i] = { ...optionsCopy[i], volume: e.target.value };
    }

    setOptions(optionsCopy);
  };

  const handleChangeQty = (e, i) => {
    // console.log("e", e.target.name);
    setValue(`ligne_commandes.${i}.quantite`, e.target.value);
    // let optionsCopy = options;
    let optionsCopy = [...options];
    optionsCopy[i] = { ...optionsCopy[i], quantite: e.target.value };

    setOptions(optionsCopy);
  };

  const handleChangeFields = (e) => {
    // console.log("e", e.target.name);
    // let fieldsCopy = fields;
    // fieldsCopy[e.target.name] = e.target.value;
    setValue(e.target.name, e.target.value);
    setFields((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    // console.log("fields", fieldsCopy);
  };

  useEffect(() => {
    if (commande?.commande_id) {
      setValue("commande_id", commande?.commande_id);
    }

    if (commande?.details_commande?.details_commande_id) {
      const fields: (keyof CommandeItemFormData)[] = [
        "details_commande_id",
        "frais_port",
        "reduction",
        "taxe",
        "total_commande",
        "total_mp",
        "ligne_commandes",
      ];
      for (let field of fields) {
        if (field !== "ligne_commandes") {
          register(field);
          setValue(field, commande?.details_commande[field]);
        }
      }
      if (commande?.details_commande?.ligne_commandes?.length) {
        let optionsCopy = [...options];
        for (
          let i: number = 0;
          i < commande?.details_commande?.ligne_commandes?.length;
          i++
        ) {
          const element = commande?.details_commande?.ligne_commandes[i];
          setValue(`ligne_commandes.${i}.nom`, element?.nom);
          setValue(
            `ligne_commandes.${i}.ligne_commande_id`,
            element?.ligne_commande_id
          );
          setValue(`ligne_commandes.${i}.category`, element?.category);
          setValue(`ligne_commandes.${i}.numero_lot`, element?.numero_lot);
          if (element?.date_ouverture) {
            setValue(
              `ligne_commandes.${i}.date_ouverture`,
              moment(element?.date_ouverture, "DD-MM-YYYY").format("YYYY-MM-DD")
            );
          }

          setValue(
            `ligne_commandes.${i}.dluo_ddm`,
            moment(element?.dluo_ddm, "DD-MM-YYYY").format("YYYY-MM-DD")
          );
          setValue(`ligne_commandes.${i}.quantite`, element?.quantite);
          setValue(`ligne_commandes.${i}.poids`, element?.poids);
          setValue(`ligne_commandes.${i}.unite_poids`, element?.unite_poids);
          setValue(`ligne_commandes.${i}.volume`, element?.volume);
          setValue(`ligne_commandes.${i}.unite_volume`, element?.unite_volume);
          setValue(`ligne_commandes.${i}.seuil_alerte`, element?.seuil_alerte);
          setValue(`ligne_commandes.${i}.prix_unite`, element?.prix_unite);
          setValue(
            `ligne_commandes.${i}.prix_total_ligne`,
            element?.prix_total_ligne
          );
          optionsCopy = [...optionsCopy];
          optionsCopy[i] = {
            ...optionsCopy[i],
            ligne_commande_id: element?.ligne_commande_id,
          };
          optionsCopy[i] = {
            ...optionsCopy[i],
            quantite: element?.quantite,
          };
          optionsCopy[i] = {
            ...optionsCopy[i],
            prix_unite: element?.prix_unite,
          };
          optionsCopy[i] = {
            ...optionsCopy[i],
            poids: element?.poids,
          };
          optionsCopy[i] = {
            ...optionsCopy[i],
            volume: element?.volume,
          };
          optionsCopy[i] = {
            ...optionsCopy[i],
            prix_total_ligne: element?.prix_total_ligne,
          };
        }
        setOptions(optionsCopy);
      }
      setFields({
        taxe: commande?.details_commande?.taxe,
        reduction: commande?.details_commande?.reduction,
        frais_port: commande?.details_commande?.frais_port,
      });
    }
  }, [commande]);

  useEffect(() => {
    if (isSuccess) {
      if (data?.success) {
        Swal.fire({
          icon: "success",
          iconColor: Color.success,
          confirmButtonColor: Color.success,
          title: data?.message,
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          if (userInfos?.status === "fabricant") {
            if (commande?.details_commande?.details_commande_id) {
              navigate(`/fabriquant/receptionner-commande`, { replace: true });
            } else {
              navigate(`/fabriquant/stock-mp`, { replace: true });
            }
          } else {
            navigate("/admin/stock-mp");
          }
        });
      } else {
        // setError("password", { message: data?.message });
        Swal.fire({
          icon: "error",
          title: data?.message,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    console.log("error", errors);
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSubmit = async (data: CommandeItemFormData) => {
    // data["fabriquant_id"] = userInfos?.user_id;

    console.log("data", data);
    await sendData({
      details_commande_id: commande?.details_commande?.details_commande_id,
      data: data,
    });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    Controller,
    control,
    handleChange,
    handleChangeQty,
    totalPM,
    total,
    handleChangeFields,
    options,
    fields,
  };
}

export default UseAddDetailsCommande;
