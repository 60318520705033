import React from "react";
import { CiSearch } from "react-icons/ci";
import "./GestionRecettes.css";
import RecettesAdminTable from "./RecettesAdminTable/RecettesAdminTable";
import FilterIcon from "../../../assets/icons/filter icon.svg"

const GestionRecettes = () => {

  return (
    <div className="fournisseur-list-component">
      <div className="container-title-page-admin mb-5">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">Liste recettes</h3>
        </div>
      </div>
      <div className="container-fournisseur-list">
      <div className="row pb-4">
          <div className="col-md-3 mb-3">
            <div className="form-filter-table position-relative">
              <input
                type="text"
                className="form-control form-control-filter-table"
                placeholder="Filtre"
              />
              <span className="content-icon-filter-table">
                <CiSearch
                  className="filter-icon-table"
                  style={{ color: "#7C97B6" }}
                />
              </span>
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className="form-filter-table position-relative">
              <input
                type="text"
                className="form-control form-control-filter-table"
                placeholder="Filtre"
              />
              <span className="content-icon-filter-table">
                <img
                  src={FilterIcon}
                  alt="filter"
                  className="filter-icon-table"
                />
              </span>
            </div>
          </div>
        </div>
        <RecettesAdminTable />
      </div>
    </div>
  );
};

export default GestionRecettes;

