import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { PaginationResults, PaginationResultsV2, TypeQuery } from "../../type";
import { QueryUrl, useLocationState } from "../../utils";
import { prepareHeaders } from "../user/user.api";
import { Recette, RecetteFormData } from "./recette.type";

export const RecetteApi = createApi({
  reducerPath: "recetteApi",
  tagTypes: [
    "recetteByID",
    "recetteList",
    "recetteByUser",
    "recetteListByUser",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/v1/app/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getRecetteByUser: builder.query<PaginationResultsV2<Recette>, TypeQuery>({
      // post-filter-recettes-by-fabriquant/{fabricant_id}/{type}
      providesTags: ["recetteByUser"],
      query: ({ id, type, ...query }) => ({
        url: `/post-filter-recettes-by-fabriquant/${id}/${type}`,
        method: "POST",
        body: query,
      }),
    }),
    getRecetteListByUser: builder.query<PaginationResults<Recette>, TypeQuery>({
      providesTags: ["recetteListByUser"],
      query: ({ id, type, ...query }) =>
        QueryUrl(
          `/recettes-by-fabricant-without-pagination/${id}/${type}`,
          query
        ),
    }),
    getRecetteList: builder.query<PaginationResultsV2<Recette>, TypeQuery>({
      providesTags: ["recetteList"],
      query: (query) => QueryUrl("recettes/", query),
    }),
    addOrUpdateRecette: builder.mutation<
      { message: string; success: boolean; data: Recette },
      {
        recette_id?: string;
        data: RecetteFormData | FormData;
      }
    >({
      invalidatesTags: ["recetteList", "recetteByUser", "recetteByID"],
      query: ({ recette_id, data }) => {
        if (recette_id) {
          return {
            url: `recettes/${recette_id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `recettes/`,
          method: "POST",
          body: data,
        };
      },
    }),
    getRecetteById: builder.query<
      { message: string; success: boolean; data: Recette },
      TypeQuery
    >({
      query: (id) => `recettes/${id}`,
      providesTags: ["recetteByID"],
    }),
    deleteRecette: builder.mutation<
      { message: string; success: boolean; data: string },
      string
    >({
      query: (recette_id) => ({
        url: `recettes/${recette_id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["recetteList", "recetteByUser", "recetteByID"],
    }),
    exportRecetteByUser: builder.query<
      { message: string; success: boolean; pdf_url: string },
      { fabricant_id: string; type: string }
    >({
      query: ({ fabricant_id, type }) =>
        `export-recettes-by-fabricant/${fabricant_id}/${type}/`,
      // providesTags: ["recetteByID"],
    }),
  }),
});

export const {
  useAddOrUpdateRecetteMutation,
  useGetRecetteListQuery,
  useDeleteRecetteMutation,
  useLazyGetRecetteByIdQuery,
  useGetRecetteByUserQuery,
  useLazyGetRecetteListByUserQuery,
  useLazyExportRecetteByUserQuery
} = RecetteApi;

export function useRecetteFromLocation(): [
  Recette,
  boolean,
  string,
  (id: string) => any
] {
  const itemState = useLocationState<Recette | undefined>(undefined);
  const [item, setItem] = useState(itemState?.recette_id ? itemState : undefined);
  const { id } = useParams<{ id: string }>();
  const [findById, { data, isError, isLoading }] = useLazyGetRecetteByIdQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      findById(id as any);
    }
    console.log("itemState", itemState);
  }, [itemState, id]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate("/fabriquant/recettes", { replace: true });
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      console.log("data", data);
      setItem(data?.data);
    }
  }, [data]);

  return [item as Recette, isLoading, id as string, findById as any];
}