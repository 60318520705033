import React from "react";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { BiCategory } from "react-icons/bi";
import { NavLink, useLocation } from "react-router-dom";
import { HiOutlineUsers } from "react-icons/hi2";
import { MdOutlinePrecisionManufacturing } from "react-icons/md";
import { TbCurling, TbFileAnalytics } from "react-icons/tb";
import { CiSettings } from "react-icons/ci";

const SidebarMobile = () => {
  let location = useLocation();
  return (
    <div>
      <div className="container-nav">
        <input type="checkbox" id="check" />
        <label htmlFor="check">
          <AiOutlineMenuUnfold />
          <i className="fas fa-times" id="cancel"></i>
        </label>
        <div className="sidebar" id="scrollbar-nav-mobile">
          <div className="content-nav-mobile">
            <ul className="nav nav-items-custom flex-column">
              {location?.pathname?.startsWith("/fabriquant") && (
                <>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/fabriquant/dashboard"
                    >
                      <BiCategory />
                      <span className="item-nav-menu drop-header">
                        Dashboard
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/fabriquant/fournisseurs"
                    >
                      <HiOutlineUsers />
                      <span className="item-nav-menu drop-header">
                        Fournisseurs
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/fabriquant/stock-mp"
                    >
                      <TbCurling />
                      <span className="item-nav-menu drop-header">
                        Stock MP
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/fabriquant/recettes"
                    >
                      <TbFileAnalytics />
                      <span className="item-nav-menu drop-header">
                        Recettes
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/fabriquant/fabrication"
                    >
                      <MdOutlinePrecisionManufacturing />
                      <span className="item-nav-menu drop-header">
                        Fabrication
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/fabriquant/stock-pf"
                    >
                      <TbCurling />
                      <span className="item-nav-menu drop-header">
                        Stock PF
                      </span>
                    </NavLink>
                  </li>
                </>
              )}
              {location?.pathname?.startsWith("/admin") && (
                <>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/dashboard"
                    >
                      <BiCategory />
                      <span className="item-nav-menu drop-header">
                        Dashboard
                      </span>
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/utilisateurs"
                    >
                      <HiOutlineUsers />
                      <span className="item-nav-menu drop-header">
                        Clients
                      </span>
                    </NavLink>
                  </li>
                  
                  <li className="nav-item nav-item-vertical-custom">
                    <NavLink
                      className="nav-link nav-link-vertival-custom"
                      to="/admin/parametres"
                    >
                      <CiSettings />
                      <span className="item-nav-menu drop-header">
                        Paramètres
                      </span>
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarMobile;
