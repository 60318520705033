import React from "react";
import { CiSearch } from "react-icons/ci";
import FilterIcon from "../../../../assets/icons/filter icon.svg";
import SavonFabricationAdminTable from "../SavonFabricationAdminTable/SavonFabricationAdminTable";

const CosmeticFabricationAdminTabsItem = ({type}:{type:string}) => {
  return (
    <div className="savon-tabs-component">
      <div className="container-filter-table pb-4">
        <div className="row pb-4">
          <div className="col-md-3 mb-3">
            <div className="form-filter-table position-relative">
              <input
                type="text"
                className="form-control form-control-filter-table"
                placeholder="Filtre"
              />
              <span className="content-icon-filter-table">
                <CiSearch
                  className="filter-icon-table"
                  style={{ color: "#7C97B6" }}
                />
              </span>
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className="form-filter-table position-relative">
              <input
                type="date"
                className="form-control form-control-filter-table"
              />
            </div>
          </div>
          {/* <div className="col-md-3 mb-3">
            <div className="form-filter-table position-relative">
              <input
                type="text"
                className="form-control form-control-filter-table"
                placeholder="Filtre"
              />
              <span className="content-icon-filter-table">
                <img
                  src={FilterIcon}
                  alt="filter"
                  className="filter-icon-table"
                />
              </span>
            </div>
          </div> */}
        </div>
        <div className="container-table-savon">
          <SavonFabricationAdminTable type={type} />
        </div>
      </div>
    </div>
  );
};

export default CosmeticFabricationAdminTabsItem;
