import React from "react";
import "./DashboardAdmin.css";
import CommandeGraph from "./CommandeGraph";

const DashboardAdmin = () => {
  return (
    <div className="dashbord-fabriquant-component">
      <div className="container-graph-dashboard mt-5">
        <div className="col-content-graph">
          <CommandeGraph />
        </div>
      </div>
    </div>
  );
};

export default DashboardAdmin;
