import React from "react";
import SousAdminTable from "../SousAdminTable/SousAdminTable";

const SousAdminTabs = () => {
  return (
    <div className="sous-admin-component">
      <div className=" table-with-checkbox center-admin-table">
        <SousAdminTable />
      </div>
    </div>
  );
};

export default SousAdminTabs;
