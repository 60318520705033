import React from "react";

const DetailFabricantCosmetic = () => {
  return (
    <div className="fournisseur-list-component">
      <div className="container-title-page-admin mb-5">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">
            Détail Fabrication
            <span className="ps-2 nom-de-la-recette">N° de la fabrication</span>
          </h3>
        </div>
      </div>
      <div className="container-detail-recette pb-5">
        <div className="content-detail-recette-item">
          <p className="title-detail-recette-fabrication">
            N° de la fabrication
          </p>
          <div className="info-detail-recette-container pt-4">
            <div className="info-detail-recette-item mb-3">
              <p className="text-libelle-info-detail-recette">
                Nom de la recette:
              </p>
              <p className="text-value-info-detail-recette"> Nom test</p>
            </div>
            <div className="info-detail-recette-item mb-3">
              <p className="text-libelle-info-detail-recette">N° de lot :</p>
              <p className="text-value-info-detail-recette">30</p>
            </div>
            <div className="info-detail-recette-item mb-3">
              <p className="text-libelle-info-detail-recette">
                Date de fabrication:
              </p>
              <p className="text-value-info-detail-recette">22 - 02 - 2023</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailFabricantCosmetic;
