import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { PaginationResults, PaginationResultsV2, TypeQuery } from "../../type";
import { QueryUrl, useLocationState } from "../../utils";
import { prepareHeaders } from "../user/user.api";
import { FournisseurFormData, IFournisseur } from "./fournisseur.type";

export const FournisseurApi = createApi({
  reducerPath: "fournisseurApi",
  tagTypes: ["fournisseurByID", "fournisseurList", "fournisseurListByUser"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/v1/app/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getFournisseurByUser: builder.query<
      PaginationResultsV2<IFournisseur>,
      TypeQuery
    >({
      providesTags: ["fournisseurListByUser"],
      query: ({ id, ...query }) =>
        QueryUrl(`/fournisseurs-by-fabriquant/${id}/`, query),
    }),
    getFournisseurList: builder.query<
      PaginationResults<IFournisseur>,
      TypeQuery
    >({
      providesTags: ["fournisseurList"],
      query: (query) => QueryUrl("fournisseurs/", query),
    }),
    addOrUpdateFournisseur: builder.mutation<
      { message: string; success: boolean; data: IFournisseur },
      {
        fournisseur_id?: string;
        data: FournisseurFormData | FormData;
      }
    >({
      invalidatesTags: [
        "fournisseurList",
        "fournisseurListByUser",
        "fournisseurByID",
      ],
      query: ({ fournisseur_id, data }) => {
        if (fournisseur_id) {
          return {
            url: `fournisseurs/${fournisseur_id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `fournisseurs/`,
          method: "POST",
          body: data,
        };
      },
    }),
    getFournisseurById: builder.query<
      { message: string; success: boolean; data: IFournisseur },
      TypeQuery
    >({
      query: (id) => `fournisseurs/${id}`,
      providesTags: ["fournisseurByID"],
    }),
    deleteFournisseur: builder.mutation<
      { message: string; success: boolean; data: string },
      string
    >({
      query: (fournisseur_id) => ({
        url: `fournisseurs/${fournisseur_id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "fournisseurList",
        "fournisseurListByUser",
        "fournisseurByID",
      ],
    }),
  }),
});

export const {
  useAddOrUpdateFournisseurMutation,
  useGetFournisseurByIdQuery,
  useGetFournisseurByUserQuery,
  useGetFournisseurListQuery,
  useLazyGetFournisseurByIdQuery,
  useDeleteFournisseurMutation
} = FournisseurApi;

export function useFournisseurFromLocation(): [
  IFournisseur,
  boolean,
  string,
  (id: string) => any
] {
  const itemState = useLocationState<IFournisseur | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { id } = useParams<{ id: string }>();
  const [findById, { data, isError, isLoading }] =
    useLazyGetFournisseurByIdQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      findById(id as any);
    }
  }, [itemState, id]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate("/fabriquant/fournisseurs", { replace: true });
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      // console.log("data",data)
      setItem(data?.data);
    }
  }, [data]);

  return [item as IFournisseur, isLoading, id as string, findById as any];
}
