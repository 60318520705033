import React from "react";
import { Outlet } from "react-router-dom";
import "./AdminLayout.css";
import { CgMenuGridO } from "react-icons/cg";
import SidebarAdmin from "../../TableauDeBord/navBar/SidebarAdmin/SidebarAdmin";
import HeaderAdmin from "../../TableauDeBord/navBar/HeaderAdmin/HeaderAdmin";
import VersionChecker from "../../../versionChecker/VersionChecker";
import moment from "moment";
import { useAppSelector } from "../../../redux/hook";
const AdminLayout = () => {
  const {userInfos} = useAppSelector((s) => s?.userInfos)
  return (
    <div className="container-fluid body-background-dashboard">
      <div className="row">
        <input type="checkbox" name="nav" id="nav_button"></input>
        <label htmlFor="nav_button" className="on no-view-mobile">
          <CgMenuGridO className="icon-side-menu" />
        </label>
        <div className="nav-container no-view-mobile">
          <SidebarAdmin />
        </div>
        <div className="container-header">
          <HeaderAdmin />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-0 h-90vh position-relative page-content-base-container">
          <div className="admin-page-container admin-page-container-height">
            <div className="container-title-page-dashbord mb-4">
              <div className="content-title-page-dashboard">
                <h2 className="h2-title-page-dashboard mb-0">
                  Bonjour {userInfos?.first_name} -{" "}
                  <span
                    style={{
                      color: "#71717A",
                      fontWeight: 400,
                      fontSize: 16,
                      paddingLeft: 7,
                    }}
                  >
                    Bienvenue
                  </span>
                </h2>
              </div>
            </div>
            <Outlet />
          </div>
          <div className="footer-admin-conatiner">
            <p className="text-copyright-admin-footer mb-0 ps-4">
              Copyright © {moment(new Date()).format("YYYY")}{" "}
              <span style={{ fontWeight: 700 }}> LOGISAOP</span>
            </p>
            <p className="text-version-footer-admin mb-0">
              Version {VersionChecker()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
