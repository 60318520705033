import React, { useEffect, useState } from "react";
import { BsCurrencyEuro } from "react-icons/bs";
import { MdOutlineAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useCommandeFromLocation } from "../../../utils/api/commande/commande.api";
import { Categories, Names } from "../../../utils/constante";
import ErrorMessage from "../../common/ErrorMessage";
import UseAddDetailsCommande from "./requestForm/UseAddDetailsCommande";

const AjouterCommande = () => {
  const navigate = useNavigate();
  const [item] = useCommandeFromLocation();
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    handleChange,
    handleChangeQty,
    totalPM,
    handleChangeFields,
    total,
    options,
    fields,
  } = UseAddDetailsCommande(item);
  const [inputFields, setInputFields] = useState([
    {
      categorie: "",
      nom: "",
      num_lot: "",
      date_ddm: "",
      date_commande: "",
      quantite: "",
      poids: "",
      unite_poids: "",
      unite_volume: "",
      seuil: "",
      prix_total: "",
    },
  ]);

  useEffect(() => {
    let arr: any = [];
    if (
      item?.details_commande?.details_commande_id &&
      item?.details_commande?.ligne_commandes?.length
    ) {
      let newfield = {
        categorie: "",
        nom: "",
        num_lot: "",
        date_ddm: "",
        date_commande: "",
        quantite: "",
        poids: "",
        unite_poids: "",
        unite_volume: "",
        seuil: "",
        prix_total: "",
      };
      item?.details_commande?.ligne_commandes?.forEach((item) => {
        arr.push(newfield);
      });
      // console.log("arr", arr);
      setInputFields(arr)
    }
  }, [item]);

  const addFields = () => {
    let newfield = {
      categorie: "",
      nom: "",
      num_lot: "",
      date_ddm: "",
      date_commande: "",
      quantite: "",
      poids: "",
      unite_poids: "",
      unite_volume: "",
      seuil: "",
      prix_total: "",
    };
    setInputFields([...inputFields, newfield]);
  };

  return (
    <div className="fournisseur-list-component">
      <div className="container-title-page-admin mb-3">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">Commande {item?.numero_commande}</h3>
        </div>
      </div>
      <div className="container-info-commande-add py-3 px-2">
        <div className="row">
          <div className="col-md-3 mb-3 col-info-commande-add">
            <div className="content-col-info-commande-add">
              <p className="text-libelle-info-commande-add mb-2">
                Numéro de la commande
              </p>
              <p className="text-value-info-commande-add mb-0">
                {item?.numero_commande}
              </p>
            </div>
          </div>
          <div className="col-md-3 mb-3 col-info-commande-add">
            <div className="content-col-info-commande-add">
              <p className="text-libelle-info-commande-add mb-2">Fournisseur</p>
              <p className="text-value-info-commande-add mb-0">
                {item?.fournisseur?.fullname}
              </p>
            </div>
          </div>
          <div className="col-md-3 mb-3 col-info-commande-add">
            <div className="content-col-info-commande-add">
              <p className="text-libelle-info-commande-add mb-2">
                Numéro de client{" "}
              </p>
              <p className="text-value-info-commande-add mb-0">
                {item?.numero_client}
              </p>
            </div>
          </div>
        </div>
      </div>
      <form action="" onSubmit={onSubmit}>
        <div className="table-add-commande-section pb-5">
          <>
            {inputFields?.map((input, i) => {
              return (
                <div className="container-table-add-commande" key={i}>
                  <div className="line-add-commande-item">
                    {i === 0 && (
                      <label
                        htmlFor="category"
                        className="form-label form-label-add-commande"
                      >
                        Catégorie{" "}
                        <span className="text-danger" style={{ fontSize: 15 }}>
                          *
                        </span>
                      </label>
                    )}

                    <select
                      className="form-control form-control-add-commande-select"
                      {...register(`ligne_commandes.${i}.category`)}
                      style={{ width: "85px" }}
                    >
                      <option value="">Sélectionnez</option>
                      {Categories?.map((category, i) => (
                        <option value={category?.value} key={i}>
                          {" "}
                          {category?.label}{" "}
                        </option>
                      ))}
                    </select>
                    {/* <input
                      type="text"
                      className="form-control form-control-add-commande"
                      placeholder="Catégorie"
                      {...register(`ligne_commandes.${i}.category`)}
                    /> */}
                    <ErrorMessage
                      message={
                        errors?.ligne_commandes &&
                        errors?.ligne_commandes[i]?.category &&
                        errors?.ligne_commandes[i]?.category?.message
                      }
                    />
                  </div>
                  <div className="line-add-commande-item">
                    {i === 0 && (
                      <label
                        htmlFor="nom"
                        className="form-label form-label-add-commande"
                      >
                        Nom{" "}
                        <span className="text-danger" style={{ fontSize: 15 }}>
                          *
                        </span>
                      </label>
                    )}

                    {/* <select
                      className="form-control form-control-add-commande-select"
                      {...register(`ligne_commandes.${i}.nom`)}
                    >
                      <option value="">Sélectionnez</option>
                      {Names?.map((name, i) => (
                        <option value={name?.value} key={i}>
                          {" "}
                          {name?.label}{" "}
                        </option>
                      ))}
                    </select> */}
                    <input
                      type="text"
                      className="form-control form-control-add-commande"
                      placeholder="Nom"
                      {...register(`ligne_commandes.${i}.nom`)}
                    />
                    <ErrorMessage
                      message={
                        errors?.ligne_commandes &&
                        errors?.ligne_commandes[i]?.nom &&
                        errors?.ligne_commandes[i]?.nom?.message
                      }
                    />
                  </div>
                  <div className="line-add-commande-item">
                    {i === 0 && (
                      <label
                        htmlFor=""
                        className="form-label form-label-add-commande"
                      >
                        N° Lot{" "}
                        <span className="text-danger" style={{ fontSize: 15 }}>
                          *
                        </span>
                      </label>
                    )}

                    <input
                      type="text"
                      className="form-control form-control-add-commande"
                      placeholder="N° Lot"
                      {...register(`ligne_commandes.${i}.numero_lot`)}
                      style={{ width: "75px" }}
                    />
                    <ErrorMessage
                      message={
                        errors?.ligne_commandes &&
                        errors?.ligne_commandes[i]?.numero_lot &&
                        errors?.ligne_commandes[i]?.numero_lot?.message
                      }
                    />
                  </div>
                  <div className="line-add-commande-item">
                    {i === 0 && (
                      <label
                        htmlFor=""
                        className="form-label form-label-add-commande"
                      >
                        DLUO/DDM{" "}
                        <span className="text-danger" style={{ fontSize: 15 }}>
                          *
                        </span>
                      </label>
                    )}

                    <input
                      type="date"
                      className="form-control form-control-add-commande"
                      {...register(`ligne_commandes.${i}.dluo_ddm`)}
                      style={{ width: "95px" }}
                    />
                    <ErrorMessage
                      message={
                        errors?.ligne_commandes &&
                        errors?.ligne_commandes[i]?.dluo_ddm &&
                        errors?.ligne_commandes[i]?.dluo_ddm?.message
                      }
                    />
                  </div>
                  <div className="line-add-commande-item">
                    {i === 0 && (
                      <label
                        htmlFor=""
                        className="form-label form-label-add-commande"
                      >
                        Date Ouverture
                      </label>
                    )}

                    <input
                      type="date"
                      className="form-control form-control-add-commande"
                      {...register(`ligne_commandes.${i}.date_ouverture`)}
                      style={{ width: "95px" }}
                    />
                    <ErrorMessage
                      message={
                        errors?.ligne_commandes &&
                        errors?.ligne_commandes[i]?.date_ouverture &&
                        errors?.ligne_commandes[i]?.date_ouverture?.message
                      }
                    />
                  </div>
                  <div className="line-add-commande-item">
                    {i === 0 && (
                      <label
                        htmlFor=""
                        className="form-label form-label-add-commande"
                      >
                        Quantité{" "}
                        <span className="text-danger" style={{ fontSize: 15 }}>
                          *
                        </span>
                      </label>
                    )}

                    <input
                      type="number"
                      min={0}
                      name={`quantite`}
                      className="form-control form-control-add-commande"
                      placeholder="Quantité"
                      // step={"any"}
                      value={options[i]?.quantite}
                      onChange={(e) => handleChangeQty(e, i)}
                      style={{ width: "60px" }}
                    />
                    <ErrorMessage
                      message={
                        errors?.ligne_commandes &&
                        errors?.ligne_commandes[i]?.quantite &&
                        errors?.ligne_commandes[i]?.quantite?.message
                      }
                    />
                  </div>
                  <div className="line-add-commande-item">
                    {i === 0 && (
                      <label
                        htmlFor=""
                        className="form-label form-label-add-commande"
                      >
                        Poids
                      </label>
                    )}

                    <input
                      type="number"
                      min={0}
                      name={`poids`}
                      value={options[i]?.poids}
                      onChange={(e) => handleChange(e, i)}
                      // {...register(`ligne_commandes.${i}.poids`)}
                      className="form-control form-control-add-commande"
                      placeholder="Poids"
                      step={"any"}
                      style={{ width: "55px" }}
                    />
                    <ErrorMessage
                      message={
                        errors?.ligne_commandes &&
                        errors?.ligne_commandes[i]?.poids &&
                        errors?.ligne_commandes[i]?.poids?.message
                      }
                    />
                  </div>
                  <div className="line-add-commande-item">
                    {i === 0 && (
                      <label
                        htmlFor=""
                        className="form-label form-label-add-commande"
                      >
                        Unité Poids
                      </label>
                    )}

                    <select
                      className="form-control form-control-add-commande-select"
                      {...register(`ligne_commandes.${i}.unite_poids`)}
                      style={{ width: "75px" }}
                    >
                      <option value="">Unité</option>
                      <option value="g">g</option>
                      <option value="kg">kg</option>
                    </select>
                    <ErrorMessage
                      message={
                        errors?.ligne_commandes &&
                        errors?.ligne_commandes[i]?.unite_poids &&
                        errors?.ligne_commandes[i]?.unite_poids?.message
                      }
                    />
                  </div>
                  <div className="line-add-commande-item">
                    {i === 0 && (
                      <label
                        htmlFor=""
                        className="form-label form-label-add-commande"
                      >
                        Volume
                      </label>
                    )}

                    <input
                      type="number"
                      step={"any"}
                      min={0}
                      name={`volume`}
                      value={options[i]?.volume}
                      onChange={(e) => handleChange(e, i)}
                      // {...register(`ligne_commandes.${i}.volume`)}
                      className="form-control form-control-add-commande"
                      placeholder="Volume"
                      style={{ width: "65px" }}
                    />
                    <ErrorMessage
                      message={
                        errors?.ligne_commandes &&
                        errors?.ligne_commandes[i]?.volumeExist &&
                        errors?.ligne_commandes[i]?.volumeExist?.message
                      }
                    />
                  </div>
                  <div className="line-add-commande-item">
                    {i === 0 && (
                      <label
                        htmlFor=""
                        className="form-label form-label-add-commande"
                      >
                        Unité Volume
                      </label>
                    )}

                    <select
                      className="form-control form-control-add-commande-select"
                      {...register(`ligne_commandes.${i}.unite_volume`)}
                      style={{ width: "75px" }}
                    >
                      <option value="">Unité</option>
                      <option value="ml">mL</option>
                      <option value="l">L</option>
                    </select>
                    <ErrorMessage
                      message={
                        errors?.ligne_commandes &&
                        errors?.ligne_commandes[i]?.unite_volume &&
                        errors?.ligne_commandes[i]?.unite_volume?.message
                      }
                    />
                  </div>
                  <div className="line-add-commande-item">
                    {i === 0 && (
                      <label
                        htmlFor=""
                        className="form-label form-label-add-commande"
                      >
                        Seuil d’alerte{" "}
                        <span className="text-danger" style={{ fontSize: 15 }}>
                          *
                        </span>
                      </label>
                    )}

                    <input
                      type="number"
                      min={0}
                      {...register(`ligne_commandes.${i}.seuil_alerte`)}
                      className="form-control form-control-add-commande"
                      placeholder="Seuil d’alerte"
                    />
                    <ErrorMessage
                      message={
                        errors?.ligne_commandes &&
                        errors?.ligne_commandes[i]?.seuil_alerte &&
                        errors?.ligne_commandes[i]?.seuil_alerte?.message
                      }
                    />
                  </div>
                  <div className="line-add-commande-item">
                    {i === 0 && (
                      <label
                        htmlFor=""
                        className="form-label form-label-add-commande"
                      >
                        Prix par unité{" "}
                        <span className="text-danger" style={{ fontSize: 15 }}>
                          *
                        </span>
                      </label>
                    )}

                    <input
                      type="number"
                      step={"any"}
                      min={0}
                      name={`prix_unite`}
                      value={options[i]?.prix_unite}
                      onChange={(e) => handleChange(e, i)}
                      className="form-control form-control-add-commande"
                      placeholder="Prix par unité"
                    />
                    <ErrorMessage
                      message={
                        errors?.ligne_commandes &&
                        errors?.ligne_commandes[i]?.prix_unite &&
                        errors?.ligne_commandes[i]?.prix_unite?.message
                      }
                    />
                  </div>
                  <div className="line-add-commande-item">
                    {i === 0 && (
                      <label
                        htmlFor=""
                        className="form-label form-label-add-commande"
                      >
                        Prix total{" "}
                        <span className="text-danger" style={{ fontSize: 15 }}>
                          *
                        </span>
                      </label>
                    )}

                    <input
                      type="number"
                      readOnly
                      step={"any"}
                      {...register(`ligne_commandes.${i}.prix_total_ligne`)}
                      className="form-control form-control-add-commande"
                      placeholder="Prix total"
                    />
                    <ErrorMessage
                      message={
                        errors?.ligne_commandes &&
                        errors?.ligne_commandes[i]?.prix_total_ligne &&
                        errors?.ligne_commandes[i]?.prix_total_ligne?.message
                      }
                    />
                  </div>
                </div>
              );
            })}
          </>
          <div className="container-btn-add-line-commande pb-4">
            <button
              className="btn btn-add-line-commande-table"
              onClick={(e: any) => {
                e.preventDefault();
                addFields();
              }}
            >
              <MdOutlineAdd />
              <span className="ps-2">Ajouter une ligne</span>
            </button>
          </div>
          <div className="container-total-add-commande-table">
            <div className="content-total-add-commande-table">
              <h2 className="text-value-info-commande-add">Total MP</h2>
              <div className="total-add-commande-table position-relative">
                {totalPM || 0}
                <span className="unite-totaladd-commande-table">
                  <BsCurrencyEuro />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fournisseur-list">
          <div className="container-filter-table py-4 b-t-1">
            <div className="row mb-3">
              <div className="col-md-3 mb-3">
                <div className="form-filter-table position-relative">
                  <label
                    htmlFor="num"
                    className="form-label form-label-add-commande"
                  >
                    Taxe{" "}
                    <span className="text-danger" style={{ fontSize: 15 }}>
                      *
                    </span>
                  </label>
                  <input
                    type="number"
                    // {...register("taxe")}
                    step={"any"}
                    min={0}
                    value={fields?.taxe}
                    name="taxe"
                    onChange={(e) => handleChangeFields(e)}
                    className="form-control form-control-filter-table"
                    placeholder="Taxes"
                  />
                  <span className="content-icon-filter-table-form">
                    <BsCurrencyEuro />
                  </span>
                </div>
                <ErrorMessage message={errors?.taxe?.message} />
              </div>
              <div className="col-md-3 mb-3">
                <div className="form-filter-table position-relative">
                  <label
                    htmlFor="num"
                    className="form-label form-label-add-commande"
                  >
                    Frais de port{" "}
                    <span className="text-danger" style={{ fontSize: 15 }}>
                      *
                    </span>
                  </label>
                  <input
                    type="number"
                    step={"any"}
                    min={0}
                    // {...register("frais_port")}
                    value={fields?.frais_port}
                    name="frais_port"
                    onChange={(e) => handleChangeFields(e)}
                    className="form-control form-control-filter-table"
                    placeholder="Frais de port"
                  />
                  <span className="content-icon-filter-table-form">
                    <BsCurrencyEuro />
                  </span>
                </div>
                <ErrorMessage message={errors?.frais_port?.message} />
              </div>
              <div className="col-md-3 mb-3">
                <div className="form-filter-table position-relative">
                  <label
                    htmlFor="num"
                    className="form-label form-label-add-commande"
                  >
                    Réduction{" "}
                    <span className="text-danger" style={{ fontSize: 15 }}>
                      *
                    </span>
                  </label>
                  <input
                    type="number"
                    step={"any"}
                    // {...register("reduction")}
                    min={0}
                    name="reduction"
                    value={fields?.reduction}
                    onChange={(e) => handleChangeFields(e)}
                    className="form-control form-control-filter-table"
                    placeholder="Réduction"
                  />
                  <span className="content-icon-filter-table-form">
                    <BsCurrencyEuro />
                  </span>
                </div>
                <ErrorMessage message={errors?.taxe?.message} />
              </div>
            </div>
            <div className="container-form-commande mt-5">
              <div className="content-total-commande">
                <h2 className="title-bloc-total-commande">
                  Total de la commande
                </h2>
                <h2 className="text-total-commande">{total} € </h2>
              </div>
              <div className="content-btn-action-add-commande">
                <div className="content-btn-cancelled">
                  <button
                    className="btn btn-cancelled w-100"
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    Annuler
                  </button>
                </div>
                <div className="content-btn-add-commande">
                  <button
                    className="btn btn-page-title-admin w-100"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>Enregistrer...</span>
                      </>
                    ) : (
                      "Enregistrer la commande"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AjouterCommande;
