import React from "react";
import "./SidebarAdmin.css";
import { BiCategory } from "react-icons/bi";
import { NavLink, useLocation } from "react-router-dom";
import { HiOutlineUsers } from "react-icons/hi2";
import { MdOutlinePrecisionManufacturing } from "react-icons/md";
import { TbCurling, TbFileAnalytics } from "react-icons/tb";
import { CiSettings } from "react-icons/ci";
import { useAppSelector } from "../../../../redux/hook";

const SidebarAdmin = () => {
  let location = useLocation();
  const { userInfos } = useAppSelector((s) => s?.userInfos);
  return (
    <div className="navbar-vertical-customisation-v2 no-view-mobile">
      <div className="hoverable mt-4" id="scrollbar">
        <div className="content">
          <div className="move-left-side-menu"></div>
          <ul className="nav nav-items-custom flex-column">
            {/* Routes fabriquant */}
            {location?.pathname?.startsWith("/fabriquant") && (
              <>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/fabriquant/dashboard"
                    data-tooltip-content="Dashboard"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <BiCategory />
                    </span>
                    <span className="hiddenable-navlink-label">Dashboard</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/fabriquant/fournisseurs"
                    data-tooltip-content="Fournisseurs"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <HiOutlineUsers />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Fournisseurs
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/fabriquant/stock-mp"
                    data-tooltip-content="Stock MP"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <TbCurling />
                    </span>
                    <span className="hiddenable-navlink-label">Stock MP</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/fabriquant/recettes"
                    data-tooltip-content="Recettes"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <TbFileAnalytics />
                    </span>
                    <span className="hiddenable-navlink-label">Recettes</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/fabriquant/fabrication"
                    data-tooltip-content="Fabrication"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <MdOutlinePrecisionManufacturing />
                    </span>
                    <span className="hiddenable-navlink-label">
                      Fabrication
                    </span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/fabriquant/stock-pf"
                    data-tooltip-content="Stock PF"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <TbCurling />
                    </span>
                    <span className="hiddenable-navlink-label">Stock PF</span>
                  </NavLink>
                </li>
              </>
            )}
            {/* Routes fabriquant */}

            {/* Routes admin */}
            {location?.pathname?.startsWith("/admin") && (
              <>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/dashboard"
                    data-tooltip-content="Dashboard"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <BiCategory />
                    </span>
                    <span className="hiddenable-navlink-label">Dashboard</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/utilisateurs"
                    data-tooltip-content="Utilisateurs"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <HiOutlineUsers />
                    </span>
                    <span className="hiddenable-navlink-label">Clients</span>
                  </NavLink>
                </li>
                {userInfos?.email === "sadio.sanghare@volkeno.sn" && (
                  <>
                    <li className="nav-item nav-item-vertical-custom">
                      <NavLink
                        className="nav-link nav-link-vertival-custom with-tooltip-menu"
                        to="/admin/stock"
                        data-tooltip-content="Stock"
                      >
                        <span className="icon-container-nav-link-vertival">
                          <TbCurling />
                        </span>
                        <span className="hiddenable-navlink-label">Stock</span>
                      </NavLink>
                    </li>
                    <li className="nav-item nav-item-vertical-custom">
                      <NavLink
                        className="nav-link nav-link-vertival-custom with-tooltip-menu"
                        to="/admin/recettes"
                        data-tooltip-content="Recettes"
                      >
                        <span className="icon-container-nav-link-vertival">
                          <TbFileAnalytics />
                        </span>
                        <span className="hiddenable-navlink-label">
                          Recettes
                        </span>
                      </NavLink>
                    </li>
                    <li className="nav-item nav-item-vertical-custom">
                      <NavLink
                        className="nav-link nav-link-vertival-custom with-tooltip-menu"
                        to="/admin/fabrication"
                        data-tooltip-content="Fabrication"
                      >
                        <span className="icon-container-nav-link-vertival">
                          <MdOutlinePrecisionManufacturing />
                        </span>
                        <span className="hiddenable-navlink-label">
                          Fabrication
                        </span>
                      </NavLink>
                    </li>
                  </>
                )}

                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="nav-link nav-link-vertival-custom with-tooltip-menu"
                    to="/admin/parametres"
                    data-tooltip-content="Paramètres"
                  >
                    <span className="icon-container-nav-link-vertival">
                      <CiSettings />
                    </span>
                    <span className="hiddenable-navlink-label">Paramètres</span>
                  </NavLink>
                </li>
              </>
            )}
            {/* Routes admin */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SidebarAdmin;
