import React from "react";
import CommandeFournisseurTable from "./CommandeFournisseurTable/CommandeFournisseurTable";

const ComandesFournisseur = () => {
  return (
    <div className="fournisseur-list-component">
      <div className="container-title-page-admin mb-5">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">Commandes</h3>
        </div>
      </div>
      <div className="container-fournisseur-list table-with-checkbox">
        <CommandeFournisseurTable />
      </div>
    </div>
  );
};

export default ComandesFournisseur;
