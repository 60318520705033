/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./StockMPTable.css";
import TableSkeleton from "../../../common/TableSkeleton";
import { BsFileCheckFill } from "react-icons/bs";
import { useGetCommandeLinesByUserQuery } from "../../../../utils/api/commande/commande.api";
import { useAppSelector } from "../../../../redux/hook";
import {
  CommandeItem,
  CommandeLine,
} from "../../../../utils/api/commande/commande.type";
import moment from "moment";
import Pagination from "../../../common/Pagination";
import { FiEdit, FiEye, FiTrash } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { UseDeleteCommandeItem } from "../requestForm/UseEditCommandeLineForm";
import StockDetailsModal from "../modal/StockDetailsModal";

type CustomPaginationProps = {
  nbPages: number | any;
  page: number;
  onChange: (page: number, perPage: number) => any;
  perPage: number;
};
export function CustomPagination({
  nbPages,
  page,
  perPage,
  onChange,
}: CustomPaginationProps) {
  if (parseInt(nbPages) <= 0) return null;
  return (
    <div className="d-flex align-items-baseline justify-content-end">
      {/* <div className="footer-form d-flex mt-3">
				{nbPages > 10 && (
					<select
						className="form-control select-perpage"
						name="limit"
						id="limit"
						value={perPage}
						onChange={(e) => {
							onChange(1, parseInt(e.target.value, 10));
						}}
					>
						<option value="10">10</option>
						<option value="30">30</option>
						<option value="50">50</option>
						<option value="100">100</option>
					</select>
				)}
			</div> */}

      {parseInt(nbPages) > 0 ? (
        <Pagination
          page={page}
          total={nbPages}
          perPage={perPage}
          onPageChange={(page: number) => onChange(page, perPage)}
        />
      ) : null}
    </div>
  );
}
function DeleteItemBtn({ id }: { id: string }) {
  const onDelete = UseDeleteCommandeItem(id);

  return (
    <button
      className="btn btn-action-modal-icon with-tooltip"
      data-tooltip-content="Supprimer"
      onClick={() => onDelete()}
    >
      <FiTrash />
    </button>
  );
}
function StockMPTable({ term }: { term: string }) {
  const [page, setPage] = useState(1);
  // const [isLoading, setIsLoading] = React.useState();
  const { userInfos } = useAppSelector((s) => s?.userInfos);
  const { data = { data: { data: [], total: 0 } }, isLoading } =
    useGetCommandeLinesByUserQuery({
      id: userInfos?.user_id,
      page,
      term: term || null,
    });

  useEffect(() => {
    setPage(1);
  }, [term]);

  const ruptureFormatter = (cell, row: CommandeLine) => {
    // F2994A
    return (
      <p className="price-table-format mb-0">
        <span
          style={{
            color:
              row?.poids_volume_total === 0
                ? "#EB5757"
                : parseInt(row?.seuil_alerte) >= row?.poids_volume_total
                ? "#F2994A"
                : "#50b2d4",
            fontSize: 20,
          }}
        >
          <BsFileCheckFill />{" "}
        </span>
      </p>
    );
  };

  const nameFormatter = (cell, row) => {
    return (
      <>
        <p className="nom-stock mb-1">{cell}</p>
        <span style={{ color: "#667085", fontSize: 14, fontWeight: 400 }}>
          {row?.category}
        </span>
      </>
    );
  };

  const quantiteFormatter = (cell, row) => {
    return (
      <div
        style={{
          color:
            cell === 0
              ? "#EB5757"
              : parseInt(row?.seuil_alerte) >= cell
              ? "#F2994A"
              : "",
          fontSize: 20,
        }}
      >
        {cell + (row?.unite_poids || row?.unite_volume)}
      </div>
    );
  };

  const actionFormatter = (cell, row) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-2 align-items-center">
          <div className="container-btn-action-icon-modal">
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Visualiser"
              data-bs-toggle="modal"
              data-bs-target={`#commandeItemModal${cell}`}
            >
              <FiEye />
            </button>
          </div>
          <div className="container-btn-action-icon-modal">
            <NavLink
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Modifier"
              to={`/fabriquant/modifier-stock/${cell}`}
              state={row}
            >
              <FiEdit />
            </NavLink>
          </div>
          <div className="container-btn-action-icon-modal">
            <DeleteItemBtn id={cell} />
          </div>
        </div>
        <StockDetailsModal item={row} />
      </>
    );
  };

  const columns = [
    {
      dataField: "nom",
      text: "Nom MP",
      formatter: (cell: string, row: CommandeLine) => nameFormatter(cell, row),
      style: { whiteSpace: "nowrap" },
    },
    {
      dataField: "fournisseur",
      text: "Fournisseurs",
      formatter: (cell: string, row: CommandeLine) =>
        row?.details_commande?.commande?.fournisseur?.fullname,
    },
    {
      dataField: "poids_volume_total",
      text: "Quantité en stock",
      formatter: (cell: number, row: CommandeLine) =>
        quantiteFormatter(cell, row),
    },
    // {
    //   dataField: "seuil_alerte",
    //   text: "Stock bas",
    //   style: { color: "#EB5757" },
    // },
    {
      dataField: "seuil_alerte",
      text: "Rupture de stock",
      formatter: (cell: string, row: CommandeLine) =>
        ruptureFormatter(cell, row),
    },
    {
      dataField: "details_commande",
      text: "Date commande",
      formatter: (cell: CommandeItem) =>
        moment(cell?.commande?.date_commande, "DD-MM-YYYY").format(
          "DD/MM/YYYY"
        ),
      headerStyle: () => {
        return { width: "80px", textAlign: "center" };
      },
    },
    {
      dataField: "date_ouverture",
      text: "Date ouverture",
      formatter: (cell: string) =>
        cell ? moment(cell, "DD-MM-YYYY").format("DD/MM/YYYY") : "Non définie",
    },
    {
      dataField: "ligne_commande_id",
      text: "Actions",
      formatter: (cell: string, row: CommandeLine) =>
        actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "80px", textAlign: "center" };
      },
    },
  ];

  useEffect(() => {
    console.log("data?.data?.data", data?.data);
  }, [data?.data?.data]);

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="ligne_commande_id"
            data={data?.data?.data || []}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => "Aucune donnée trouvée"}
          />
          <CustomPagination
            nbPages={data?.data?.total}
            page={page}
            onChange={(page) => {
              setPage(page);
            }}
            perPage={1}
          />
        </>
      )}
    </>
  );
}
export default StockMPTable;
