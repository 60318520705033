import React, { useEffect, useState } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { AppRoutes } from "./routes/router";
import { useAppDispatch, useAppSelector } from "./redux/hook";
import { useMeQuery, useRefreshTokenMutation } from "./utils/api/user/user.api";
import { onlogout, onSetUser, onSetUserToken } from "./redux/slice/User.slice";
import { JwtPayload } from "jwt-decode";
import moment from "moment";
import jwtDecode from "jwt-decode";

function FetchUser() {
  const { isSuccess, data } = useMeQuery();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isSuccess) {
      // console.log(data);
      dispatch(onSetUser(data));
    }
  }, [isSuccess, data]);

  return null;
}

function CheckToken() {
  const { token, userInfos } = useAppSelector((s) => s.userInfos);
  const [refreshToken, { isError, isSuccess, data, isLoading }] =
    useRefreshTokenMutation();
  const dispatch = useAppDispatch();
  const [inProgress, setInProgress] = useState(false);
  useEffect(() => {
    if (token && userInfos?.user_id) {
      let { exp } = jwtDecode(token) as JwtPayload;
      
      if (exp) {
        
        if (exp < moment().unix()) {
          dispatch(onlogout());
          // window.location.pathname = "/";
        } else {
          const expireDate = moment.unix(exp);
          const rest = expireDate.diff(moment(), "minutes");
          // console.log("expired dans", rest);

          if (rest <= 10 && !isLoading && !inProgress) {
            try {
              // call refresh token to renew the token
              setInProgress(true);
              refreshToken();
            } catch (ex) {
              console.log("error", ex);
              // return false;
            }
          }
        }
      }
    }
  });

  useEffect(() => {
    if (isSuccess) {
      console.log(data, "done");
      if (data?.access_token) {
        dispatch(
          onSetUserToken({ userInfos: data?.user, token: data?.access_token })
        );
      }

      setTimeout(() => {
        setInProgress(false);
      }, 1000);
    }
  }, [isError, isSuccess]);

  return null;
}

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname, location.state]);
  return null;
}
function App() {
  const routes = useRoutes(AppRoutes);
  const isConnected = useAppSelector((s) => !!s.userInfos?.token);
  return (
    <div className="app-routing-container" data-testid="location-display">
      {<CheckToken />}
      {isConnected && <FetchUser />}
      <ScrollToTop />
      {routes}
    </div>
  );
}

export default App;
