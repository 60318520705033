import React from "react";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../common/ErrorMessage";
import PhoneInput from "react-phone-input-2";
import UseCrudFournisseurForm from "./requestForm/UseCrudFournisseurForm";
import GoogleInput from "../../common/GoogleInput";
import { useFournisseurFromLocation } from "../../../utils/api/fournisseur/fournisseur.api";

const AddFournisseur = () => {
  const [item] = useFournisseurFromLocation()
  const navigate = useNavigate();
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    phone,
    handleChangePhone,
    onChangeAddress,
    address,
  } = UseCrudFournisseurForm(item);
  return (
    <div className="list-monitors-admin">
      <div className="container-title-page-admin">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">
            {" "}
            {item?.fournisseur_id
              ? "Modification d'un fournisseur"
              : "Nouveau fournisseur"}{" "}
          </h3>
        </div>
      </div>
      <div className="container-form-add">
        <form onSubmit={onSubmit}>
          <div className="row row-add">
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <input
                  className="form-control form-control-add-form"
                  type="text"
                  placeholder="Nom*"
                  {...register("fullname")}
                />
                <ErrorMessage message={errors?.fullname?.message} />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <input
                  className="form-control form-control-add-form"
                  type="email"
                  placeholder="Mail"
                  {...register("email")}
                />
                <ErrorMessage message={errors?.email?.message} />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <GoogleInput
                  className="form-control form-control-add-form"
                  value={address}
                  onChange={onChangeAddress}
                />
                <ErrorMessage message={errors?.address?.message} />
              </div>
            </div>

            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <input
                  className="form-control form-control-add-form"
                  type="text"
                  placeholder="Nom du commercial"
                  {...register("nom_commercial")}
                />
                <ErrorMessage message={errors?.nom_commercial?.message} />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                {/* <input
                  className="form-control form-control-add-form"
                  type="text"
                  placeholder="Téléphone"
                /> */}
                <PhoneInput
                  containerClass="mb-3 p-0"
                  inputClass="form-control form-control-add-form"
                  country={"fr"}
                  data-testid="phoneId"
                  placeholder=""
                  inputProps={{
                    name: "telephone",
                    required: true,
                    autoFocus: true,
                  }}
                  countryCodeEditable={false}
                  enableAreaCodes={true}
                  prefix="+"
                  value={phone}
                  onChange={(phone, country) =>
                    handleChangePhone(phone, country)
                  }
                  inputStyle={{
                    width: "100%",
                    paddingBottom: "30px",
                    paddingTop: "30px",
                  }}
                />
                <ErrorMessage message={errors?.phone?.message} />
              </div>
            </div>

            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <input
                  className="form-control form-control-add-form"
                  type="text"
                  placeholder="Minimum de commande"
                  {...register("minimum_commande")}
                />
                <ErrorMessage message={errors?.minimum_commande?.message} />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <input
                  className="form-control form-control-add-form"
                  type="text"
                  placeholder="Franco de port"
                  {...register("franco_port")}
                />
                <ErrorMessage message={errors?.franco_port?.message} />
              </div>
            </div>
          </div>
          <div className="row row-content-btn-action py-5">
            <div className="col-md-4 offset-md-4">
              <button
                className="btn btn-cancelled w-100"
                onClick={() => navigate(-1)}
              >
                Annuler
              </button>
            </div>
            <div className="col-md-4">
              <button
                className="btn btn-page-title-admin w-100"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>Enregistrer...</span>
                  </>
                ) : (
                  "Enregistrer"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddFournisseur;
