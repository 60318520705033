import React from "react";
import "./ParametreAdmin.css";
import { NavLink } from "react-router-dom";
import { MdOutlineAdd } from "react-icons/md";
import { useStepType } from "../../../utils/type";
import { useStep } from "react-hooks-helper";
import SousAdminTabs from "./ParametreTabs/SousAdminTabs";
import CguTabs from "./ParametreTabs/CguTabs";
import ConfidentialiteTabs from "./ParametreTabs/ConfidentialiteTabs";
import { useAppSelector } from "../../../redux/hook";
import { Permission } from "../../../utils/api/user/user.type";

const steps = [
  { id: "sous-admin", Component: SousAdminTabs },
  { id: "cosmotique", Component: CguTabs },
  { id: "confidentialite", Component: ConfidentialiteTabs },
];
const tabs = ["Sous-admin", ];

const ParametreAdmin = () => {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const { userInfos } = useAppSelector((s) => s?.userInfos);
  return (
    <div className="fournisseur-list-component">
      <div className="container-title-page-admin mb-5">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">Paramètres</h3>
        </div>
        <div className="content-btn-action-title-page-admin">
          {index === 0 && (
              <>
                <NavLink
                  to={"/admin/ajouter-admin"}
                  className="btn btn-page-title-admin"
                >
                  <MdOutlineAdd />
                  <span className="ps-2">Ajouter un sous-admin</span>
                </NavLink>
              </>
            )}
          {index === 1 &&
            !!userInfos?.permissions?.some(
              (item: Permission) => item?.name === "edit_cgu"
            ) && (
              <>
                <NavLink
                  to="/admin/ajouter-cgu"
                  className="btn btn-page-title-admin"
                >
                  <MdOutlineAdd />
                  <span className="ps-2">Mettre à jour</span>
                </NavLink>
              </>
            )}
          {index === 2 &&
            !!userInfos?.permissions?.some(
              (item: Permission) => item?.name === "edit_conf"
            ) && (
              <>
                <NavLink
                  to="/admin/ajouter-politique-de-confidentialite"
                  className="btn btn-page-title-admin"
                >
                  <MdOutlineAdd />
                  <span className="ps-2">Mettre à jour</span>
                </NavLink>
              </>
            )}
        </div>
      </div>
      <div className="container-fournisseur-list">
        <div className="container-ul-recette">
          <ul className="tabs-container">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
        </div>
        <div className="tabs-component-container">
          <Component />
        </div>
      </div>
    </div>
  );
};

export default ParametreAdmin;
