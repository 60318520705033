import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { FiEye } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { useGetRecetteListQuery } from "../../../../utils/api/recette/recette.api";
import { Recette } from "../../../../utils/api/recette/recette.type";
import TableSkeleton from "../../../common/TableSkeleton";
import { CustomPagination } from "../../../TableauDeBord/StockMP/StockMPTable/StockMPTable";
import RecettesAdminTableData from "./RecettesAdminTableData";

function RecettesAdminTable() {
  const [page, setPage] = useState(1);
  const { data = { data: {data:[],total:0} }, isLoading } = useGetRecetteListQuery({ page });
  // const [isLoading, setIsLoading] = React.useState();

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  const priceFormatter = (cell) => {
    return (
      <>
        <p className="price-table-format mb-0">{cell} €</p>
      </>
    );
  };

  const poidsFormatter = (cell) => {
    return (
      <>
        <p className="price-table-format mb-0">{cell} Kg</p>
      </>
    );
  };

  const actionFormatter = (cell: string, row: Recette) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-2 align-items-center">
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/recette/${cell}`}
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Visualiser"
              state={row}
            >
              <FiEye />
            </NavLink>
          </div>

        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "nom_recette",
      text: "Nom de la recette",
    },
    {
      dataField: "type_recette",
      text: "Catégorie",
    },
    {
      dataField: "code_interne_recette",
      text: "Code interne",
    },
    {
      dataField: "poids_total",
      text: "Poids",
      formatter: (cell: number) => poidsFormatter(cell),
    },
    {
      dataField: "cout_fabrication_total",
      text: "Coût total",
      formatter: (cell: number) => priceFormatter(cell),
    },
    {
      dataField: "recette_id",
      text: "Actions",
      formatter: (cell: string, row: Recette) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="recette_id"
            data={data?.data?.data}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => "Aucune donnée trouvée"}
          />
          <CustomPagination
            nbPages={data?.data?.total}
            page={page}
            onChange={(page) => {
              setPage(page);
            }}
            perPage={10}
          />
        </>
      )}
    </>
  );
}

export default RecettesAdminTable;
