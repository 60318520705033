type ENV = "dev" | "prod" | "demo";

export const currentEnv: ENV = "prod";

const env: ENV = currentEnv;

export const Env = env;

const API_DEV_URL = "https://logisoap-api.withvolkeno.com";
const API_DEMO_URL = "https://logisoap-api.withvolkeno.com";
const API_PROD_URL = "https://api.beta.logisoap.com";

function processApiUrl() {
  if (env === "prod") return API_PROD_URL;
  if (env === "demo") return API_DEMO_URL;
  return API_DEV_URL;
}

export const ApiBaseUrl = processApiUrl();
