import React from "react";
import { useFabricationFromLocation } from "../../../../utils/api/fabrication/fabrication.api";
import { onFormatDate } from "../../../../utils/utils";
import BootstrapTable from "react-bootstrap-table-next";
import { RecetteFabrication } from "../../../../utils/api/fabrication/fabrication.type";
import SuiviCureTable from "../AddNewFabrication/SuiviCureTable/SuiviCureTable";

const DetailFabricantSavon = () => {
  const [item] = useFabricationFromLocation();

  const columns = [
    {
      dataField: "categorie",
      text: "Catégorie",
      formatter: (cell, row: RecetteFabrication, rowIndex) => {
        let recetteMP = item?.recette?.recette_matiere_premieres?.find(
          (el, i) =>
            el?.recette_matiere_premiere_id ===
              row?.recette_matiere_premiere_id || i === rowIndex
        );
        if (recetteMP) {
          return recetteMP?.categorie;
        }

        return "";
      },
    },
    {
      dataField: "nom",
      text: "Nom MP",
      formatter: (cell, row: RecetteFabrication, rowIndex) => {
        let recetteMP = item?.recette?.recette_matiere_premieres?.find(
          (el, i) =>
            el?.recette_matiere_premiere_id ===
              row?.recette_matiere_premiere_id || i === rowIndex
        );
        if (recetteMP) {
          return recetteMP?.ligne_commande?.nom;
        }

        return "";
      },
    },
    {
      dataField: "numero_lot",
      text: "N°Lot",
      formatter: (cell, row: RecetteFabrication, rowIndex) => {
        let recetteMP = item?.recette?.recette_matiere_premieres?.find(
          (el, i) =>
            el?.recette_matiere_premiere_id ===
              row?.recette_matiere_premiere_id || i === rowIndex
        );
        if (recetteMP) {
          return recetteMP?.ligne_commande?.numero_lot;
        }

        return "";
      },
    },
    {
      dataField: "pourcentage",
      text: "% dans la recette",
      formatter: (cell, row: RecetteFabrication, rowIndex) => {
        let recetteMP = item?.recette?.recette_matiere_premieres?.find(
          (el, i) =>
            el?.recette_matiere_premiere_id ===
              row?.recette_matiere_premiere_id || i === rowIndex
        );
        if (recetteMP) {
          return recetteMP?.pourcentage + "%";
        }

        return "";
      },
    },
    {
      dataField: "poids",
      text: "Poids théorique",
      formatter: (cell, row: RecetteFabrication, rowIndex) => {
        let recetteMP = item?.recette?.recette_matiere_premieres?.find(
          (el, i) =>
            el?.recette_matiere_premiere_id ===
              row?.recette_matiere_premiere_id || i === rowIndex
        );
        if (recetteMP) {
          return (
            parseFloat(recetteMP?.poids) *
              parseInt(item?.fab_coefficient_multiplicateur) +
            recetteMP?.unite_poids
          );
        }

        return "";
      },
    },
    {
      dataField: "pesee_reelle",
      text: "Pesée réelle",
      formatter: (cell, row: RecetteFabrication) => cell + row?.unite_poids,
    },
  ];
  return (
    <div className="fournisseur-list-component">
      <div className="container-title-page-admin mb-5">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">
            Détail Fabrication
            <span className="ps-2 nom-de-la-recette">
              N°:{item?.numero_fabrication}
            </span>
          </h3>
        </div>
      </div>
      <div className="container-detail-recette pb-5">
        <div className="content-detail-recette-item">
          <p className="title-detail-recette-fabrication">
            N°:{item?.numero_fabrication}
          </p>
          <div className="info-detail-recette-container pt-4 row">
            <div className="col-md-12">
              <h3 className="title-form-add py-4">Produit</h3>
            </div>
            <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
              <p className="text-libelle-info-detail-recette">
                Nom de la recette:
              </p>
              <p className="text-value-info-detail-recette">
                {" "}
                {item?.recette?.nom_recette}
              </p>
            </div>
            <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
              <p className="text-libelle-info-detail-recette">
                Type de produit :
              </p>
              <p className="text-value-info-detail-recette">
                {item?.type_produit}
              </p>
            </div>
            <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
              <p className="text-libelle-info-detail-recette">Code interne :</p>
              <p className="text-value-info-detail-recette">
                {item?.code_interne}
              </p>
            </div>
            <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
              <p className="text-libelle-info-detail-recette">N° Lot :</p>
              <p className="text-value-info-detail-recette">
                {item?.numero_lot}
              </p>
            </div>
            <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
              <p className="text-libelle-info-detail-recette">
                Date de fabrication:
              </p>
              <p className="text-value-info-detail-recette">
                {" "}
                {onFormatDate(item?.date_fabrication)}{" "}
              </p>
            </div>
            <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
              <p className="text-libelle-info-detail-recette">DLUO / DDM PF:</p>
              <p className="text-value-info-detail-recette">
                {" "}
                {onFormatDate(item?.dluo_ddm_pf)}{" "}
              </p>
            </div>
            <div className="col-md-12">
              <h3 className="title-form-add py-4">Environnement</h3>
            </div>
            <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
              <p className="text-libelle-info-detail-recette">
                Température labo:
              </p>
              <span className="text-value-info-detail-recette">
                {" "}
                {item?.env_temperature_labo
                  ? item?.env_temperature_labo + " C°"
                  : "Non définie"}
              </span>
            </div>

            <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
              <p className="text-libelle-info-detail-recette">
                Hygrométrie labo:
              </p>
              <span className="text-value-info-detail-recette">
                {" "}
                {item?.env_hygrometrie_labo
                  ? item?.env_hygrometrie_labo + "C°"
                  : "Non définie"}
              </span>
            </div>

            <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
              <p className="text-libelle-info-detail-recette">Autres:</p>
              <span className="text-value-info-detail-recette">
                {" "}
                {item?.env_autre}
              </span>
            </div>

            <div className="col-md-12">
              <h3 className="title-form-add py-4">Fabrication</h3>
            </div>
            <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
              <p className="text-libelle-info-detail-recette">
                Coefficient multiplicateur :
              </p>
              <span className="text-value-info-detail-recette">
                {" "}
                {item?.fab_coefficient_multiplicateur}
              </span>
            </div>

            <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
              <p className="text-libelle-info-detail-recette">
                Moules utilisés :
              </p>
              <span className="text-value-info-detail-recette">
                {" "}
                {item?.fab_moules_utilises || ""}
              </span>
            </div>

            <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
              <p className="text-libelle-info-detail-recette">
                Matériel utilisé :
              </p>
              <span className="text-value-info-detail-recette">
                {" "}
                {item?.fab_materiel_utilise || ""}
              </span>
            </div>

            <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
              <p className="text-libelle-info-detail-recette">
                Choix + Concentration:
              </p>
              <span className="text-value-info-detail-recette">
                {" "}
                {item?.fab_acocher || ""} {item?.fab_acocher_concentration} %
              </span>
            </div>

            <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
              <p className="text-libelle-info-detail-recette">
                Température des corps gras:
              </p>
              <span className="text-value-info-detail-recette">
                {" "}
                {/* {item?.fab_temperature_corps_gras || ""}  */}
                {item?.fab_temperature_corps_gras
                  ? item?.fab_temperature_corps_gras + " C°"
                  : "Non définie"}
              </span>
            </div>

            <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
              <p className="text-libelle-info-detail-recette">
                Température de la soude:
              </p>
              <span className="text-value-info-detail-recette">
                {" "}
                {/* {item?.fab_temperature_soude || ""} C° */}
                {item?.fab_temperature_soude
                  ? item?.fab_temperature_soude + " C°"
                  : "Non définie"}
              </span>
            </div>

            <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
              <p className="text-libelle-info-detail-recette">
                Additif ajouté dans:
              </p>
              <span className="text-value-info-detail-recette">
                {" "}
                {item?.fab_additif_ajoute_dans?.length
                  ? item?.fab_additif_ajoute_dans?.map((el) => el)
                  : item?.fab_additif_ajoute_dans}
              </span>
            </div>
            <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
              <p className="text-libelle-info-detail-recette">Surgras:</p>
              <span className="text-value-info-detail-recette">
                {" "}
                {item?.fab_surgras || ""} %
              </span>
            </div>

            <div className="col-md-12">
              <h3 className="title-form-add py-4">Recette</h3>
            </div>
            <div className="col-md-12 mb-3">
              <BootstrapTable
                keyField="recette_fabrication_id"
                data={item?.recette_fabrications || []}
                columns={columns}
                bordered={true}
                condensed={false}
                responsive={true}
                wrapperClasses="table-responsive admin-table"
                noDataIndication={() => "Aucune donnée trouvée"}
              />
            </div>
            {item?.type_produit === "savon" && (
              <>
                <div className="col-md-12">
                  <h3 className="title-form-add py-4">Cure</h3>
                </div>
                <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
                  <p className="text-libelle-info-detail-recette">
                    Temps de cure prévu:
                  </p>
                  <span className="text-value-info-detail-recette">
                    {" "}
                    {item?.temps_cure_prevu || ""} semaines
                  </span>
                </div>

                <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
                  <p className="text-libelle-info-detail-recette">
                    temps de cure restant:
                  </p>
                  <span className="text-value-info-detail-recette">
                    {" "}
                    {item?.temps_cure_restant || ""} semaines
                  </span>
                </div>

                <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
                  <p className="text-libelle-info-detail-recette">
                    Temps de cure restant:
                  </p>
                  <span className="text-value-info-detail-recette">
                    {" "}
                    {item?.temps_cure_restant || ""} semaines
                  </span>
                </div>

                <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
                  <p className="text-libelle-info-detail-recette">
                    Date de mise en cure:
                  </p>
                  <span className="text-value-info-detail-recette">
                    {" "}
                    {item?.mise_encure_date
                      ? onFormatDate(item?.mise_encure_date)
                      : ""}{" "}
                  </span>
                </div>

                <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
                  <p className="text-libelle-info-detail-recette">
                    Poids du lot:
                  </p>
                  <span className="text-value-info-detail-recette">
                    {" "}
                    {item?.mise_encure_poids_lot || ""}{" "}
                  </span>
                </div>

                <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
                  <p className="text-libelle-info-detail-recette">
                    Contrôle olfactif:
                  </p>
                  <span className="text-value-info-detail-recette">
                    {" "}
                    {item?.mise_encure_controle_olfactif || ""}{" "}
                  </span>
                </div>

                <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
                  <p className="text-libelle-info-detail-recette">
                    Contrôle visuel:
                  </p>
                  <span className="text-value-info-detail-recette">
                    {" "}
                    {item?.mise_encure_controle_visuel || ""}{" "}
                  </span>
                </div>

                <div className="info-detail-recette-item mb-3 col-md-12 mb-3">
                  <p className="text-libelle-info-detail-recette">
                    Observations:
                  </p>
                  <span className="text-value-info-detail-recette">
                    {" "}
                    {item?.mise_encure_observations || ""}{" "}
                  </span>
                </div>
                <div className="col-md-12">
                  <h3 className="title-form-add py-4">
                    Cure (Suivi échantillon témoin)
                  </h3>
                </div>
                <div className="col-md-12">
                  <SuiviCureTable
                    data={item?.suivi_echantillon_temoins || []}
                  />
                </div>
                <div className="col-md-12">
                  <h3 className="title-form-add py-4">Sortie de cure</h3>
                </div>
                <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
                  <p className="text-libelle-info-detail-recette">
                    Date de sortie de cure:
                  </p>
                  <span className="text-value-info-detail-recette">
                    {" "}
                    {item?.sortie_cure_date
                      ? onFormatDate(item?.sortie_cure_date)
                      : ""}{" "}
                  </span>
                </div>
                <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
                  <p className="text-libelle-info-detail-recette">
                    Contrôle olfactif:
                  </p>
                  <span className="text-value-info-detail-recette">
                    {" "}
                    {item?.sortie_cure_controle_olfactif ? "OK" : "NON OK"}{" "}
                  </span>
                </div>
                <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
                  <p className="text-libelle-info-detail-recette">
                    Contrôle visuel:
                  </p>
                  <span className="text-value-info-detail-recette">
                    {" "}
                    {item?.sortie_cure_controle_visuel ? "OK" : "NON OK"}{" "}
                  </span>
                </div>
                <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
                  <p className="text-libelle-info-detail-recette">
                    Poids échantillon témoin:
                  </p>
                  <span className="text-value-info-detail-recette">
                    {" "}
                    {item?.sortie_cure_poids_echantillon_temoin}{" "}
                  </span>
                </div>
                <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
                  <p className="text-libelle-info-detail-recette">PH:</p>
                  <span className="text-value-info-detail-recette">
                    {" "}
                    {item?.sortie_cure_ph}{" "}
                  </span>
                </div>

                <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
                  <p className="text-libelle-info-detail-recette">PH:</p>
                  <span className="text-value-info-detail-recette">
                    {" "}
                    {item?.sortie_cure_ph}{" "}
                  </span>
                </div>

                <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
                  <p className="text-libelle-info-detail-recette">
                    Nombre de produits crées:
                  </p>
                  <span className="text-value-info-detail-recette">
                    {" "}
                    {item?.sortie_cure_nombre_produits_crees}{" "}
                  </span>
                </div>

                <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
                  <p className="text-libelle-info-detail-recette">
                    Nombre de produits conformes:
                  </p>
                  <span className="text-value-info-detail-recette">
                    {" "}
                    {item?.sortie_cure_nombre_produits_conformes}{" "}
                  </span>
                </div>

                <div className="info-detail-recette-item mb-3 col-md-4 mb-3">
                  <p className="text-libelle-info-detail-recette">
                    Nombre de produits non conformes:
                  </p>
                  <span className="text-value-info-detail-recette">
                    {" "}
                    {item?.sortie_cure_nombre_produits_nonconformes}{" "}
                  </span>
                </div>
                <div className="info-detail-recette-item mb-3 col-md-12 mb-3">
                  <p className="text-libelle-info-detail-recette">
                    Observations:
                  </p>
                  <span className="text-value-info-detail-recette">
                    {" "}
                    {item?.sortie_cure_observations}{" "}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailFabricantSavon;
