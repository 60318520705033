import React, { useEffect } from "react";
import "./GestionFabrication.css";
import { NavLink } from "react-router-dom";
import { MdOutlineAdd } from "react-icons/md";
import { useStepType } from "../../../utils/type";
import { useStep } from "react-hooks-helper";
import CosmeticFabricationTabsItem from "./FabricationTabs/CosmeticFabricationTabsItem";
import SavonFabricationTabsItem from "./FabricationTabs/SavonFabricationTabsItem";
import { useLazyExportFabricationByUserQuery } from "../../../utils/api/fabrication/fabrication.api";
import { useAppSelector } from "../../../redux/hook";
import Swal from "sweetalert2";
import { TiExport } from "react-icons/ti";

const steps = [
  { id: "savon", Component: SavonFabricationTabsItem },
  { id: "cosmetique", Component: CosmeticFabricationTabsItem },
];
const tabs = ["Savon", "Cosmétique"];

const GestionFabrication = () => {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const { userInfos } = useAppSelector((s) => s?.userInfos);
  const [exportData, { isLoading, isSuccess, isError, error, data }] =
    useLazyExportFabricationByUserQuery();
  const props = {
    type: step?.id,
  };

  useEffect(() => {
    if (isSuccess) {
      if (data?.success) {
        window.open(data?.pdf_url, "_blank");
      } else {
        Swal.fire({
          icon: "error",
          title:
            data?.message ||
            "Nous n'avons pas pu exporter vos données. Veuillez rééssayer plutard!",
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }

    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isSuccess, isError]);
  return (
    <div className="fournisseur-list-component">
      <div className="container-title-page-admin mb-5">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">Liste des fabrications</h3>
        </div>
        <div className="content-btn-action-title-page-admin">
          <button
            className="btn btn-page-title-admin"
            onClick={() =>
              exportData({ fabricant_id: userInfos?.user_id, type: step?.id })
            }
            disabled={isLoading}
          >
            {!isLoading && (
              <>
                <TiExport />
                <span className="ps-2">Exporter en PDF</span>
              </>
            )}
            {!!isLoading && (
              <>
                <span
                  className="spinner-border spinner-border-sm me-1 d-inline-block"
                  role="status"
                ></span>
                <span>Exportation...</span>
              </>
            )}
          </button>
        </div>
        <div className="content-btn-action-title-page-admin">
          <NavLink
            to="/fabriquant/fabriquer"
            className="btn btn-page-title-admin"
          >
            <MdOutlineAdd />
            <span className="ps-2">Fabriquer</span>
          </NavLink>
        </div>
      </div>
      <div className="container-fournisseur-list">
        <div className="container-ul-recette">
          <ul className="tabs-container">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
        </div>
        <div className="tabs-component-container">
          <Component {...props} />
        </div>
      </div>
    </div>
  );
};

export default GestionFabrication;
