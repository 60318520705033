/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleannerError } from "../../../../utils/utils";
import {
  CommandeFormData,
  ICommandes,
} from "../../../../utils/api/commande/commande.type";
import { useAppSelector } from "../../../../redux/hook";
import { useAddOrUpdateCommandeMutation } from "../../../../utils/api/commande/commande.api";
import { Color } from "../../../../utils/theme";
import { useGetFournisseurByUserQuery } from "../../../../utils/api/fournisseur/fournisseur.api";

function UseCrudCommandeForm(commande?: ICommandes) {
  const validationSchema = yup.object().shape({
    numero_commande: yup.string().required().label("Le N° de la commande"),
    fournisseur_id: yup.string().required().label("Le fournisseur").nullable(),
    numero_client: yup.string().required().label("Le N° du client").nullable(),
    date_commande: yup.string().required().label("La date"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    clearErrors,
  } = useForm<CommandeFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [option, setOption] = useState<unknown>(null);
  const [options, setOptions] = useState<unknown[]>([]);
  const navigate = useNavigate();
  const { userInfos } = useAppSelector((s) => s?.userInfos);
  const { data: fournisseurs = { data: { data: [] }, count: 0 } } =
    useGetFournisseurByUserQuery({ id: userInfos?.user_id });
  const [sendData, { isLoading, isSuccess, error, isError, data }] =
    useAddOrUpdateCommandeMutation();

  useEffect(() => {
    if (fournisseurs?.data?.data?.length) {
      console.log("fourn",fournisseurs?.data)
      setOptions(
        fournisseurs?.data?.data?.map((el) => {
          return {
            label: el?.fullname,
            value: el?.fournisseur_id,
            ...el,
          };
        })
      );
    }
  }, [fournisseurs?.data?.data]);

  const handleChange = (selected: any) => {
    setValue("fournisseur_id", selected?.value);
    setValue("numero_client", selected?.numero_client);

    setOption(selected);
  };

  useEffect(() => {
    if (commande?.commande_id) {
      const fields: (keyof CommandeFormData)[] = [
        "fournisseur_id",
        "numero_commande",
        "date_commande",
        "numero_client",
      ];
      for (let field of fields) {
        register(field);
        setValue(field, commande[field]);
      }
    }
  }, [commande]);

  useEffect(() => {
    if (isSuccess) {
      if (data?.success) {
        Swal.fire({
          icon: "success",
          iconColor: Color.success,
          confirmButtonColor: Color.success,
          title: data?.message,
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          if (userInfos?.status === "fabricant") {
            navigate(
              `/fabriquant/ajouter-commande/${data?.data?.commande_id}`,
              {
                state: data?.data,
                replace: true,
              }
            );
          } else {
            navigate("/admin/ajouter-commande");
          }
        });
      } else {
        // setError("password", { message: data?.message });
        Swal.fire({
          icon: "error",
          title: data?.message,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSubmit = async (data: CommandeFormData) => {
    if (!commande) {
      data["fabriquant_id"] = userInfos?.user_id;
    }
    console.log("data", data);
    await sendData({ commande_id: commande?.commande_id, data: data });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    Controller,
    control,
    handleChange,
    option,
    options,
  };
}

export default UseCrudCommandeForm;
