export const Categories = [
  {
    label: "Additif",
    value: "Additif",
  },
  {
    label: "Corps gras",
    value: "Corps gras",
  },
  {
    label: "Liquide",
    value: "Liquide",
  },
  {
    label: "Soude",
    value: "Soude",
  },
  {
    label: "Autre",
    value: "Autre",
  },
];

export const Names = [
  {
    label: "Amande",
    value: "Amande",
  },
  {
    label: "Cacao",
    value: "Cacao",
  },
  {
    label: "Coco",
    value: "Coco",
  },
  {
    label: "Colza",
    value: "Colza",
  },
  {
    label: "Karité",
    value: "Karité",
  },
  {
    label: "Olive",
    value: "Olive",
  },
  {
    label: "Ricin",
    value: "Ricin",
  },
  {
    label: "Tournesol",
    value: "Tournesol",
  },
];