import React from "react";
import { CgMenuGridO } from "react-icons/cg";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "../../../redux/hook";
import HeaderAdmin from "../navBar/HeaderAdmin/HeaderAdmin";
import SidebarAdmin from "../navBar/SidebarAdmin/SidebarAdmin";
import "./FabriquantLayout.css";
const FabriquantLayout = () => {
  const { userInfos } = useAppSelector((s) => s?.userInfos);
  return (
    <div className="container-fluid body-background-dashboard">
      <div className="row">
        <input type="checkbox" name="nav" id="nav_button"></input>
        <label htmlFor="nav_button" className="on no-view-mobile">
          <CgMenuGridO className="icon-side-menu" />
        </label>
        <div className="container-header">
          <HeaderAdmin />
        </div>
        <div className="nav-container no-view-mobile">
          <SidebarAdmin />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-0 h-90vh position-relative page-content-base-container">
          <div className="admin-page-container">
            <div className="container-title-page-dashbord mb-4">
              <div className="content-title-page-dashboard">
                <h2 className="h2-title-page-dashboard mb-0">
                  Bonjour {userInfos?.first_name} -{" "}
                  <span
                    style={{
                      color: "#71717A",
                      fontWeight: 400,
                      fontSize: 16,
                      paddingLeft: 7,
                    }}
                  >
                    Bienvenue
                  </span>
                </h2>
              </div>
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FabriquantLayout;
