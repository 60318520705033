import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { useAppSelector } from "../../../redux/hook";
import { useGetDashboardByUserQuery } from "../../../utils/api/user/user.api";
import CureDashboardTable from "./CureDashboardTable/CureDashboardTable";
import "./DashboardFabriquant.css";
import LastProductTable from "./LastProductTable/LastProductTable";

const DashboardFabriquant = () => {
  const { userInfos } = useAppSelector((s) => s?.userInfos);
  const { data,isLoading } = useGetDashboardByUserQuery({
    id: userInfos?.user_id,
  });

  useEffect(() => {
    console.log("data dashboard", data);
  }, [data]);
  return (
    <div className="dashbord-fabriquant-component">
      <div className="content-col-left-dashboard-fabriquant">
        <div className="row row-card-stat-fabriquant mb-4">
          {!!isLoading && <CardSkeleton />}
          {!isLoading && (
            <>
              <div className="col-xl-4 col-md-6 col-stat-dashboard-fabriquant mb-3">
                <div className="content-col-stat-dashboard-fabriquant">
                  <p className="text-stat-dashboard-fabriquant">
                    Valeur du stock mp
                  </p>
                  <div className="content-info-stat-dash">
                    <p className="text-chiffre-stat-dash">{data?.valeur_stock_mp} €</p>
                    
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 col-stat-dashboard-fabriquant mb-3">
                <div className="content-col-stat-dashboard-fabriquant">
                  <p className="text-stat-dashboard-fabriquant">
                    Nombre de productions
                  </p>
                  <div className="content-info-stat-dash">
                    <p className="text-chiffre-stat-dash">{data?.nombre_productions}</p>
                    {/* <p className="text-info-stat-dash">
                      + 36% <span></span>
                    </p> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 col-stat-dashboard-fabriquant mb-3">
                <div className="content-col-stat-dashboard-fabriquant">
                  <p className="text-stat-dashboard-fabriquant">
                    nombre de produits fabriqués
                  </p>
                  <div className="content-info-stat-dash">
                    <p className="text-chiffre-stat-dash">{data?.nombre_produits_fabriques}</p>
                    
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {/* <div className="content-col-table mb-5">
          <h3 className="title-table-dash">Liste des dernières productions</h3>
          <div className="container-table pt-4">
            <LastProductTable />
          </div>
        </div> */}
        <div className="content-col-table mb-5">
          <h3 className="title-table-dash">Liste des dernières productions</h3>
          <div className="container-table pt-4">
            <CureDashboardTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardFabriquant;

const CardSkeleton = () => {
  return (
    <>
      {Array(3)
        .fill(3)
        .map((shopCard, i) => (
         
          <div
            className="col-xl-4 col-md-6 col-stat-dashboard-fabriquant mb-3"
            key={i}
          >
            <div className="content-col-stat-dashboard-fabriquant">
              <Skeleton className="text-stat-dashboard-fabriquant mb-3" />
              <div className="content-info-stat-dash">
                <Skeleton className="text-chiffre-stat-dash" width={`6rem`} />
              </div>
            </div>
          </div>
        ))}
    </>
  );
};
