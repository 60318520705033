/* eslint-disable react-hooks/exhaustive-deps */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { AuthState } from "../user/user.type";
import {
  ForgetPasswordFormData,
  LoginFormData,
  LoginResult,
  RegisterFormData,
  ResetPasswordFormData,
  VerificationRegister,
  VerificationRegisterFormData,
} from "./auth.type";

export const AuthApi = createApi({
  reducerPath: "auth",
  tagTypes: ["auth"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/v1/app/`,
  }),
  endpoints: (build) => ({
    // register user mutation
    registerUser: build.mutation<AuthState["userInfos"], RegisterFormData>({
      query: (data) => ({
        url: "register",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["auth"],
      transformResponse: ({ data }) => data,
    }),
    registerConfirm: build.mutation<
      VerificationRegister,
      VerificationRegisterFormData
    >({
      query: (data) => ({
        url: "email-verification-request",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["auth"],
    }),
    // login user mutation
    loginUser: build.mutation<LoginResult, LoginFormData>({
      query: (data) => ({
        url: "login/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["auth"],
    }),
    // request to reset user password mutation
    forgetPassword: build.mutation<any, ForgetPasswordFormData>({
      query: (data) => ({
        url: `reset-password-request`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["auth"],
    }),
    //reset user password mutation
    resetUserPassword: build.mutation<any, ResetPasswordFormData>({
      query: (data) => ({
        url: `reset-password`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["auth"],
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useLoginUserMutation,
  useForgetPasswordMutation,
  useResetUserPasswordMutation,
  useRegisterConfirmMutation,
} = AuthApi;

export function useRequestFromParams(): [VerificationRegister, boolean] {
  const [item, setItem] = useState<VerificationRegister | undefined>();
  const { token } = useParams<{ token: string }>();
  const [confirmRegister, { data, isError, isLoading, error,isSuccess }] =
    useRegisterConfirmMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      confirmRegister({ verify: token });
    } else {
      setTimeout(() => {
        navigate(-1);
      }, 50);
    }
  }, [navigate, token]);
  useEffect(() => {
    if (isError) {
      console.log("err", error);
      navigate(-1);
    }
  }, [isError, navigate]);
  useEffect(() => {
    if (isSuccess) {
      console.log(data);
      setItem(data);
    }
  }, [isSuccess]);

  return [item as VerificationRegister, isLoading];
}
