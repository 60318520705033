import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Logo from "../../assets/appImages/logo.png";
import ErrorMessage from "../common/ErrorMessage";
import UseResetPassword from "./requestForm/UseResetPassword";

const ResetPassword = () => {
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    passwordHandleChange,
    haveMinCharacter,
    haveMinLowercase,
    haveMinNumber,
    haveMinSpecialCharacter,
    haveMinUppercase,
  } = UseResetPassword();
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  return (
    <div>
      <div className="container-page-login-app">
        <div className="auth-row">
          <div className="auth-col auth-right-side-col">
            <div className="auth-right-side-container">
              <div className="auth-form-container text-center pb-4">
                <div className="content-logo-auth pb-2">
                  <img src={Logo} alt="logo" className="logo-auth-page" />
                </div>
                <h2 className="title-auth-form-text pt-4">
                  Modifier mot de passe
                </h2>
                <div className="mt-5">
                  <form id="auth-form" onSubmit={onSubmit}>
                    <div className="row auth-form-row">
                      <div className="col-md-12 auth-input-col mb-3">
                        <div className="auth-form-group">
                          <label className="form-label form-label-auth-login">
                            Code
                          </label>
                          <input
                            type={`string`}
                            className="form-control auth-form-control"
                            id="reset_password_code"
                            {...register("reset_password_code")}
                            placeholder="Code"
                            aria-label="reset_password_code"
                            data-testid="codeId"
                          />
                          <ErrorMessage
                            message={errors?.reset_password_code?.message}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 auth-input-col mb-2">
                        <div className="auth-form-group position-relative">
                          <label className="form-label form-label-auth-login">
                            Mot de passe
                          </label>
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control auth-form-control"
                            id="password"
                            // {...register("password")}
                            onChange={passwordHandleChange}
                            placeholder="Mot de passe"
                            aria-label="Password"
                            data-testid="passwordId"
                          />
                          <span
                            className="show-hide-password"
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                          >
                            {showPassword ? (
                              <AiOutlineEye />
                            ) : (
                              <AiOutlineEyeInvisible />
                            )}
                          </span>
                        </div>
                        <ErrorMessage message={errors?.password?.message} />
                      </div>
                      <div className="col-md-12 auth-input-col mb-2">
                        <div className="auth-form-group position-relative">
                          <label className="form-label form-label-auth-login">
                            Confimer le mot de passe
                          </label>
                          <input
                            type={showCPassword ? "text" : "password"}
                            className="form-control auth-form-control"
                            id="password_confirmation"
                            {...register("password_confirmation")}
                            placeholder="Confimer le mot de passe"
                            aria-label="password_confirmation"
                            data-testid="cpasswordId"
                          />
                          <span
                            className="show-hide-password"
                            onClick={() => {
                              setShowCPassword(!showCPassword);
                            }}
                          >
                            {showCPassword ? (
                              <AiOutlineEye />
                            ) : (
                              <AiOutlineEyeInvisible />
                            )}
                          </span>
                        </div>
                        <ErrorMessage
                          message={errors?.password_confirmation?.message}
                        />
                      </div>
                      <div className="col-md-12 auth-submit-btn-container mt-2 mb-4">
                        <button
                          type="submit"
                          disabled={isLoading}
                          data-testid="submitBtnId"
                          className="btn auth-submit-btn trans-0-2"
                        >
                          {isLoading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                                role="status"
                              ></span>
                              <span>En cours...</span>
                            </>
                          ) : (
                            "Enregistrer"
                          )}
                        </button>
                      </div>
                      <div className="col-md-12">
                        <div className="input-group my-2 password-validation-terms-row">
                          <div className="password-validation-terms-container">
                            <h4 className="password-validation-terms-title">
                              Votre mot de passe doit contenir :{" "}
                            </h4>
                            <ul className="password-validation-terms-ul p-l-14 text-start">
                              <li
                                className={
                                  "password-rule-item " +
                                  (haveMinCharacter && "text-success")
                                }
                              >
                                Au minimum 8 caractères
                              </li>
                              <li
                                className={
                                  "password-rule-item " +
                                  (haveMinUppercase && "text-success")
                                }
                              >
                                Au minimum 1 majuscule
                              </li>
                              <li
                                className={
                                  "password-rule-item " +
                                  (haveMinLowercase && "text-success")
                                }
                              >
                                Au minimum 1 minuscule
                              </li>
                              <li
                                className={
                                  "password-rule-item " +
                                  (haveMinNumber && "text-success")
                                }
                              >
                                Au minimum 1 chiffre
                              </li>
                              <li
                                className={
                                  "password-rule-item " +
                                  (haveMinSpecialCharacter && "text-success")
                                }
                              >
                                Au minimum 1 caractère spécial
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <p className="text-footer-auth">
            ©2021 All Rights Reserved. Volkeno Inc
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
