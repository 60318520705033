import React from "react";
import "./GestionUsers.css";
import { NavLink } from "react-router-dom";
import { MdOutlineAdd } from "react-icons/md";
import GestionUsersTable from "./GestionUsersTable/GestionUsersTable";

const GestionUsers = () => {
  return (
    <div className="fournisseur-list-component">
      <div className="container-title-page-admin mb-5">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">Liste des clients</h3>
        </div>
        <div className="content-btn-action-title-page-admin">
          <NavLink
            to="/admin/ajouter-utilisateur"
            className="btn btn-page-title-admin"
          >
            <MdOutlineAdd />
            <span className="ps-2">Nouveau client</span>
          </NavLink>
        </div>
      </div>
      <div className="container-fournisseur-list center-admin-table">
        <GestionUsersTable />
      </div>
    </div>
  );
};

export default GestionUsers;
