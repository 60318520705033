/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent } from "react";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { RecetteFormData } from "../../../../../utils/api/recette/recette.type";
import ErrorMessage from "../../../../common/ErrorMessage";

const InfoFabricationTabs = ({
  navigation,
  register,
  errors,
  onChangeInfo,
  infos,
  onSelectType,
  type,
}: {
  navigation: any;
  register: UseFormRegister<RecetteFormData>;
  errors: Partial<FieldErrorsImpl<RecetteFormData>>;
  onChangeInfo: any;
  infos: any;
  onSelectType: (e:ChangeEvent<HTMLSelectElement>) => void;
  type: string
}) => {

  return (
    <div className="quantite-tabs-component">
      <div className="container-form-add">
        <div>
          <div className="row row-add">
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="nom_recette"
                  className="form-label form-label-add-recette"
                >
                  Nom de la recette
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="text"
                  min={0}
                  step="any"
                  {...register("nom_recette")}
                  placeholder="Nom de la recette"
                />
                <ErrorMessage
                  message={errors?.nom_recette && errors?.nom_recette?.message}
                />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="type_recette"
                  className="form-label form-label-add-recette"
                >
                  Type de la recette
                </label>
                <select
                  // {...register("type_recette")}
                  value={type}
                  onChange={(e) => onSelectType(e)}
                  id="type_recette"
                  className="form-control form-control-add-form"
                >
                  <option value="">Type de la recette</option>
                  <option value="savon">Savon</option>
                  <option value="cosmetique">Cosmétique</option>
                </select>
                <ErrorMessage
                  message={
                    errors?.type_recette && errors?.type_recette?.message
                  }
                />
              </div>
            </div>
            {type !== "cosmetique" ? (
              <>
                <div className="col-md-6 col-form-add-student mb-3">
                  <div className="row-addcontent-col-form-add">
                    <label
                      htmlFor="surgras"
                      className="form-label form-label-add-recette"
                    >
                      Surgras (%)
                    </label>
                    <input
                      className="form-control form-control-add-form"
                      type="number"
                      min={0}
                      step="any"
                      {...register("surgras")}
                      placeholder="Surgras (%)"
                    />
                    <ErrorMessage
                      message={errors?.surgras && errors?.surgras?.message}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-form-add-student mb-3">
                  <div className="row-addcontent-col-form-add">
                    <label
                      htmlFor="temps_cure"
                      className="form-label form-label-add-recette"
                    >
                      Temps de cure ( en semaines)
                    </label>
                    <input
                      className="form-control form-control-add-form"
                      type="number"
                      step={"any"}
                      min={0}
                      placeholder="Temps de cure ( en semaines)"
                      {...register("temps_cure")}
                    />
                    <ErrorMessage
                      message={
                        errors?.temps_cure && errors?.temps_cure?.message
                      }
                    />
                  </div>
                </div>
              </>
            ) : null}

            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="poids_total_initial"
                  className="form-label form-label-add-recette"
                >
                  Poids total initial (kg)
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="number"
                  step={"any"}
                  min={0}
                  value={infos?.poids_total_initial}
                  name="poids_total_initial"
                  onChange={(e) => onChangeInfo(e)}
                  placeholder="Poids total initial (kg)"
                />
                <ErrorMessage
                  message={
                    errors?.poids_total_initial &&
                    errors?.poids_total_initial?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="perte_prevue_durant_fabrication"
                  className="form-label form-label-add-recette"
                >
                  Perte prévue durant la fabrication (%)
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="number"
                  step={"any"}
                  min={0}
                  value={infos?.perte_prevue_durant_fabrication}
                  name="perte_prevue_durant_fabrication"
                  onChange={(e) => onChangeInfo(e)}
                  placeholder="Perte prévue durant la fabrication (%)"
                />
                <ErrorMessage
                  message={
                    errors?.perte_prevue_durant_fabrication &&
                    errors?.perte_prevue_durant_fabrication?.message
                  }
                />
              </div>
            </div>
            {type !== "cosmetique" ? (
              <>
                <div className="col-md-6 col-form-add-student mb-3">
                  <div className="row-addcontent-col-form-add">
                    <label
                      htmlFor="perte_prevue_durant_cure"
                      className="form-label form-label-add-recette"
                    >
                      Perte prévue durant la cure (%)
                    </label>
                    <input
                      className="form-control form-control-add-form"
                      type="number"
                      step={"any"}
                      min={0}
                      value={infos?.perte_prevue_durant_cure}
                      name="perte_prevue_durant_cure"
                      onChange={(e) => onChangeInfo(e)}
                      placeholder="Perte prévue durant la cure (%)"
                    />
                    <ErrorMessage
                      message={
                        errors?.perte_prevue_durant_cure &&
                        errors?.perte_prevue_durant_cure?.message
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 col-form-add-student mb-3">
                  <div className="row-addcontent-col-form-add">
                    <label
                      htmlFor="poids_prevu_fin_cure"
                      className="form-label form-label-add-recette"
                    >
                      Poids prévu en fin de cure (kg)
                    </label>
                    <input
                      className="form-control form-control-add-form"
                      type="number"
                      step={"any"}
                      min={0}
                      {...register("poids_prevu_fin_cure")}
                      readOnly
                      placeholder="Poids prévu en fin de cure (kg)"
                    />
                    <ErrorMessage
                      message={
                        errors?.poids_prevu_fin_cure &&
                        errors?.poids_prevu_fin_cure?.message
                      }
                    />
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <div className="flex-r gap-4 pe-5 align-items-center py-5">
            <button
              className="btn btn-cancelled-recette"
              type="button"
              onClick={() => navigation.previous()}
            >
              Retour
            </button>
            <button
              type="submit"
              className="btn auth-submit-btn-recette trans-0-2"
            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoFabricationTabs;
