/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleannerError, validatePhone } from "../../../../utils/utils";
import {
  FournisseurFormData,
  IFournisseur,
} from "../../../../utils/api/fournisseur/fournisseur.type";
import { useAppSelector } from "../../../../redux/hook";
import {
  useAddOrUpdateFournisseurMutation,
  useDeleteFournisseurMutation,
} from "../../../../utils/api/fournisseur/fournisseur.api";
import { Color } from "../../../../utils/theme";

function UseCrudFournisseurForm(fournisseur?: IFournisseur) {
  const [country, setCountry] = useState<string>("");
  const validationSchema = yup.object().shape({
    fullname: yup.string().required().label("Le nom"),
    nom_commercial: yup.string().nullable().label("Le nom commercial"),
    address: yup.string().label("L'adresse").nullable(),
    phone: validatePhone(yup, country),
    email: yup.string().email().label("L'email"),
    minimum_commande: yup.string().nullable().label("Le minimum de commande"),
    franco_port: yup.string().nullable().label("Le franco de port"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<FournisseurFormData>({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const { userInfos } = useAppSelector((s) => s?.userInfos);

  const [sendData, { isLoading, isSuccess, error, isError, data }] =
    useAddOrUpdateFournisseurMutation();
  const [address, setAddress] = useState<string>("");
  const [phone, setPhone] = useState<string | null>(null);

  const handleChangePhone = (item, country) => {
    setCountry(country?.countryCode);
    setPhone(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue("phone", item);
    } else {
      setValue("phone", "");
    }
    
  };

  const onChangeAddress = (val) => {
    setAddress(val?.rue);
    setValue("address", val?.rue);
  };

  useEffect(() => {
    if (fournisseur?.fournisseur_id) {
      const fields: (keyof FournisseurFormData)[] = [
        "fullname",
        "nom_commercial",
        "email",
        "phone",
        "address",
        "minimum_commande",
        "owner_personnal_id",
        "franco_port",
      ];
      for (let field of fields) {
        register(field);
        setValue(field, fournisseur[field]);
      }
      if (fournisseur?.address) {
        setAddress(fournisseur?.address);
      }

      if (fournisseur?.phone) {
        setPhone(fournisseur?.phone);
      }
    }
  }, [fournisseur]);

  useEffect(() => {
    if (isSuccess) {
      if (data?.success) {
        Swal.fire({
          icon: "success",
          iconColor: Color.success,
          confirmButtonColor: Color.success,
          title: data?.message,
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          if (userInfos?.status === "fabricant") {
            navigate("/fabriquant/fournisseurs");
          } else {
            navigate("/admin/fournisseurs");
          }
        });
      } else {
        // setError("password", { message: data?.message });
        Swal.fire({
          icon: "error",
          title: data?.message,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSubmit = async (data: FournisseurFormData) => {
    if (!fournisseur) {
      data["fabriquant_id"] = userInfos?.user_id;
    }
    console.log("data", data);
    await sendData({ fournisseur_id: fournisseur?.fournisseur_id, data: data });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    phone,
    handleChangePhone,
    onChangeAddress,
    address,
    country,
  };
}

export default UseCrudFournisseurForm;

export function UseDeleteItem(
  slug: string
  // modalID: string
) {
  const [deleteData] = useDeleteFournisseurMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce fournisseur ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        console.log(result);
        Swal.fire({
          icon: "success",
          title: `Fournisseur supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
