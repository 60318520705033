import React from "react";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../../../common/ErrorMessage";
import { PropsTypeFabric } from "../AddNewFabrication";

const ProductEnvironment = ({
  register,
  errors,
  handleChange,
  produit,
  recettes,
  isLoading
}: PropsTypeFabric) => {
  const navigate = useNavigate();


  return (
    <div className="quantite-tabs-component">
      <div className="container-form-add">
        <h3 className="title-form-add">Produit</h3>
        <div>
          <div className="row row-add">
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="type"
                  className="form-label form-label-add-recette"
                >
                  Type de produit
                  <span className="text-danger" >
                    *
                  </span>
                </label>
                <select
                  className="form-control form-control-add-form"
                  name="type_produit"
                  onChange={(e) => handleChange(e)}
                  id="type"
                  value={produit?.type}
                >
                  <option
                    className="form-control form-control-add-form"
                    value=""
                  >
                    Type de produit
                  </option>
                  <option value="cosmetique">Cosmétique</option>
                  <option value="savon">Savon</option>
                </select>
                <ErrorMessage
                  message={
                    errors?.type_produit && errors?.type_produit?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="recette_id"
                  className="form-label form-label-add-recette"
                >
                  Nom (recette/nom){" "}
                  <span className="text-danger" >
                    *
                  </span>
                </label>

                <select
                  className="form-control form-control-add-form"
                  name="recette_id"
                  onChange={(e) => handleChange(e)}
                  id="recette_id"
                  value={produit?.recette_id}
                >
                  <option
                    className="form-control form-control-add-form"
                    value=""
                  >
                    Nom (recette/nom)
                  </option>
                  {!!recettes?.length &&
                    recettes?.map((item) => (
                      <option value={item?.recette_id} key={item?.recette_id}>
                        {item?.nom_recette}
                      </option>
                    ))}
                </select>
                <ErrorMessage
                  message={errors?.recette_id && errors?.recette_id?.message}
                />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="code"
                  className="form-label form-label-add-recette"
                >
                  Code interne{" "}
                  <span className="text-danger" >
                    *
                  </span>
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="text"
                  {...register("code_interne")}
                  placeholder="Code interne"
                />
                <ErrorMessage
                  message={
                    errors?.code_interne && errors?.code_interne?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="num_lot"
                  className="form-label form-label-add-recette"
                >
                  Numéro de lot{" "}
                  <span className="text-danger" >
                    *
                  </span>
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="text"
                  placeholder="Numéro de lot"
                  {...register("numero_lot")}
                />
                <ErrorMessage
                  message={errors?.numero_lot && errors?.numero_lot?.message}
                />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="date_fabrication"
                  className="form-label form-label-add-recette"
                >
                  Date de fabrication{" "}
                  <span className="text-danger" >
                    *
                  </span>
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="date"
                  {...register("date_fabrication")}
                />
                <ErrorMessage
                  message={
                    errors?.date_fabrication &&
                    errors?.date_fabrication?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="dlu"
                  className="form-label form-label-add-recette"
                >
                  DLUO / DDM PF{" "}
                  <span className="text-danger" >
                    *
                  </span>
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="date"
                  placeholder="DLUO / DDM PF"
                  {...register("dluo_ddm_pf")}
                />
                <ErrorMessage
                  message={errors?.dluo_ddm_pf && errors?.dluo_ddm_pf?.message}
                />
              </div>
            </div>
          </div>
          <h3 className="title-form-add py-4">Environnement</h3>
          <div className="row row-add">
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="temp_labo"
                  className="form-label form-label-add-recette"
                >
                  Température labo °C
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="number"
                  step={"any"}
                  placeholder="Température labo °C"
                  {...register("env_temperature_labo")}
                />
                <ErrorMessage
                  message={
                    errors?.env_temperature_labo &&
                    errors?.env_temperature_labo?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="hyg_labo"
                  className="form-label form-label-add-recette"
                >
                  Hygrométrie labo °C
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="number"
                  step={"any"}
                  placeholder="Hygrométrie labo °C "
                  {...register("env_hygrometrie_labo")}
                />
                <ErrorMessage
                  message={
                    errors?.env_hygrometrie_labo &&
                    errors?.env_hygrometrie_labo?.message
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <label
                  htmlFor="other"
                  className="form-label form-label-add-recette"
                >
                  Autres
                </label>
                <input
                  className="form-control form-control-add-form"
                  type="text"
                  placeholder="Autres"
                  {...register("env_autre")}
                />
                <ErrorMessage
                  message={errors?.env_autre && errors?.env_autre?.message}
                />
              </div>
            </div>
          </div>
          <div className="flex-r gap-4 align-items-center py-5">
            <button
              className="btn btn-cancelled-recette"
              onClick={() => navigate(-1)}
            >
              Retour
            </button>
            <button
              type="submit"
              className="btn auth-submit-btn-recette trans-0-2"
            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductEnvironment;
