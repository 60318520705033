/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginFormData } from "../../../utils/api/auth/auth.type";
import { useLoginUserMutation } from "../../../utils/api/auth/auth.api";
import { Color } from "../../../utils/theme";
import { cleannerError } from "../../../utils/utils";
import { useAppDispatch } from "../../../redux/hook";
import { onSetUserToken } from "../../../redux/slice/User.slice";

function UseLoginForm() {
  const validationSchema = yup.object().shape({
    email: yup.string().email().required().label("L'email"),
    password: yup.string().required().label("Le mot de passe"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm<LoginFormData>({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loginUser, { isLoading, isSuccess, error, isError, data }] =
    useLoginUserMutation();

  useEffect(() => {
    if (isSuccess) {
      console.log("data", data);
      if (data?.success && data?.is_active) {
        dispatch(
          onSetUserToken({ userInfos: data.user, token: data?.access_token })
        );
        Swal.fire({
          icon: "success",
          title: "Connexion réussie!",
          iconColor: Color.success,
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: data?.message,
          iconColor: Color.success,
          showConfirmButton: false,
          timer: 3000,
        });
      }
      
    }
    const err = error as any;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : err?.status < 500
          ? "Email ou mot de passe incorrect"
          : `Une erreur de statut ${err?.status} est survenue.`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess, navigate]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [clearErrors, errors]);

  const onSubmit = async (data: LoginFormData) => {
    // console.log("data login", data);
    await loginUser(data);
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
  };
}

export default UseLoginForm;
