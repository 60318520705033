import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { BtnCloseModal } from "../../../common/Button";
import Modal from "react-modal";
import UseEditPassordForm from "../requestUser/UseEditPassordForm";
import ErrorMessage from "../../../common/ErrorMessage";

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    zIndex: 99999,
  },
};

function ChangePasswordModal({ modalChangePassword, setModalChangePassword }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const {
    register,
    onSubmit,
    isLoading,
    errors,
    passwordHandleReset,
    passwordHandleChange,
    haveMinCharacter,
    haveMinLowercase,
    haveMinNumber,
    haveMinSpecialCharacter,
    haveMinUppercase,
  } = UseEditPassordForm(setModalChangePassword);
  function closeModalChangePassword() {
    setModalChangePassword(false);
    passwordHandleReset();
  }
  return (
    <Modal
      isOpen={modalChangePassword}
      onRequestClose={closeModalChangePassword}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal-header px-2 py-1">
        <h5 className="modal-title" id="ChangePasswordModalLabel">
          Modifier mot de passe
        </h5>
        <BtnCloseModal label="" onClick={closeModalChangePassword} />
      </div>
      <form onSubmit={onSubmit} autoComplete="off">
        <div className="row py-3 px-2">
          <div className="col-md-12 auth-input-col mb-2">
            <div className="auth-form-group position-relative">
              <label className="form-label form-label-auth-login">
                Actuel mot de passe
              </label>
              <input
                type={showPassword ? "text" : "password"}
                className="form-control auth-form-control"
                id="current_password"
                {...register("current_password")}
                placeholder="Actuel mot de passe"
                aria-label="current_password"
                autoComplete="new-password"
              />
              <span
                className="show-hide-password"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </span>
            </div>
            <ErrorMessage message={errors?.current_password?.message} />
          </div>
          <div className="col-md-12 auth-input-col mb-2">
            <div className="auth-form-group position-relative">
              <label className="form-label form-label-auth-login">
                Nouveau mot de passe
              </label>
              <input
                type={showNewPassword ? "text" : "password"}
                className="form-control auth-form-control"
                id="password"
                // {...register("password")}
                name="password"
                onChange={(e) => passwordHandleChange(e)}
                placeholder="Nouveau mot de passe"
                aria-label="Password"
                autoComplete="new-password"
              />
              <span
                className="show-hide-password"
                onClick={() => {
                  setShowNewPassword(!showNewPassword);
                }}
              >
                {showNewPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </span>
            </div>
            <ErrorMessage message={errors?.password?.message} />
          </div>
          <div className="col-md-12 auth-input-col mb-2">
            <div className="auth-form-group position-relative">
              <label className="form-label form-label-auth-login">
                Confirmer mot de passe
              </label>
              <input
                type={showCPassword ? "text" : "password"}
                className="form-control auth-form-control"
                id="password_confirmation"
                {...register("password_confirmation")}
                placeholder="Confirmer mot de passe"
                aria-label="password_confirmation"
              />
              <span
                className="show-hide-password"
                onClick={() => {
                  setShowCPassword(!showCPassword);
                }}
              >
                {showCPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </span>
            </div>
            <ErrorMessage message={errors?.password_confirmation?.message} />
          </div>
          <div className="col-md-12 mb-4">
            <div className="content-btn-action-title-page-admin">
              <button
                type="submit"
                disabled={isLoading}
                data-testid="submitBtnId"
                className="btn btn-page-title-admin w-100"
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>En cours...</span>
                  </>
                ) : (
                  "Enregistrer"
                )}
              </button>
            </div>
          </div>
          <div className="col-md-12">
            <div className="input-group my-2 password-validation-terms-row">
              <div className="password-validation-terms-container">
                <h4 className="password-validation-terms-title">
                  Votre mot de passe doit contenir :{" "}
                </h4>
                <ul className="password-validation-terms-ul p-l-14 text-start">
                  <li
                    className={
                      "password-rule-item " +
                      (haveMinCharacter && "text-success")
                    }
                  >
                    Au minimum 8 caractères
                  </li>
                  <li
                    className={
                      "password-rule-item " +
                      (haveMinUppercase && "text-success")
                    }
                  >
                    Au minimum 1 majuscule
                  </li>
                  <li
                    className={
                      "password-rule-item " +
                      (haveMinLowercase && "text-success")
                    }
                  >
                    Au minimum 1 minuscule
                  </li>
                  <li
                    className={
                      "password-rule-item " + (haveMinNumber && "text-success")
                    }
                  >
                    Au minimum 1 chiffre
                  </li>
                  <li
                    className={
                      "password-rule-item " +
                      (haveMinSpecialCharacter && "text-success")
                    }
                  >
                    Au minimum 1 caractère spécial
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default ChangePasswordModal;
