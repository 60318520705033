import React from "react";
import "./GestionStockAdmin.css";
import { useStepType } from "../../../utils/type";
import { useStep } from "react-hooks-helper";
import MatierePremiereTabs from "./StockAdminTabs/MatierePremiereTabs";
import ProduitFiniTabs from "./StockAdminTabs/ProduitFiniTabs";

const steps = [
  { id: "matiere-premiere", Component: MatierePremiereTabs },
  { id: "produit-fini", Component: ProduitFiniTabs },
];
const tabs = ["Matières premières", "Produits finis"];

const GestionStockAdmin = () => {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  return (
    <div className="fournisseur-list-component">
      <div className="container-title-page-admin mb-5">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">Liste stock</h3>
        </div>
      </div>
      <div className="container-fournisseur-list">
        <div className="container-ul-recette">
          <ul className="tabs-container">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
        </div>
        <div className="tabs-component-container">
          <Component />
        </div>
      </div>
    </div>
  );
};

export default GestionStockAdmin;
