import { configureStore } from "@reduxjs/toolkit";
import { AuthApi } from "../utils/api/auth/auth.api";
import { CommandeApi } from "../utils/api/commande/commande.api";
import { FabricationApi } from "../utils/api/fabrication/fabrication.api";
import { FournisseurApi } from "../utils/api/fournisseur/fournisseur.api";
import { RecetteApi } from "../utils/api/recette/recette.api";
import { UserApi } from "../utils/api/user/user.api";
import { Env, currentEnv } from "../utils/http";
import { UserSlice } from "./slice/User.slice";

const store = configureStore({
  reducer: {
    [AuthApi.reducerPath]: AuthApi.reducer,
    [UserSlice.name]: UserSlice.reducer,
    [UserApi.reducerPath]: UserApi.reducer,
    [FournisseurApi.reducerPath]: FournisseurApi.reducer,
    [CommandeApi.reducerPath]: CommandeApi.reducer,
    [RecetteApi.reducerPath]: RecetteApi.reducer,
    [FabricationApi.reducerPath]:FabricationApi.reducer
  },
  devTools: Env === currentEnv,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    AuthApi.middleware,
    UserApi.middleware,
    FournisseurApi.middleware,
    CommandeApi.middleware,
    RecetteApi.middleware,
    FabricationApi.middleware
  ],
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export default store;
