import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { PaginationResultsV2, TypeQuery } from "../../type";
import { QueryUrl, useLocationState } from "../../utils";
import { prepareHeaders } from "../user/user.api";
import { Fabrication, FabricationFormData } from "./fabrication.type";

export const FabricationApi = createApi({
  reducerPath: "fabricationApi",
  tagTypes: [
    "fabricationByID",
    "fabricationList",
    "fabricationByUser",
    "allFabricationList",
    "pfList",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/v1/app/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getFabricationByUser: builder.query<
      PaginationResultsV2<Fabrication>,
      TypeQuery
    >({
      providesTags: ["fabricationByUser"],
      query: ({ id, type, ...query }) => ({
        url: `/post-filter-fabrications-by-fabriquant/${id}/${type}`,
        method: "POST",
        body: query,
      }),
    }),
    getPFList: builder.query<PaginationResultsV2<Fabrication>, TypeQuery>({
      providesTags: ["pfList"],
      query: ({ id, ...query }) =>
        QueryUrl(`/stocks-produits-finis-by-fabricant/${id}`, query),
    }),
    getAllFabricationList: builder.query<
      PaginationResultsV2<Fabrication>,
      TypeQuery
    >({
      providesTags: ["allFabricationList"],
      query: (query) => QueryUrl("fabrications/", query),
    }),
    getFabricationList: builder.query<
      PaginationResultsV2<Fabrication>,
      TypeQuery
    >({
      providesTags: ["fabricationList"],
      query: ({ type, ...query }) =>
        QueryUrl(`/fabrications-view-by-admin/${type}/`, query),
    }),
    addOrUpdateFabrication: builder.mutation<
      { message: string; success: boolean; data: Fabrication },
      {
        fabrication_id?: string;
        data: FabricationFormData | FormData;
      }
    >({
      invalidatesTags: [
        "fabricationList",
        "fabricationByUser",
        "fabricationByID",
        "pfList",
      ],
      query: ({ fabrication_id, data }) => {
        if (fabrication_id) {
          return {
            url: `fabrications/${fabrication_id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `fabrications/`,
          method: "POST",
          body: data,
        };
      },
    }),
    getFabricationByID: builder.query<
      { message: string; success: boolean; data: Fabrication },
      TypeQuery
    >({
      query: (id) => `fabrications/${id}`,
      providesTags: ["fabricationByID"],
    }),
    deleteFabrication: builder.mutation<
      { message: string; success: boolean; data: string },
      string
    >({
      query: (fabrication_id) => ({
        url: `fabrications/${fabrication_id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "fabricationList",
        "fabricationByUser",
        "fabricationByID",
        "pfList",
      ],
    }),
    exportFabricationByUser: builder.query<
      { message: string; success: boolean; pdf_url: string },
      { fabricant_id: string; type: string }
    >({
      query: ({ fabricant_id, type }) =>
        `export-fabrications-by-fabricant/${fabricant_id}/${type}/`,
      // providesTags: ["recetteByID"],
    }),
    exportPFByUser: builder.query<
      { message: string; success: boolean; pdf_url: string },
      string
    >({
      query: (fabricant_id) =>
        `/export-produits-finis-by-fabricant/${fabricant_id}/`,
      // providesTags: ["recetteByID"],
    }),
  }),
});

export const {
  useAddOrUpdateFabricationMutation,
  useDeleteFabricationMutation,
  useLazyGetFabricationByIDQuery,
  useGetFabricationByUserQuery,
  useGetFabricationListQuery,
  useGetAllFabricationListQuery,
  useGetPFListQuery,
  useLazyExportFabricationByUserQuery,
  useLazyExportPFByUserQuery
} = FabricationApi;

export function useFabricationFromLocation(): [
  Fabrication,
  boolean,
  string,
  (id: string) => any
] {
  const itemState = useLocationState<Fabrication | undefined>(undefined);
  const [item, setItem] = useState(
    !itemState?.recette_id ? itemState : undefined
  );
  const { id } = useParams<{ id: string }>();
  const [findById, { data, isError, isLoading }] =
    useLazyGetFabricationByIDQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      findById(id as any);
    }
    // console.log("itemState", itemState,id);
  }, [itemState, id]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate("/fabriquant/fabrications", { replace: true });
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      console.log("data", data);
      setItem(data?.data);
    }
  }, [data]);

  return [item as Fabrication, isLoading, id as string, findById as any];
}
