import React, { ChangeEvent, useEffect } from "react";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../../utils/type";
import ProductEnvironment from "./NewFabricationTabs/ProductEnvironment";
import FabricationTabs from "./NewFabricationTabs/FabricationTabs";
import RecetteTabs from "./NewFabricationTabs/RecetteTabs";
import CureTabs from "./NewFabricationTabs/CureTabs";
import { TiExport } from "react-icons/ti";
import UseCrudFabricationForm from "./requestFabrication/UseCrudFabricationForm";
import { useFabricationFromLocation } from "../../../../utils/api/fabrication/fabrication.api";
import { FieldErrorsImpl, UseFormRegister, UseFormSetValue, UseFormUnregister } from "react-hook-form";
import { FabricationFormData } from "../../../../utils/api/fabrication/fabrication.type";
import { Recette, RecetteItem } from "../../../../utils/api/recette/recette.type";
export type PropsTypeFabric = {
  navigation: any;
  register: UseFormRegister<FabricationFormData>;
  unregister: UseFormUnregister<FabricationFormData>;
  errors: Partial<FieldErrorsImpl<FabricationFormData>>;
  handleChange: (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void;
  produit: { type: string; recette_id: string };
  recettes: Recette[];
  recette: Recette;
  lines: RecetteItem[];
  HandleSelectName: (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement>,
    i: number
  ) => void;
  isLoading: boolean;
  setValue: UseFormSetValue<FabricationFormData>;
  dataForm: any
};

const steps = [
  { id: "produit-environnement", Component: ProductEnvironment },
  { id: "fabrication", Component: FabricationTabs },
  { id: "recette", Component: RecetteTabs },
  { id: "cure", Component: CureTabs },
];
const tabs = ["Produit et environnement", "Fabrication", "Recette", "Cure"];

const AddNewFabrication = () => {
  const { step, navigation, index }: useStepType = useStep({
    initialStep: 0,
    steps,
  });
  const [item] = useFabricationFromLocation();
  const form = UseCrudFabricationForm(item);
  const { Component } = step;
  const { onSubmit, level, dataForm, setValue,produit } = form;
  const props = {
    navigation,
    ...form,
  };

  useEffect(() => {
    window.scrollTo(10, 10);
    // console.log("level index", itemState);
    setValue("step", index);
  }, [index]);

  useEffect(() => {
    if (level === 1) {
      navigation.go("fabrication");
    } else if (level === 2) {
      navigation.go("recette");
    } else if (level === 3) {
      navigation.go("cure");
    }
  }, [level, dataForm]);
  return (
    <div className="fournisseur-list-component">
      <div className="container-title-page-admin mb-5">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">Fabriquer un savon</h3>
        </div>
        {/* <div className="content-btn-action-title-page-admin">
          <button className="btn btn-page-title-admin">
            <TiExport />
            <span className="ps-2">Exporter en PDF</span>
          </button>
        </div> */}
      </div>
      <div className="container-fournisseur-list">
        <div className="container-ul-recette">
          <ul className="tabs-container">
            {tabs.map((tab, key) => (
              <li
                className={`tab-item  ${index === key && "tab-active-item"}`}
                key={key}
                role="button"
                hidden={produit?.type === "cosmetique" && tab === "Cure"}
              >
                {tab}
              </li>
            ))}
          </ul>
        </div>
        <div className="tabs-component-container pt-5">
          <form onSubmit={onSubmit}>
            <Component {...props} />
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNewFabrication;
