/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { CommandeLine } from "../../../../../utils/api/commande/commande.type";
import { RecetteFormData } from "../../../../../utils/api/recette/recette.type";
import { Categories } from "../../../../../utils/constante";
import ErrorMessage from "../../../../common/ErrorMessage";

const QuantiteTabs = ({
  index,
  register,
  errors,
  stocks,
  options,
  handleChange,
  HandleSelectName,
  totals,
}: {
  index: number;
  register: UseFormRegister<RecetteFormData>;
  errors: Partial<FieldErrorsImpl<RecetteFormData>>;
  stocks: CommandeLine[];
  options: any;
  handleChange: any;
  HandleSelectName: any;
  totals: {
    total_poids: string;
    total_pourcentage: string;
    total_cout: string;
    all_total: string;
  };
}) => {
  const navigate = useNavigate();

  const [inputFields, setInputFields] = useState([
    {
      categorie: "",
      nom: "",
      poucentage: "",
      poids: "",
      unite_poids: "",
      cout: "",
      total: "",
    },
  ]);

  useEffect(() => {
    let arr: any = [];
    if (options?.length) {
      let newfield = {
        categorie: "",
        nom: "",
        poucentage: "",
        poids: "",
        unite_poids: "",
        cout: "",
        total: "",
      };
      options?.forEach((item) => {
        arr.push(newfield);
      });
      // console.log("arr", arr);
      setInputFields(arr);
    }
  }, [options?.length, index]);

  const addFields = () => {
    let newfield = {
      categorie: "",
      nom: "",
      poucentage: "",
      poids: "",
      unite_poids: "",
      cout: "",
      total: "",
    };
    setInputFields([...inputFields, newfield]);
  };
  return (
    <div className="quantite-tabs-component">
      <div className="conatiner-quantite-recette mb-4">
        <>
          {inputFields?.map((input, i) => {
            return (
              <div className="container-table-add-recette">
                <div className="line-add-recette-item w-15">
                  {i === 0 ? (
                    <label
                      htmlFor=""
                      className="form-label form-label-add-recette"
                    >
                      Catégorie
                    </label>
                  ) : null}
                  <select
                    className="form-control form-control-add-recette"
                    // {...register(`recette_matiere_premieres.${i}.categorie`)}
                    name="categorie"
                    onChange={(e) => HandleSelectName(e, i)}
                    value={options[i]?.categorie}
                  >
                    <option value="">Catégorie</option>
                    {Categories?.map((category, i) => (
                      <option value={category?.value} key={i}>
                        {" "}
                        {category?.label}{" "}
                      </option>
                    ))}
                  </select>

                  <ErrorMessage
                    message={
                      errors?.recette_matiere_premieres &&
                      errors?.recette_matiere_premieres[i]?.categorie &&
                      errors?.recette_matiere_premieres[i]?.categorie?.message
                    }
                  />
                </div>
                <div className="line-add-recette-item w-15">
                  {i === 0 ? (
                    <label
                      htmlFor="nom"
                      className="form-label form-label-add-recette"
                    >
                      Nom
                    </label>
                  ) : null}
                  <select
                    className="form-control form-control-add-recette"
                    // {...register(`recette_matiere_premieres.${i}.nom`)}
                    name="nom"
                    onChange={(e) => HandleSelectName(e, i)}
                    value={options[i]?.nom}
                    id="nom"
                  >
                    <option value="">Nom</option>
                    {!!options[i]?.categorie &&
                      stocks
                        ?.filter(
                          (el) =>
                            el.category === options[i]?.categorie &&
                            parseInt(el?.quantite) * parseFloat(el?.poids) > 0
                        )
                        ?.map((item) => (
                          <option
                            value={item?.ligne_commande_id}
                            key={item?.ligne_commande_id}
                          >
                            {item?.nom}
                          </option>
                        ))}
                  </select>

                  <ErrorMessage
                    message={
                      errors?.recette_matiere_premieres &&
                      errors?.recette_matiere_premieres[i]?.nom &&
                      errors?.recette_matiere_premieres[i]?.nom?.message
                    }
                  />
                </div>
                <div className="line-add-recette-item">
                  {i === 0 ? (
                    <label
                      htmlFor=""
                      className="form-label form-label-add-recette"
                    >
                      Poids
                    </label>
                  ) : null}
                  <input
                    type="number"
                    step={"any"}
                    min={0}
                    className="form-control form-control-add-recette"
                    placeholder="Poids"
                    name="poids"
                    value={options[i]?.poids}
                    onChange={(e) => handleChange(e, i)}
                    // {...register(`recette_matiere_premieres.${i}.poids`)}
                  />
                  <ErrorMessage
                    message={
                      errors?.recette_matiere_premieres &&
                      errors?.recette_matiere_premieres[i]?.poids &&
                      errors?.recette_matiere_premieres[i]?.poids?.message
                    }
                  />
                </div>
                <div className="line-add-recette-item w-15">
                  {i === 0 ? (
                    <label
                      htmlFor=""
                      className="form-label form-label-add-recette"
                    >
                      Unité de poids
                    </label>
                  ) : null}
                  <select
                    className="form-control form-control-add-recette"
                    // {...register(`recette_matiere_premieres.${i}.unite_poids`)}
                    name="unite_poids"
                    value={options[i]?.unite_poids}
                    onChange={(e) => handleChange(e, i)}
                  >
                    <option value="">Unité</option>
                    <option value="g">g</option>
                    <option value="kg">kg</option>
                  </select>
                  <ErrorMessage
                    message={
                      errors?.recette_matiere_premieres &&
                      errors?.recette_matiere_premieres[i]?.unite_poids &&
                      errors?.recette_matiere_premieres[i]?.unite_poids?.message
                    }
                  />
                </div>
                <div className="line-add-recette-item">
                  {i === 0 ? (
                    <label
                      htmlFor=""
                      className="form-label form-label-add-recette"
                    >
                      Pourcentage
                    </label>
                  ) : null}
                  <input
                    type="number"
                    min={0}
                    step={"any"}
                    // {...register(`recette_matiere_premieres.${i}.pourcentage`)}
                    value={options[i]?.pourcentage}
                    name="pourcentage"
                    onChange={(e) => handleChange(e, i)}
                    className="form-control form-control-add-recette"
                    placeholder="%"
                  />
                  <ErrorMessage
                    message={
                      errors?.recette_matiere_premieres &&
                      errors?.recette_matiere_premieres[i]?.pourcentage &&
                      errors?.recette_matiere_premieres[i]?.pourcentage?.message
                    }
                  />
                </div>
                <div className="line-add-recette-item">
                  {i === 0 ? (
                    <label
                      htmlFor="cout"
                      className="form-label form-label-add-recette"
                    >
                      Coût
                    </label>
                  ) : null}
                  <input
                    type="number"
                    step={"any"}
                    min={0}
                    {...register(`recette_matiere_premieres.${i}.cout`)}
                    readOnly
                    className="form-control form-control-add-recette"
                    placeholder="Cout"
                  />
                  <ErrorMessage
                    message={
                      errors?.recette_matiere_premieres &&
                      errors?.recette_matiere_premieres[i]?.cout &&
                      errors?.recette_matiere_premieres[i]?.cout?.message
                    }
                  />
                </div>
                <div className="line-add-recette-item">
                  {i === 0 ? (
                    <label
                      htmlFor=""
                      className="form-label form-label-add-recette"
                    >
                      Total
                    </label>
                  ) : null}
                  <input
                    type="number"
                    step={"any"}
                    {...register(`recette_matiere_premieres.${i}.cout_total`)}
                    readOnly
                    className="form-control form-control-add-recette"
                    placeholder="Total"
                  />
                  <ErrorMessage
                    message={
                      errors?.recette_matiere_premieres &&
                      errors?.recette_matiere_premieres[i]?.cout_total &&
                      errors?.recette_matiere_premieres[i]?.cout_total?.message
                    }
                  />
                </div>
              </div>
            );
          })}
        </>
        <div
          className="container-table-add-recette-total pb-4"
          style={{ background: "#f9fafb" }}
        >
          <div className="line-add-recette-item-total">
            <div
              className="form-control-add-recette"
              style={{ color: "#667085", fontWeight: 700 }}
            >
              Total
            </div>
          </div>
          <div className="line-add-recette-item-total">
            <div
              className="form-control-add-recette"
              style={{ color: "#50B2D4", fontWeight: 700 }}
            >
              {" "}
              {""}{" "}
            </div>
          </div>
          <div className="line-add-recette-item-total">
            <div
              className="form-control-add-recette"
              style={{ color: "#50B2D4", fontWeight: 700 }}
            >
              {totals?.total_poids}
            </div>
          </div>
          <div className="line-add-recette-item-total">
            <div
              className="form-control-add-recette"
              style={{ color: "#50B2D4", fontWeight: 700 }}
            ></div>
          </div>
          <div className="line-add-recette-item-total">
            <div
              className="form-control-add-recette"
              style={{ color: "#50B2D4", fontWeight: 700 }}
            >
              {totals?.total_pourcentage}%
            </div>
          </div>
          <div className="line-add-recette-item-total">
            <div
              className="form-control-add-recette"
              style={{ color: "#50B2D4", fontWeight: 700 }}
            >
              {totals?.total_cout}€
            </div>
          </div>
          <div className="line-add-recette-item-total">
            <div
              className="form-control-add-recette"
              style={{ color: "#50B2D4", fontWeight: 700 }}
            >
              {parseFloat(totals?.all_total).toFixed(2)}€
            </div>
          </div>
        </div>
        <div className="w-100">
          <ErrorMessage
            message={
              errors?.total_pourcentage && errors?.total_pourcentage?.message
            }
          />
        </div>
        <div className="container-btn-add-newrecette-table pb-4">
          <button
            className="btn btn-add-new-recette-table"
            onClick={(e: any) => {
              e.preventDefault();
              addFields();
            }}
          >
            <AiOutlinePlus />
          </button>
        </div>
        <div className="flex-r gap-4 pe-5 align-items-center">
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="btn btn-cancelled-recette trans-0-2"
          >
            Annuler
          </button>
          <button
            // onClick={(e) => handleSubmit(e)}
            type="submit"
            className="btn auth-submit-btn-recette trans-0-2"
          >
            Suivant
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuantiteTabs;
