import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import TableSkeleton from "../../../../common/TableSkeleton";
import "./CommandeFournisseurTable.css";
import { AiOutlineDashboard } from "react-icons/ai";
import { useFournisseurFromLocation } from "../../../../../utils/api/fournisseur/fournisseur.api";
import { useGetCommandeLinesByFournisseurQuery } from "../../../../../utils/api/commande/commande.api";
import { CommandeLine } from "../../../../../utils/api/commande/commande.type";
import moment from "moment";
import { FiEye } from "react-icons/fi";
import StockDetailsModal from "../../../StockMP/modal/StockDetailsModal";
import { CustomPagination } from "../../../StockMP/StockMPTable/StockMPTable";

function CommandeFournisseurTable() {
  const [item] = useFournisseurFromLocation();
  const [page, setPage] = useState(1);
  const { data = { data: { data: [], total: 0 } }, isLoading } =
    useGetCommandeLinesByFournisseurQuery({ id: item?.fournisseur_id, page });

  const nameFormatter = (cell, row) => {
    return (
      <>
        <p className="nom-stock mb-1">{cell}</p>
        <span style={{ color: "#667085", fontSize: 14, fontWeight: 400 }}>
          {row?.category}
        </span>
      </>
    );
  };

  const symboleFormatter = (cell: string, row: CommandeLine) => {
    if (parseInt(cell) < parseInt(row?.quantite)) {
      return (
        <p className="price-table-format mb-0">
          <span style={{ color: "#219653", fontSize: 20, paddingLeft: 5 }}>
            <AiOutlineDashboard />{" "}
          </span>
          {cell}
        </p>
      );
    }
    if (parseInt(cell) === parseInt(row?.quantite) / 2) {
      return (
        <p className="price-table-format mb-0">
          <span style={{ color: "#F2994A", fontSize: 20, paddingLeft: 5 }}>
            <AiOutlineDashboard />{" "}
          </span>
          {cell}
        </p>
      );
    }
    if (parseInt(cell) >= parseInt(row?.quantite)) {
      return (
        <p className="price-table-format mb-0">
          <span style={{ color: "#EB5757", fontSize: 20, paddingLeft: 5 }}>
            <AiOutlineDashboard />{" "}
          </span>
          {cell}
        </p>
      );
    }
  };

  const actionFormatter = (cell, row) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-2 align-items-center">
          <div className="container-btn-action-icon-modal">
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Visualiser"
              data-bs-toggle="modal"
              data-bs-target={`#commandeItemModal${cell}`}
            >
              <FiEye />
            </button>
          </div>
        </div>
        <StockDetailsModal item={row} />
      </>
    );
  };

  const columns = [
    {
      dataField: "nom",
      text: "Nom de la MP ",
      formatter: (cell: string, row: CommandeLine) => nameFormatter(cell, row),
      style: { whiteSpace: "nowrap" },
    },
    {
      dataField: "quantite",
      text: "Quantité en stock ",
    },
    {
      dataField: "numero_lot",
      text: "N° de lot",
    },
    {
      dataField: "dluo_ddm",
      text: "DDM/DLUO",
      formatter: (cell: string) => moment(new Date(cell)).format("DD/MM/YYYY"),
    },
    {
      dataField: "seuil_alerte",
      text: "Symbole de recommande",
      formatter: (cell: string, row: CommandeLine) =>
        symboleFormatter(cell, row),
    },
    {
      dataField: "ligne_commande_id",
      text: "Actions",
      formatter: (cell: string, row: CommandeLine) =>
        actionFormatter(cell, row),
      // headerStyle: () => {
      //   return { width: "80px", textAlign: "center" };
      // },
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="ligne_commande_id"
            data={data?.data?.data}
            columns={columns}
            bordered={true}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            // selectRow={selectRow}
            noDataIndication={() => "Aucune donnée trouvée"}
          />
          <CustomPagination
            nbPages={data?.data?.total}
            page={page}
            onChange={(page) => {
              setPage(page);
            }}
            perPage={10}
          />
        </>
      )}
    </>
  );
}

export default CommandeFournisseurTable;
