import React, { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import ReactQuiill from 'react-quill'

const AddCgu = () => {
  const [value, setValue] = useState("");
  
  const [inputFields, setInputFields] = useState([ {
    titre: "",
    text: "",
  },
]);
const handleFormChange = (index, event) => {
  let data = [...inputFields];
  data[index][event.target.name] = event.target.value;
  setInputFields(data);
};
const addFields = () => {
  let newfield = {
    titre: "",
    text: "",
  };
  setInputFields([...inputFields, newfield]);
};
  return (
    <div className="fournisseur-list-component">
      <div className="container-title-page-admin mb-5">
        <div className="content-title-admin">
          <h3 className="h3-title-admin">Paramètres</h3>
        </div>
      </div>
      <div className="container-fournisseur-list">
      <div className="container-form-add">
        <form>
        <>
          {inputFields?.map((input, index) => {
            return index === 0 ? (
          <div className="row row-add">
            <div className="col-md-12 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <input
                  className="form-control form-control-add-form"
                  type="text"
                  placeholder="Titre"
                />
              </div>
            </div>
            <div className="col-md-12 col-form-add-student mb-3">
              <div className="row-addcontent-col-form-add">
                <ReactQuiill theme="snow" value={value} onChange={setValue} />
              </div>
            </div>
            </div>
            ) : (
              <div className="row row-add pt-5">
              <div className="col-md-12 col-form-add-student mb-3">
                <div className="row-addcontent-col-form-add">
                  <input
                    className="form-control form-control-add-form"
                    type="text"
                    placeholder="Titre"
                    name="titre"
                    onChange={(event) => handleFormChange(index, event)}
                  />
                </div>
              </div>
              <div className="col-md-12 col-form-add-student mb-3">
                <div className="row-addcontent-col-form-add">
                  <ReactQuiill theme="snow" value={value} onChange={(event) => handleFormChange(index, event)} />
                </div>
              </div>
              </div>
              );
            })}
             </>
            </form>
            <div className="content-btn-add-new-section mt-4Ò">
              <button className="btn btn-add-new-section" onClick={(e: any) => {
              e.preventDefault();
              addFields();
            }}>
                <AiOutlinePlus />
                <span className="ps-2">Ajouter un autre chapitre</span>
              </button>
            </div>
            <div className="row">
            <div className="col-md-3 offset-md-9 content-btn-add py-5 flex-r">
            <button className="btn auth-submit-btn trans-0-2">
            Mettre à jour
              </button>
            </div>
            </div>
            </div>
      </div>
    </div>
  );
};

export default AddCgu;
